import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import React, {
  Context,
  Fragment,
  useContext,
  useEffect,
  useState,
} from "react";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import { dateFormat } from "../../utils";

export interface IBreadCrumbsProps {
  Link: any;
  to: string;
  inspection?: {
    project: {
      id: number;
      name: string;
    };
    created_date: string;
  };
  resource?: {
    resourceName: string;
    regex: RegExp;
    context?: Context<any>;
    hook?: any;
    rootTo: string;
    contextKey?: string;
  };
}

const BreadCrumbs: React.FC<IBreadCrumbsProps> = ({
  Link,
  to,
  inspection,
  resource,
}) => {
  const appDivisionContext = useContext(AppDivisionContext);
  const [instance, setInstance] = useState<{ name: string }>({ name: "" });
  const context =
    resource && resource.context ? useContext(resource.context) : null;
  const hookData =
    resource && resource.hook ? resource.hook(parseInt(to)) : null;

  useEffect(() => {
    if (resource && context) {
      setInstance(resource.contextKey ? context[resource.contextKey] : null);
    } else if (resource && hookData) {
      setInstance(hookData.data);
    }

    return () => setInstance({ name: "" });
  }, [context, hookData]);

  if (resource) {
    return (instance && instance.name && instance.name.length > 0) ||
      inspection ? (
      <Fragment>
        {!inspection && (
          <Fragment>
            <FontAwesomeIcon
              icon={faCaretRight}
              className="pad-left pad-right"
            />
            <Link
              to={`/divisions/${appDivisionContext.appDivisionId}${resource.rootTo}/${to}`}
            >
              {instance.name}
            </Link>
          </Fragment>
        )}

        {inspection && inspection.project && (
          <Fragment>
            <FontAwesomeIcon
              icon={faCaretRight}
              className="pad-left pad-right"
            />
            <Link
              to={`/divisions/${appDivisionContext.appDivisionId}${resource.rootTo}/${inspection.project.id}`}
            >
              {inspection.project.name}
            </Link>

            <FontAwesomeIcon
              icon={faCaretRight}
              className="pad-left pad-right"
            />
            <Link
              to={`/divisions/${appDivisionContext.appDivisionId}${resource.rootTo}/${inspection.project.id}/inspections`}
            >
              Inspections
            </Link>
            <FontAwesomeIcon
              icon={faCaretRight}
              className="pad-left pad-right"
            />
            <Link to={`/inspection/${to}`}>
              {moment.utc(inspection.created_date).format(dateFormat)}
            </Link>
          </Fragment>
        )}
      </Fragment>
    ) : null;
  } else {
    return null;
  }
};

export default BreadCrumbs;
