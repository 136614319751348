import React, { useContext, useEffect, useState } from 'react';
import { AppDivisionContext } from "../../contexts/AppDivisionContext";
import Box from '@mui/material/Box';
import { useTheme } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import TablePagination from '@mui/material/TablePagination';
import LastPageIcon from '@mui/icons-material/LastPage';
import FirstPageIcon from '@mui/icons-material/FirstPage';
import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft';
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight';
import Tooltip from '@mui/material/Tooltip';
import reportApi from '../../utils/api/report';
import divisionApi from "../../utils/api/division"
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import DatePicker from "react-datepicker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCog } from "@fortawesome/free-solid-svg-icons";
import Loading from "../Shared/ResourceIndex/Loading";
import AppContext from '../../contexts/AppContext';
import {
    Typography,
    Checkbox,
} from '@mui/material';


const staticRowName = [
    {
        id: 0,
        label: "Project Name",
        value: "projectName",
        isEnable: true,
        totalPlaceHolder: true,
    },
    {
        id: 1,
        label: "Client Name",
        value: "clientName",
        isEnable: false,
        showInOptions: true,
    },
    {
        id: 2,
        label: "Total Inspection",
        value: "inspectionCount",
        isEnable: true,
        showInOptions: true,
        hasTotal: true,
    },
    {
        id: 3,
        label: "Total Findings",
        value: "findingsCount",
        isEnable: true,
        showInOptions: true,
        hasTotal: true,
    },
    {
        id: 4,
        label: "Avg. Findings per Inspection",
        value: "avgFindings",
        isEnable: true,
        showInOptions: true,
        hasAvg: true,
        superSet: "AVG",
        decimal: true,
    },
    {
        id: 5,
        label: "Avg. Days to Correct",
        value: "avgFindingDays",
        isEnable: true,
        showInOptions: true,
        hasAvg: true,
        superSet: "AVG",
        decimal: true,
    },
    {
        id: 6,
        label: "Max Days to Correct",
        value: "maxDay",
        isEnable: true,
        showInOptions: true,
        superSet: "MAX",
    },
    {
        id: 7,
        label: "Open Findings",
        value: "openFindings",
        isEnable: true,
        showInOptions: true,
        hasTotal: true,
        hasPercent: true,
        percentVariance: "findingsCount",
    },
    {
        id: 8,
        label: "Not Completed On-Time",
        value: "notCompleteInExpectedDays",
        tooltip: "Number of findings that took longer than 7-days to complete",
        isEnable: true,
        showInOptions: true,
        hasTotal: true,
        hasPercent: true,
        percentVariance: "findingsCount",
    },
    {
        id: 9,
        label: "Acknowledgement Certification",
        value: "ackCount",
        tooltip: "Number of inspections where the acknowledgment statement was not signed within 2 days of the date of inspection",
        isEnable: true,
        showInOptions: true,
        hasTotal: true,
        hasPercent: true,
        percentVariance: "inspectionCount",
    },
    {
        id: 10,
        label: "Compliance Certification",
        value: "complianceCount",
        tooltip: "Number of inpsection where the compliance certification has not been signed",
        isEnable: true,
        showInOptions: true,
        hasTotal: true,
        hasPercent: true,
        percentVariance: "inspectionCount",
    },
    // {
    //     id: 11,
    //     label: "Missed Inspection",
    //     value: "missedIsp",
    //     isEnable: true,
    //     showInOptions: true,
    //     hasTotal: true,
    //     hasPercent: true,
    //     percentVariance: "inspectionCount",
    // },
]

export const ComplianceReport = () => {
    const divisionId: any = useContext(AppDivisionContext).appDivisionId;
    const [pageNumber, setPageNumber] = useState<number>(1)
    const [search, setSearch] = useState<string | undefined>()
    const [report, setReport] = useState<Array<any>>([])
    const [pagination, setPagination] = useState<any>(null)
    const [rowPerPage, setRowsPerPage] = useState<number>(10)
    const theme = useTheme();
    const [clientName, setClientName] = useState<any[]>([]);
    const animatedComponents = makeAnimated();
    const [selectedOptions, setSelectedOptions] = useState<any>([]);
    const [selectedFindings, setSelectedFindings] = useState<any>([]);
    const [clientIds, setClientIds] = useState<number[] | []>([]);
    const [rowName, setRowName] = useState<any>();
    const [minDate, setMinDate] = useState<any>(null);
    const [maxDate, setMaxDate] = useState<any>(null);
    const [findingTypes, setFindingTypes] = useState<any>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [colSpan, setColSpan] = useState<number>(6);
    const [colTotalSpan, setColTotalSpan] = useState<number>(1);
    const [loading, setLoading] = useState<Boolean>(true);
    const [error, setError] = useState<Boolean>(false);
    const [initialLoad, setInitialLoad] = useState<Boolean>(true);
    const appContext = useContext(AppContext);
    const { panel, setPanel } = appContext;

    const setSidePanel = () => {
        if (panel === "open") {
            setPanel("close")
        }
    }

    useEffect(() => {
        setTimeout(setSidePanel, 500)
    }, [])
    const TablePaginationActions = () => {
        const { pageSize, numModels } = pagination;

        const handleFirstPageButtonClick = () => {
            setPageNumber(1)
        };

        const handleBackButtonClick = () => {
            setPageNumber(pageNumber - 1);
        };

        const handleNextButtonClick = () => {
            setPageNumber(pageNumber + 1);
        };

        const handleLastPageButtonClick = () => {
            setPageNumber(Math.ceil(numModels / pageSize));
        };

        return (
            <Box sx={{ flexShrink: 0, ml: 2.5 }}>
                <IconButton
                    onClick={handleFirstPageButtonClick}
                    disabled={pageNumber === 1}
                    aria-label="first page"
                >
                    {theme.direction === 'rtl' ? <LastPageIcon /> : <FirstPageIcon />}
                </IconButton>
                <IconButton
                    onClick={handleBackButtonClick}
                    disabled={pageNumber === 1}
                    aria-label="previous page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowRight /> : <KeyboardArrowLeft />}
                </IconButton>
                <IconButton
                    onClick={handleNextButtonClick}
                    disabled={pageNumber >= Math.ceil(numModels / pageSize)}
                    aria-label="next page"
                >
                    {theme.direction === 'rtl' ? <KeyboardArrowLeft /> : <KeyboardArrowRight />}
                </IconButton>
                <IconButton
                    onClick={handleLastPageButtonClick}
                    disabled={pageNumber >= Math.ceil(numModels / pageSize)}
                    aria-label="last page"
                >
                    {theme.direction === 'rtl' ? <FirstPageIcon /> : <LastPageIcon />}
                </IconButton>
            </Box>
        );
    }

    const HandleRowPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        setRowsPerPage(+event.target.value);
    };

    const TotalData = () => {
        const totalValue: any = {
            inspectionCount: 0,
            findingsCount: 0,
            avgFindings: 0,
            avgFindingDays: 0,
            maxDay: 0,
            ackCount: 0,
            complianceCount: 0,
            openFindings: 0,
            notCompleteInExpectedDays: 0,
            missedIsp: 0,
        }

        report.forEach((ele) => {
            const keys: string[] = Object.keys(ele)

            keys.forEach((key) => {
                if (key === "maxDay") {
                    totalValue[key] = totalValue[key] > ele[key] ? totalValue[key] : ele[key]
                } else {
                    totalValue[key] += ele[key]
                }

            })
        })

        return (
            <>
                <TableRow className='compliance-report__total-data'>
                    {rowName.map((rowValue: any) => {
                        if (rowValue.isEnable) {
                            if (rowValue.value !== "projectName" && rowValue.value !== "clientName") {
                                if (rowValue.decimal) {
                                    if (rowValue.hasAvg) {

                                        return <TableCell className="sup-cell" align="center"><sup>{rowValue.superSet}</sup>{totalValue[rowValue.value] || totalValue[rowValue.value] === 0 ? (totalValue[rowValue.value] / report.length).toFixed(2) : "-"}</TableCell>
                                    }

                                    return <TableCell align="center">{totalValue[rowValue.value] || totalValue[rowValue.value] === 0 ? totalValue[rowValue.value].toFixed(2) : "-"}</TableCell>
                                }
                                if (rowValue.superSet) {
                                    return <TableCell className="sup-cell" align="center"><sup>{rowValue.superSet}</sup>{(totalValue[rowValue.value])}</TableCell>
                                }

                                return <TableCell align="center">{totalValue[rowValue.value]}</TableCell>
                            }
                            if (rowValue.totalPlaceHolder) {
                                return <TableCell colSpan={colTotalSpan} align="center">Total</TableCell>
                            }

                            return null
                        }

                        return null
                    })}
                </TableRow>
                <TableRow className='compliance-report__percent-variance'>
                    <TableCell align="center" colSpan={colSpan}><strong>Percent Variance</strong></TableCell>
                    {rowName.map((rowValue: any) => {
                        if (rowValue.isEnable) {
                            if (rowValue.hasPercent) {
                                return <TableCell align="center"><strong>{totalValue[rowValue.value] ? `${((100 * totalValue[rowValue.value]) / totalValue[rowValue.percentVariance]).toFixed(2)}%` : "-"}</strong></TableCell>
                            }

                            return null
                        }

                        return null
                    })}
                </TableRow>
            </>
        )
    }

    const handleChangeSearch = (e: any) => {
        setSearch(e.target.value);
    }

    const handleClientAdd = () => {
        const clientId = selectedOptions.map((ele: any) => (
            ele.value
        ))

        setPageNumber(1)
        setClientIds(clientId)
        setSelectedOptions([])
    }

    const handleDateChangeRaw = (e: any) => {
        e.preventDefault();
    }


    const getValue = (findingType: string) => {
        switch (findingType) {
            case "CA":
                return { value: findingType, label: "Corrective Action" }

            case "MI":
                return { value: findingType, label: "Maintenance Item" }

            case "A":
                return { value: findingType, label: "Achievement" }

            default:
                return { value: findingType, label: findingType }
        }
    }

    const onCheck = (ele: any) => {
        const newOption = rowName.map((row: any) => {
            if (ele.id === row.id) {
                row.isEnable = !row.isEnable

                if (row.isEnable && !row.hasPercent) {
                    setColSpan(colSpan + 1)
                }
                if (!row.isEnable && !row.hasPercent) {
                    setColSpan(colSpan - 1)
                }

                return row
            }
            if (ele.value === "clientName") {
                if (ele.isEnable) {
                    setColTotalSpan(colTotalSpan + 1)
                } else {
                    setColTotalSpan(colTotalSpan - 1)
                }
            }

            return row
        })

        setRowName(newOption)
    }

    useEffect(() => {
        const param = {
            divisionId,
            pageNumber,
            searchQuery: search,
            rowsPerPage: rowPerPage,
            clientIds,
            maxDate: maxDate && maxDate.toISOString(),
            minDate: minDate && minDate.toISOString(),
            findings: selectedFindings && selectedFindings.value
        }

        reportApi.index(param).then((res: any) => {
            setReport(res.pageData)
            setPagination(res.paginationState)
            if (initialLoad) {
                setLoading(false)
                setInitialLoad(false)
            }
        }).catch(() => {
            setError(true)
            setLoading(false)
        })

    }, [pageNumber, search, rowPerPage, divisionId, clientIds, maxDate, minDate, selectedFindings])

    useEffect(() => {
        const clientArr: React.SetStateAction<any[]> = []
        const findingsArr: React.SetStateAction<any[]> = []

        setRowName(staticRowName)

        divisionApi.clients.index(divisionId).then((clients) => {
            clients.forEach((client) => {
                clientArr.push({ value: client.id, label: client.name })
            })
        })

        reportApi.getFindings(divisionId).then((findings) => {
            findings.findings.forEach((finding) => {
                if (!findingsArr.includes(finding)) {
                    findingsArr.push(finding)
                }
            })
            setFindingTypes(findingsArr.map((finding) => (getValue(finding))))
        })

        setClientName(clientArr)
    }, [divisionId])

    if (loading) {
        return <Loading />
    }

    if (error) {
        return (
            <div style={{ textAlign: "center" }}>
                <h3><strong>This User role doesnt have the access to the reports page</strong></h3>
            </div>)
    }

    return (
        <div className="compliance-report">
            <div className="compliance-report__menu">
                <div className="compliance-report__cog-wrapper" onClick={() => setOpen(!open)}>
                    <FontAwesomeIcon icon={faCog} className="pad-left" />
                </div>

                <div className="compliance-report__drawer-wrapper" style={{ display: open ? "block" : "none" }}>
                    <div className="compliance-report__drawer">
                        <p>Column Setting</p>
                        <hr />
                        <div className="compliance-report__widget-list">
                            {rowName.length ? rowName.map((row: any) => {
                                if (row.showInOptions) {
                                    return (
                                        <div className="compliance-report__widget-list-item">
                                            <Checkbox onChange={() => onCheck(row)} checked={row.isEnable} />
                                            <Typography variant='h6'>{row.label}</Typography>
                                        </div>
                                    )
                                }

                                return null
                            }) : null}
                        </div>
                    </div>
                </div>
            </div>


            <div className="compliance-report__toolbar">
                <input
                    type="text"
                    placeholder="Search"
                    onChange={handleChangeSearch}
                    value={search}
                    className="selectClient-input search-control"
                />
                <Select
                    closeMenuOnSelect={false}
                    isMulti
                    components={animatedComponents}
                    options={clientName}
                    onChange={(data) => { setSelectedOptions(data) }}
                    isClearable={true}
                    value={selectedOptions}
                    placeholder="Client"
                    id={'selectInput'}
                    className="compliance-report__toolbar-client-select"
                    classNamePrefix="toolbar-client-select"
                />
                <button
                    className="primary"
                    onClick={() => handleClientAdd()}
                >
                    Save
                </button>


                <DatePicker
                    placeholderText={"Min Date"}
                    selected={minDate}
                    maxDate={new Date()}
                    onChange={(date: Date) => setMinDate(date)}
                    onChangeRaw={(e: any) => handleDateChangeRaw(e)}
                />

                <DatePicker
                    placeholderText={"Max Date"}
                    selected={maxDate}
                    maxDate={new Date()}
                    onChange={(date: Date) => (setMaxDate(date))}
                    onChangeRaw={(e) => handleDateChangeRaw(e)}
                />

                <Select
                    closeMenuOnSelect={false}
                    components={animatedComponents}
                    options={findingTypes}
                    onChange={(data) => { setSelectedFindings(data) }}
                    isClearable={true}
                    value={selectedFindings}
                    placeholder="Finding Type"
                    id={'selectInput'}
                    className="compliance-report__toolbar-client-select"
                    classNamePrefix="toolbar-client-select"
                />
            </div>
            <TableContainer component={Paper} className="compliance-report__table-container">
                <Table sx={{ minWidth: 650 }} aria-label="caption table">
                    <TableHead>
                        <TableRow>
                            {rowName.map((rowTitle: any) => {
                                if (rowTitle.isEnable) {
                                    if (rowTitle.tooltip) {
                                        return <Tooltip title={rowTitle.tooltip} placement="top" arrow><TableCell align="center"><strong>{rowTitle.label}</strong></TableCell></Tooltip>
                                    }

                                    return <TableCell align="center"><strong>{rowTitle.label}</strong></TableCell>
                                }

                                return null
                            })}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {report.length ? report.map((row, index) => (
                            <TableRow key={index} >
                                {rowName.map((rowValue: any) => {
                                    if (rowValue.isEnable) {
                                        if (rowValue.value !== "projectName" && rowValue.value !== "clientName") {
                                            return <TableCell align="center">{row[rowValue.value] !== null ? row[rowValue.value] : "-"}</TableCell>
                                        }

                                        return <TableCell>{row[rowValue.value]}</TableCell>
                                    }

                                    return null
                                })}
                            </TableRow>
                        ))
                            :
                            <TableRow>
                                <TableCell colSpan={staticRowName.length} align="center"><strong>No data found</strong></TableCell>
                            </TableRow>}
                        <TotalData />
                    </TableBody>
                </Table>
            </TableContainer>
            <TablePagination
                colSpan={3}
                rowsPerPageOptions={[10, 20, 30, 50, { value: pagination.numModels, label: 'All' }]}
                component="div"
                count={pagination.numModels}
                rowsPerPage={rowPerPage}
                page={pageNumber - 1}
                onPageChange={() => (console.log("demo"))}
                onRowsPerPageChange={HandleRowPerPage}
                ActionsComponent={TablePaginationActions}
                SelectProps={{
                    inputProps: {
                        'aria-label': 'rows per page',
                    },
                    native: true,
                }}
            />
        </div>
    )
}