import { Inspection } from "@sw-sw/lib-inspection-templates";
import xhrService from "../xhrService";
import { Finding } from "./finding";
const baseUrl = "/api/projects";

export type ProjectInspection = Omit<Inspection, "id"> & {
  id: number;
  findings: Array<Finding>;
};

export const projectInspectionApi = {
  index: (id: number): Promise<ProjectInspection[]> => {
    return xhrService
      .get(`${baseUrl}/${id}/inspections`)
      .then(response => response.data);
  },
};

export default projectInspectionApi;
