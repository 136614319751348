import { isNull } from "lodash";
import { ProjectInspection } from "./api/projectInspection";

export type InspectionFindingCount = {
  total: { ca: number; mi: number };
  complete: { ca: number; mi: number };
};

export const getFindingCounts = (
  inspection: ProjectInspection,
): InspectionFindingCount => {
  const total = { ca: 0, mi: 0 };
  const complete = { ca: 0, mi: 0 };

  if (inspection.findings) {
    inspection.findings.forEach(finding => {
      if (finding.type === "CA") {
        total.ca++;

        if (!isNull(finding.date_completed)) {
          complete.ca++;
        }

        return;
      }

      if (finding.type === "MI") {
        total.mi++;

        if (!isNull(finding.date_completed)) {
          complete.mi++;
        }

        return;
      }
    });
  }

  return { total, complete };
};
