import React, { useContext } from "react";
import PropTypes from "prop-types";

import AppContext from "../../../contexts/AppContext";
import EditUserModal from "../EditUser/EditUserModal";

function AddUserModal({ onSubmit, ...props }) {
  const appStore = useContext(AppContext);

  return (
    <EditUserModal
      {...props}
      isEdit={false}
      onSubmit={newUsers => {
        appStore.triggerSuccessNotification();

        return onSubmit(newUsers);
      }}
    />
  );
}

AddUserModal.propTypes = {
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default AddUserModal;
