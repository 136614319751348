import IconEditorTool from "./IconEditorTool";

const initialState = () => {
  return {
    shape: null,
  };
};

/**
 * Click and drag to draw the `ShapeClass`
 */
class DraggableShapeTool extends IconEditorTool {
  label = "Shape";
  helpText = "Click and drag to create shape.";

  /** @todo better implementation of this */
  regularQuad = false; // all sides the same

  state = initialState();

  constructor(canvas, label, regularQuad) {
    super(canvas);

    if (label) {
      this.label = label;
    }

    if (regularQuad) {
      this.regularQuad = regularQuad;
    }
  }

  makeShape(options) {
    throw new Error(
      "Descendants of `DraggableShapeTool` must implement `makeShape`",
    );
  }

  getDefaultShapeConfig() {
    throw new Error(
      "Descendants of `DraggableShapeTool` must implement `getDefaultShapeConfig`",
    );

    // eslint-disable-next-line no-unreachable
    return {};
  }

  calculateDimensions(eventX, eventY, shapeLeft, shapeTop) {
    throw new Error(
      "Descendants of `DraggableShapeTool` must implement `calculateDimensions`",
    );

    // eslint-disable-next-line no-unreachable
    return {};
  }

  /**
   * @param {fabric.IEvent} event
   */
  handleMouseDown(event) {
    const { x, y } = this.canvas.getPointer(event.e);

    this.state.shape = new this.makeShape({
      ...this.getDefaultShapeConfig(),
      top: y,
      left: x,
    });

    this.canvas.add(this.state.shape);
    this.applyTheme(this.state.shape);
  }

  /**
   * @param {fabric.IEvent} event
   */
  handleMouseMove(event) {
    if (this.state.shape !== null) {
      const { shape } = this.state;
      const p = this.canvas.getPointer(event.e);

      shape.set(this.calculateDimensions(p.x, p.y, shape.left, shape.top));
      this.canvas.renderAll();
    }
  }

  /**
   * @param {fabric.IEvent} event
   */
  handleMouseUp(event) {
    if (this.state.shape !== null) {
      this.canvas.remove(this.state.shape);

      this.canvas.trigger("tool:complete", {
        shape: this.applyTheme(this.state.shape),
        autoSelect: true,
      });
      this.state.shape = null;
    }
  }

  /**
   * When the tool is selected from the toolbar
   */
  handleSelected() {
    this.state = initialState();
  }

  /**
   * When tool is deselected
   */
  handleDeselected() {
    this.state = initialState();
  }

  //
}

export default DraggableShapeTool;
