import { useEffect, useState, useContext } from 'react';
import { QueryObserverOptions, useQuery } from 'react-query';
import { ProjectInspection } from '../utils/api/projectInspection';
import inspectionsCertificationDue from '../utils/api/inspectionsCertificationDue';
import { InspectionFindingCount } from '../utils/findingCount';
import { customDashboard } from '../utils/api/dashboard';
import AppDivisionContext from '../contexts/AppDivisionContext';
import AppContext from '../contexts/AppContext';

export type InspectionWithFindingCount = ProjectInspection & {
  findingCount: InspectionFindingCount;
};

// get all certification due list
export function useInspectionsCertificationDue(
  queryParams: any,
  divisionId: number,
  loadData: boolean,
  queryOptions: Omit<
    QueryObserverOptions<InspectionWithFindingCount[]>,
    'queryKey' | 'queryFn' | 'enabled' | 'keepPreviousData'
  > = {},
) {

  const query = useQuery<any[]>({
    queryKey: ['Inspections', 'CertificationDue', 'All'],
    queryFn: async () => {
      const data = loadData ? await inspectionsCertificationDue.index(queryParams, divisionId) : [];

      return data;
    },
    enabled: false,
    keepPreviousData: true,
    refetchOnWindowFocus: false,
    ...queryOptions,
  });

  useEffect(() => {
    query.refetch();
  }, [queryParams, divisionId, loadData]);

  return query;
}

// open Findings widget
export function useOpenFindingsData() {
  const [displayStatsForOF, setDisplayStatsForOF] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForOF, setAllProjectsForOF] = useState([]);
  const [isLoadingForOF, setIsLoadingForOF] = useState(true);
  const { panel, setPanel } = useContext(AppContext)

  const setter = () => {
    if (panel === "open") {
      setPanel("close")
    }
  }

  useEffect(() => {
    setTimeout(setter, 500);
  }, [])

  const fetchStatsFuncForOF = () => {
    customDashboard
    .fetch(`/divisions/${appDivisionId}/openFindings/getOpenFindingStats`)
    .then((res) => {
      setAllProjectsForOF(res.data.statistics);
      setIsLoadingForOF(false);
      setDisplayStatsForOF(res.data.statistics);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (!appDivisionId) return
    fetchStatsFuncForOF()    
  }, [appDivisionId]);

  return [
    displayStatsForOF,
    allProjectsForOF,
    isLoadingForOF,
    setDisplayStatsForOF,
    setIsLoadingForOF,
    fetchStatsFuncForOF
  ];
}

export function useFetchAllFindingTypes() {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [findingTypeListForOF, setFindingTypeListForOF] = useState([]);

  useEffect(() => {
    if (!appDivisionId) return

    customDashboard
      .fetch(`/divisions/${appDivisionId}/openFindings/fetchFindingTypesUsed`)
      .then((res) => {
        setFindingTypeListForOF(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [appDivisionId]);

  return [findingTypeListForOF];
}

// team management widget
export function useTeamManagement() {
  const [teamManagementData, setTeamManagementData] = useState(null);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [isLoadingForTM, setIsLoadingForTM] = useState(true);

  const getTeamManagementData = () => {
    setIsLoadingForTM(true);
    customDashboard
      .fetch(`/divisions/${appDivisionId}/getTeamManagementData`)
      .then((res) => {
        setTeamManagementData(res.data);
        setIsLoadingForTM(false);
      })
      .catch((error) => {
        setIsLoadingForTM(false);
        console.log(error);
      });
  };

  useEffect(() => {
    if (!appDivisionId) return;
    getTeamManagementData();
  }, [appDivisionId]);

  return [
    getTeamManagementData,
    teamManagementData,
    isLoadingForTM,
    setIsLoadingForTM,
  ];
}

// Inspections Due Widget

export function useInspectionsDueData() {
  const [displayStatsForID, setDisplayStatsForID] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForID, setAllProjectsForID] = useState([]);
  const [isLoadingForID, setIsLoadingForID] = useState(true);

  const fetchStatsFuncForID = () => {
    customDashboard
    .fetch(`/divisions/${appDivisionId}/inspectionsDue/getInspectionsDueStats`)
    .then((res) => {
      setAllProjectsForID(res.data.inspectionsDue);
      setIsLoadingForID(false);
      setDisplayStatsForID(res.data.inspectionsDue);
    })
    .catch((error) => {
      console.log(error);
    });
  }

  useEffect(() => {
    if (!appDivisionId) return
    fetchStatsFuncForID()
  }, [appDivisionId]);

  return [
    displayStatsForID,
    allProjectsForID,
    isLoadingForID,
    setDisplayStatsForID,
    setIsLoadingForID,
    fetchStatsFuncForID
  ];
}


// Findings Overview Widget

export function useFindingsOverviewData() {
  const [displayStatsForFO, setDisplayStatsForFO] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [allProjectsForFO, setAllProjectsForFO] = useState([]);
  const [isLoadingForFO, setIsLoadingForFO] = useState(true);

  useEffect(() => {
    if (!appDivisionId) return

    customDashboard
      .fetch(`/divisions/${appDivisionId}/findingsOverview/getStats`)
      .then((res) => {
        setAllProjectsForFO(res.data.statistics);
        setIsLoadingForFO(false);
        setDisplayStatsForFO(res.data.statistics);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [appDivisionId]);

  return [
    displayStatsForFO,
    allProjectsForFO,
    isLoadingForFO,
    setDisplayStatsForFO,
    setIsLoadingForFO,
  ];
}

export function useFetchAllFindingTypesForFO() {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [findingTypeListForFO, setFindingTypeListForFO] = useState([]);

  useEffect(() => {
    if (!appDivisionId) return

    customDashboard
      .fetch(`/divisions/${appDivisionId}/findingsOverview/fetchFindingTypesUsed`)
      .then((res) => {
        setFindingTypeListForFO(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [appDivisionId]);

  return [findingTypeListForFO];
}


// Calendar Widget

export function useCalendarData(){
  const [displayStatsForCal, setDisplayStatsForCal] = useState([]);
  const { appDivisionId } = useContext(AppDivisionContext);
  const [isLoadingForCal, setIsLoadingForCal] = useState(true);

  const fetchStatsFuncForCal= () => {
    customDashboard
      .fetch(`/divisions/${appDivisionId}/calendar/getCalendarData`)
      .then((res) => { 
        setIsLoadingForCal(false);
        setDisplayStatsForCal(res.inspections);
      })
      .catch((error) => {
        console.log(error);
      });
  }

  useEffect(() => {
    if (!appDivisionId) return
    fetchStatsFuncForCal()    
  }, [appDivisionId]);

  return [
    displayStatsForCal,
    isLoadingForCal,
    setDisplayStatsForCal,
    setIsLoadingForCal,
    fetchStatsFuncForCal
  ];
}


export function useFetchProjectsListForCal() {
  const { appDivisionId } = useContext(AppDivisionContext);
  const [ projectsListForCal, setProjectsListForCal] = useState([]);

  useEffect(() => {
    if (!appDivisionId) return

    customDashboard
      .fetch(`/divisions/${appDivisionId}/calendar/fetchAllUserProjects`)
      .then((res) => {        
        setProjectsListForCal(res);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [appDivisionId]);

  return [projectsListForCal];
}