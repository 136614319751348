import { asSchemaDefinition, SchemaFieldsWithContext } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import React, { useContext } from "react";
import { Link, Redirect, useLocation } from "react-router-dom";
import AuthenticationContext from "../../../contexts/AuthenticationContext";
import FormActions from "../../Shared/form/modal/FormActions";

const schema = asSchemaDefinition({
  email: {
    label: "Email",
    className: "bottom-outline",
    autoComplete: "username",
    validation: {
      required: true,
      format: "email",
    },
  },
  password: {
    controlType: UIControlType.password,
    label: "Password",
    className: "bottom-outline",
    autoComplete: "current-password",
    validation: {
      required: true,
    },
  },
});

const LoginForm = () => {
  const authStore = useContext(AuthenticationContext);
  const location = useLocation();

  const urlSearchParams = new URLSearchParams(location.search);

  const handleLogin = async ({
    email,
    password,
  }: {
    email: string;
    password: string;
  }) => {
    await authStore.login(email, password);
  };

  if (authStore.loggedIn) {
    return <Redirect to={urlSearchParams.get("to") || "/"} />;
  }

  return (
    <>
      <SchemaFieldsWithContext className="login-form" schema={schema}>
        <FormActions
          submitText="Login"
          onSubmit={handleLogin}
          onCancel={() => {}}
          showCancel={false}
          small={false}
          showSubmit={true}
          showErrorText={true}
          errorMessageText="Invalid form data. Please review errors above."
          cancelText="Cancel"
        >
          <Link to="/forgot" className="no-underline forgot-password">
            Forgot password
          </Link>
        </FormActions>
      </SchemaFieldsWithContext>
    </>
  );
};

export default LoginForm;
