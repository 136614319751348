import React from "react";
import { isEmpty, values } from "lodash";
import { Changelog } from "@sw-sw/common";
import { useProjectLog } from "../../../hooks/projectDetails";
import Loading from "../../Shared/ResourceIndex/Loading";

export interface IProjectLogProps {
  projectId: number;
}

const ProjectLog: React.FC<IProjectLogProps> = ({ projectId }) => {
  const projectLogQuery = useProjectLog(projectId);

  if (!projectLogQuery.data) {
    return <Loading what="Project Log" />;
  }

  if (values(projectLogQuery.data).every(value => isEmpty(value))) {
    return <p>There are no logs for this project</p>;
  }

  return (
    <section className="project-log">
      <Changelog changelogs={projectLogQuery.data} />
    </section>
  );
};

export default ProjectLog;
