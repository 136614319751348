import React from "react";
import { useMemo, useState } from "react";
import Lightbox from "react-image-lightbox";
import { getDownloadUrl } from "../../../utils/api/upload";

export interface IImageProps {
  sources: string[];
  showLb: boolean;
  setShowLb: (val: boolean) => void;
  className?: string;
}

const ImageWithLightbox: React.FC<IImageProps> = ({
  sources,
  showLb,
  setShowLb,
  className = "",
}) => {
  const [lbIndex, setLbIndex] = useState(0);
  const urls = useMemo(
    () => sources.map(src => getDownloadUrl(src)),
    [sources],
  );
  const thumbnailImageSrc = useMemo(
    () => getDownloadUrl(sources[0], 220),
    [sources[0]],
  );

  return (
    <div>
      <img src={thumbnailImageSrc} alt="thumbnail" className={className} />
      {showLb ? (
        <Lightbox
          mainSrc={urls[lbIndex]}
          nextSrc={urls[(lbIndex + 1) % urls.length]}
          prevSrc={urls[(lbIndex + urls.length - 1) % urls.length]}
          onMovePrevRequest={() =>
            setLbIndex((lbIndex + urls.length - 1) % urls.length)
          }
          onMoveNextRequest={() => setLbIndex((lbIndex + 1) % urls.length)}
          onCloseRequest={() => setShowLb(false)}
        />
      ) : null}
    </div>
  );
};

export default ImageWithLightbox;
