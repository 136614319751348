import React, { useContext } from 'react';
import AppContext from '../../contexts/AppContext';
import RolesContext from '../../contexts/RolesContext';

export interface ISiteMapCard {
  imgSrc: string;
  title: string;
  label: string;
  handleImgClick?: () => void;
  handleDelete?: () => void;
  handlePrint?: () => void;
  showActions?: boolean;
}

const SiteMapCard: React.FC<ISiteMapCard> = ({
  imgSrc,
  title,
  label,
  handleImgClick,
  handleDelete,
  handlePrint,
  showActions,
}) => {
  const permCheck = useContext(RolesContext).userHasPermission;
  const canUpdate = permCheck('update', label);
  const appStore = useContext(AppContext);
  const user = appStore.get('user') || {};

  const canDelete = () => {
    const roles = [
      'Regional Manager',
      'Area / Assistant Manager',
      'Inspector',
      'Client Manager',
      'Client Inspector',
      'Regional Manager (External)',
      'Divisions Admin',
      'SWMP Admin',
    ];

    if (roles.includes(user.role.name)) {
      return false;
    } else return true;
  };

  return (
    <section className='card'>
      <div
        className='card-img card-cap'
        style={{
          backgroundImage: `url(${imgSrc})`,
          cursor: handleImgClick && canUpdate ? 'pointer' : 'initial',
        }}
        title={title}
        onClick={() => (handleImgClick && canUpdate ? handleImgClick() : null)}
      />

      <div className='card-body'>
        <p className='card-title'>{title}</p>

        {showActions && (
          <div className='card-toolbar'>
            {canUpdate && canDelete() && (
              <button className='outline warn small' onClick={handleDelete}>
                <i className='fa fa-trash' />
              </button>
            )}
          </div>
        )}
      </div>

      <div className='card-body-actions card-actions'>
        <button
          className='card-action pure-button button-primary'
          onClick={handleImgClick}
        >
          {canUpdate && showActions ? 'Update Site Map' : 'View Site Map'}
        </button>
        <button
          className='card-action pure-button button-outline-blue '
          onClick={handlePrint}
        >
          Print Site Map
        </button>
      </div>
    </section>
  );
};

export default SiteMapCard;
