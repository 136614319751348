import { uniqueId } from "lodash";
import React, { Dispatch, useEffect, useRef, useState } from "react";
import { useTimeoutFn } from "react-use";
import { usePdfEditor } from "../../../hooks/pdfEditor";
import LoadingModal from "../Loading/LoadingModal";

export type WebViewerProps = {
  id: number;
  guid: string;
  uploadId: number;
  filename: string;
  onSave: Dispatch<ArrayBuffer>;
};

export const WebViewer: React.FC<WebViewerProps> = ({
  guid,
  id,
  // filename,
  // onSave,
}) => {
  const [loading, setLoading] = useState(true);
  const [editorStatus, setEditorStatus] = useState<null | string>(null);
  const viewer = useRef<HTMLDivElement | null>(null);
  const componentId = useRef(uniqueId("pdfEditor"));
  const editor = usePdfEditor({
    ref: viewer,
    upload: { guid, id },
  });
  const clearStatusTimeout = useTimeoutFn(() => {
    setEditorStatus(null);
  }, 10000);
  const resetClearStatusTimeout = clearStatusTimeout[2];
  const cancelClearStatusTimeout = clearStatusTimeout[2];

  // cancel immediately
  cancelClearStatusTimeout();

  useEffect(() => {
    // console.log("editor", editor);

    if (editor.status === "loaded") {
      setLoading(false);
    }

    if (editor.status === "saving") {
      setEditorStatus(editor.status);
    }

    if (editor.status === "saved") {
      const date = new Date();

      setEditorStatus(
        `Saved: ${date.toDateString()} @ ${date.toLocaleTimeString()}`,
      );
    }
  }, [editor]);

  useEffect(() => {
    if (editorStatus) {
      resetClearStatusTimeout();
    }
  }, [editorStatus]);

  return (
    <div
      id={componentId.current}
      className="webviewer-container loading-modal-container"
    >
      <div className="webviewer-editor" ref={viewer} />
      <LoadingModal
        show={loading}
        portalQuerySelector={`#${componentId.current}`}
      />
      {editorStatus ? (
        <p className="webviewer-status"> {editorStatus}</p>
      ) : null}
    </div>
  );
};

export default WebViewer;
