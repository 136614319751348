import React, { MouseEventHandler } from "react";
import { Modal } from "@sw-sw/lib-ui";
import Loading from "../ResourceIndex/Loading";
import WebViewer, { WebViewerProps } from "./WebViewer";

function WebViewerModal({
  show,
  onClose,
  ...viewerProps
}: {
  show: boolean;
  onClose: MouseEventHandler;
} & WebViewerProps) {
  return (
    <Modal
      classes="webviewer-modal"
      handleClose={onClose}
      show={show}
      hideButtons
      disableClose
      dismissOnBgClick
      isExtraWide
    >
      {viewerProps.guid && viewerProps.filename ? (
        <WebViewer {...viewerProps} />
      ) : (
        <Loading />
      )}
    </Modal>
  );
}

WebViewerModal.propTypes = {};

WebViewerModal.defaultProps = {
  show: false,
};

export default WebViewerModal;
