import React, { useContext, Fragment } from "react";
import { string, arrayOf, shape, number, oneOf, bool } from "prop-types";
import classnames from "classnames";
import moment from "moment";

import WeatherContext from "../../contexts/WeatherContext";
import { LoadingIcon } from "@sw-sw/lib-ui";

function WeatherIcon(props) {
  function getIconClass(icon) {
    switch (icon) {
      case "clear-day":
        return "wi-day-sunny";

      case "clear-night":
        return "wi-night-clear";

      case "wind":
        return "wi-strong-wind";

      case "rain":
      case "snow":
      case "sleet":
      case "fog":
        return `wi-${icon}`;

      case "partly-cloudy-day":
        return "wi-day-cloudy";

      case "partly-cloudy-night":
        return "wi-night-alt-cloudy";

      case "cloudy":
      default:
        return "wi-cloudy";
    }
  }

  return (
    <i className={classnames("wi", getIconClass(props.icon), "pad-right")} />
  );
}

function DeniedWidget(props) {
  return (
    <section className={classnames("weather weather-denied", props.className)}>
      <WeatherIcon />
      <div>
        {props.error ? (
          <Fragment>
            <p className="bold">No weather available</p>
            <p>There was an error fetching weather data</p>
          </Fragment>
        ) : (
          <Fragment>
            <p className="bold">
              Get daily weather updates by sharing your location
            </p>
            <p>
              To enable weather, allow your browser to access your current
              location through your browser's settings
            </p>
          </Fragment>
        )}
      </div>
    </section>
  );
}

function WeatherForecastWidget(props) {
  return (
    <section className={classnames("weather", props.className)}>
      {!props.weather.length ? (
        <div className="loading-container">
          <LoadingIcon />
        </div>
      ) : (
        <Fragment>
          <section className="weather-forecast">
            {props.weather.map(weather => (
              <div key={weather.time} className="weather-day">
                <WeatherIcon icon={weather.icon} />
                <div>
                  <p className="date">
                    {moment.unix(weather.time).format("ddd M/DD")}
                  </p>
                  <div className="weather-details-wrapper">
                    <div className="weather-details">
                      <p className="high">
                        {Math.round(weather.temperatureMax)}&deg;
                      </p>
                      <p className="label">High</p>
                    </div>

                    <div className="weather-details">
                      <p>{Math.round(weather.temperatureMin)}&deg;</p>
                      <p className="label">Low</p>
                    </div>

                    <div className="weather-details">
                      <p>{Math.round(weather.precipProbability)}%</p>
                      <p className="label">Precip.</p>
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </section>
          <div className="poweredby">
            <a
              href="https://darksky.net/poweredby/"
              target="_blank"
              rel="noopener noreferrer"
            >
              Powered by Dark Sky
            </a>
          </div>
        </Fragment>
      )}
    </section>
  );
}

function WeatherWidget(props) {
  const weatherStore = useContext(WeatherContext);

  return weatherStore.location.denied || weatherStore.error ? (
    <DeniedWidget {...props} error={weatherStore.error} />
  ) : (
    <WeatherForecastWidget {...props} weather={weatherStore.weather} />
  );
}

const icons = [
  "clear-day",
  "clear-night",
  "rain",
  "snow",
  "sleet",
  "wind",
  "fog",
  "cloudy",
  "partly-cloudy-day",
  "partly-cloudy-night",
];
WeatherIcon.propTypes = {
  icon: oneOf(icons),
};

const weatherProps = {
  className: string,
};
const weatherShape = shape({
  time: number.isRequired,
  temperatureMax: number.isRequired,
  temperatureMin: number.isRequired,
  precipProbability: number.isRequired,
  icon: oneOf(icons),
});
WeatherForecastWidget.propTypes = {
  ...weatherProps,
  weather: arrayOf(weatherShape).isRequired,
};
DeniedWidget.propTypes = {
  ...weatherProps,
  error: bool.isRequired,
};
WeatherWidget.propTypes = {
  ...weatherProps,
};

export default WeatherWidget;
