import React, { useState, useContext } from "react";

import ResourceDetailDelete from "../Shared/ResourceDetail/ResourceDetailDelete";
import DivisionContext from "../../contexts/DivisionContext";
import divisionApi from "../../utils/api/division";
import { Redirect } from "react-router-dom";
import { AppDivisionContext } from "../../contexts/AppDivisionContext";

export interface IDivisionDeleteProps {
  onDelete?: Function;
}

const DivisionDelete: React.FC<IDivisionDeleteProps> = ({
  onDelete = undefined,
}) => {
  const { refetchDivisions } = useContext(AppDivisionContext);
  const divisionContext = useContext(DivisionContext);
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [disableDelete, setDisableDelete] = useState(true);
  const [redirect, setRedirect] = useState(false);

  const handleSubmit = () => {
    setErrorMsg("");

    if (divisionContext.selectedDivision) {
      divisionApi
        .destroy(divisionContext.selectedDivision.id)
        .then(() => {
          if (typeof onDelete === "function") {
            onDelete();
          }
        })
        .then(() => {
          refetchDivisions();
          hideModal();
          setRedirect(true);
        })
        .catch(e => {
          if (e.message) {
            setErrorMsg(e.message);
          } else if (e.response && e.response.data && e.response.data.message) {
            setErrorMsg(e.response.data.message);
          } else {
            setErrorMsg("An error has occurred");
          }
        });
    }
  };

  const showModal = () => {
    setShow(true);
    setErrorMsg("");
  };

  const hideModal = () => {
    setShow(false);
    setDisableDelete(true);
  };

  const toggleDisabled = () => {
    setDisableDelete(!disableDelete);
  };

  if (redirect) {
    return <Redirect to="/divisions" />;
  }

  return (
    <ResourceDetailDelete
      resource="Division"
      handleSubmit={handleSubmit}
      confirmationMsg={
        <>
          This action will permanently delete{" "}
          <strong>{"Division Name" /* division.name */}</strong>. Do you wish to
          proceed?
        </>
      }
      show={show}
      errorMsg={errorMsg}
      hideModal={hideModal}
      showModal={showModal}
      disableDelete={disableDelete}
      toggleDisabled={toggleDisabled}
    />
  );
};

export default DivisionDelete;
