import { FormContext, FormControlProps, formControls } from "@sw-sw/lib-form";
import c from "classnames";
import { get, toNumber } from "lodash";
import React, { useContext, useEffect } from "react";

const field = {
  inspectionIds: "inspectionIds",
  timeFilter: "timeFilter",
  selectAll: "select_all",
};

export function InspectionIdsControl({
  className,
  ...props
}: FormControlProps) {
  const formContext = useContext(FormContext);
  const filter = get(formContext.value, field.timeFilter, 0);
  const selectAll = get(formContext.value, field.selectAll, false);

  // reset data when changing filter
  useEffect(() => {
    formContext.replace(field.inspectionIds, {});
    formContext.set(field.selectAll, false);
  }, [filter]);

  // select/unselect all
  useEffect(() => {
    if (selectAll) {
      formContext.set(
        field.inspectionIds,
        (props.options || []).reduce((newVal, opt) => {
          newVal[opt.value] = selectAll;

          return newVal;
        }, {}),
      );
    } else {
      formContext.replace(field.inspectionIds, {});
    }
  }, [selectAll]);

  // remove invalid options such as after date changes, during custom filtering
  useEffect(() => {
    const validIds = (props.options || []).map(opt => opt.value);
    const value = get(formContext.value, field.inspectionIds, {});

    const invalidValueItems = Object.keys(value)
      .filter(id => value[id] === true)
      .map(toNumber)
      .filter(id => !validIds.includes(id));

    if (invalidValueItems.length) {
      invalidValueItems.forEach(id => {
        props.onChange({
          [id]: false,
        });
      });

      formContext.set(field.selectAll, false);
    }
  }, [props.options]);

  return (
    <formControls.checkboxGroup
      className={c(className, "form-control--checkboxGroup")}
      {...props}
    />
  );
}
