import {
  faTimes, faSave, faArrowCircleLeft,
  faArrowCircleRight, faUndo
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useContext, useEffect, useState } from "react";
import { useMediaQuery } from "react-responsive";
import { Context as InteractionContext } from "./Interaction/InteractionContext";
import { Context as InstanceDataContext } from "./Positionable/InstanceDataContext";
import { Context as DataSourceContext } from "./DataSourceContext"
import { ProjectContext } from '../../contexts/ProjectContext';
import { useHistory } from 'react-router-dom';

/**
 * Initial state of options
 *
 * @typedef defaultOptions
 * @type {Array}
 */

function CancelButton(props) {
  return (
    <>
      <button className='pure-button pure-button-secondary' {...props}>
        <span>Cancel</span>
        <FontAwesomeIcon icon={faTimes} {...props} />
      </button>
    </>
  );
}

function UndoButton(props) {
  return (
    <>
      <button className='pure-button pure-button-secondary' {...props}>
        <span>Undo</span>
        <FontAwesomeIcon icon={faUndo} />
      </button>
    </>
  );
}

/**
 * Map Editor "Toolbar"
 *
 * Only "shows" the active tools, controlled by user interactions on map
 * Provides api for user to save or discard changes
 *
 * @todo separator in options
 */
export function Toolbar(props) {
  const { mapId, divisionId, projectId } = props;
  const interactionContext = useContext(InteractionContext);
  const dataContext = useContext(InstanceDataContext);
  const dc = useContext(DataSourceContext)
  const [busy, setBusy] = useState(false);
  const isTabletOrLess = useMediaQuery({ maxWidth: 768 });
  const [disableLeft, setDisableLeft] = useState(false);
  const [disableRight, setDisableRight] = useState(false);
  const [getMapId, setGetMapId] = useState(mapId);
  const [redirect, setRedirect] = useState();
  const projectContext = useContext(ProjectContext);

  if (!dataContext) {
    return null;
  }

  const onDiscard = () => {
    dataContext.discard();
    interactionContext.setPositionableType(null);
  };
  const onUndo = () => {
    dataContext.undo();
  };
  const onSave = () => {
    setBusy(true);
    interactionContext.setDefaultMode();

    dataContext.save().then(
      () => {
        interactionContext.setPositionableSourceModel(null);
        setBusy(false);
      },
      (err) => {
        setBusy(false);

        return Promise.reject(err);
      },
    );
  };

  const filterDocs = projectContext.projectDocs.documentTypes
    .filter((ele) => ele.name === 'Active Site Maps')[0]
    .documents.map((e) => e.GUID);

  let counter = filterDocs.indexOf(mapId);

  useEffect(() => {
    if (counter === 0) {
      setDisableLeft(true);
    }
    if (counter === filterDocs.length - 1) {
      setDisableRight(true);
    }
  }, []);
  const toggleMap = (name) => {
    if (name === 'toggleLeft') {
      if (counter === 0) {
        setRedirect(false);
      } else {
        setRedirect(true);
        setGetMapId(filterDocs[counter - 1]);
      }
    }
    if (name === 'toggleRight') {
      if (counter === filterDocs.length - 1) {
        setRedirect(false);
      } else {
        setRedirect(true);
        setGetMapId(filterDocs[counter + 1]);
      }
    }
  };
  const getUrl = useHistory();
  const getStr = getUrl.location.search;
  const testReg = getStr.match('active')

  if (redirect === true) {
    const ext = testReg !== null ? 'active-map' : 'inspection'
    const divId = divisionId || localStorage.getItem("divisionId")
    const isPublic = window.location.pathname.startsWith("/public")

    window.location.href = isPublic ? `/public/divisions/${divId}/projects/${projectId}/map/${getMapId}/edit?from=${ext}` : `/divisions/${divId}/projects/${projectId}/map/${getMapId}/edit?from=${ext}`
  }

  return (
    <div
      className={
        'map-editor-toolbar'
      }
      style={{ justifyContent: 'space-between' }}
    >
      <div
        className={'map-editor-Angle'}
        onClick={() => toggleMap('toggleLeft')}
      >
        {disableLeft ? null : <FontAwesomeIcon icon={faArrowCircleLeft} />}
      </div>

      {dataContext.hasPendingUpdates && (
        <div className='map-editor-toolbar-actions'>
          <CancelButton onClick={onDiscard} />

          <button
            className='pure-button pure-button-primary'
            onClick={onSave}
            disabled={busy}
          >
            <span>Save</span>
            <FontAwesomeIcon
              className='map-editor-save-icon'
              icon={faSave}
            />
          </button>
          {dc.undo.length ? (
            <UndoButton onClick={onUndo} />
          ) : null}
        </div>
      )}

      {!dataContext.hasPendingUpdates && dc.undo.length ? (
        <div className='map-editor-toolbar-actions'>
          <UndoButton onClick={onUndo} />
        </div>
      ) : null}

      {!dataContext.hasPendingUpdates && interactionContext.mode === 'modify' && (
        <div className='map-editor-toolbar-actions'>
          <CancelButton onClick={onDiscard} isTabletOrLess={isTabletOrLess} />
        </div>
      )}
      <div
        className={'map-editor-Angle'}
        onClick={() => toggleMap('toggleRight')}
      >
        {disableRight ? null : <FontAwesomeIcon icon={faArrowCircleRight} />}
      </div>
    </div>
  );
}

export default Toolbar;