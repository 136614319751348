import { FormContext, FormHelpText } from "@sw-sw/lib-form";
import { LoadingIcon } from "@sw-sw/lib-ui";
import { AxiosError } from "axios";
import classnames from "classnames";
import { noop } from "lodash";
import PropTypes from "prop-types";
import React, {
  MouseEventHandler,
  PropsWithChildren,
  SyntheticEvent,
  useContext,
} from "react";
import TinyIconButton from "../../TinyIconButton";

export type SubmitHandlerFn = (
  data: any,
  event: SyntheticEvent,
) => Promise<void>;

/**
 * Generic form submission handler
 */
export const submitHandler = (
  formContext: React.ContextType<typeof FormContext>,
  event: SyntheticEvent,
  callback: SubmitHandlerFn,
  errorMessageText?: string,
) => {
  event.preventDefault();

  if (formContext.isBusy) {
    return false;
  }

  formContext.setBusy(true);

  // reset error state
  formContext.setError(null);

  return formContext
    .validate()
    .then(valid => {
      if (valid) {
        /** @important the callback should reset "busy" state */

        return formContext.getTransformedData();
      } else {
        return Promise.reject({
          message: errorMessageText,
        });
      }
    })
    .then(data => Promise.resolve(callback(data, event)))
    .then((...args) => {
      if (formContext.isMounted.current === true) {
        formContext.setBusy(false);
      }

      return Promise.resolve(...args);
    })
    .catch(getSubmitErrorHandler(formContext.setError, formContext.setBusy));
};

export const getSubmitErrorHandler =
  (setError: (message: string) => void, setBusy: (busy: boolean) => void) =>
  (err: Error | AxiosError | any) => {
    const defaultError = "An unknown error has occurred";

    console.error("getSubmitErrorHandler err:", err);

    setBusy(false);

    // axios error
    if (err && err.response) {
      const { data } = err.response;
      const message = data.message ? data.message : defaultError;

      setError(message);
    }
    // invalid form error
    else if (err && err.message) {
      setError(err.message);
    }
    // unknown error
    else {
      setError(typeof err === "string" ? err : defaultError);
    }

    // don't propagate the error
    // return Promise.reject(err);
  };

export interface IFormActionsProps {
  onCancel?: MouseEventHandler;
  onSubmit: SubmitHandlerFn;
  small?: boolean;
  showCancel?: boolean;
  showSubmit?: boolean;
  showErrorText?: boolean;
  submitText?: string;
  cancelText?: string;
  errorMessageText?: string;
}

/**
 * Display a row of form-wide user feedback and form action buttons
 *
 * "small" option shows inline icon buttons
 */
const FormActions: React.FC<PropsWithChildren<IFormActionsProps>> = ({
  onCancel = () => {},
  onSubmit,
  small = false,
  showCancel = true,
  showSubmit = true,
  submitText = "Ok",
  cancelText = "Cancel",
  errorMessageText = "Invalid form data. Please review errors above.",
  showErrorText = true,
  children,
}) => {
  const formContext = useContext(FormContext);
  const isBusy = formContext.isBusy;

  return (
    <div
      className={classnames("form-actions", {
        "form-actions-small": small,
        "form-actions-is-busy": isBusy,
      })}
    >
      {formContext.error && showErrorText ? (
        <FormHelpText theme="error" content={formContext.error} center />
      ) : null}

      {small ? (
        <div className="form-actions-row">
          {children}

          {!isBusy && (
            <TinyIconButton
              iconName="close"
              onClick={onCancel}
              className="form-action-secondary"
              title={cancelText}
            />
          )}

          <TinyIconButton
            className="form-action-primary"
            iconClassName={classnames({
              "fa-spin": isBusy,
            })}
            iconName={isBusy ? "spinner" : "check"}
            disabled={isBusy || !formContext.isValid}
            onClick={(event: SyntheticEvent) =>
              submitHandler(formContext, event, onSubmit, errorMessageText)
            }
            title={submitText}
          />
        </div>
      ) : (
        <div className="form-actions-row">
          {children}

          {showCancel && (
            <button
              className="reversed form-action-secondary"
              type="button"
              onClick={onCancel}
            >
              {cancelText}
            </button>
          )}

          {showSubmit && (
            <button
              className="primary form-action-primary"
              type="submit"
              disabled={isBusy || !formContext.isValid}
              onClick={event =>
                submitHandler(formContext, event, onSubmit, errorMessageText)
              }
            >
              {isBusy ? <LoadingIcon /> : submitText}
            </button>
          )}
        </div>
      )}
    </div>
  );
};

FormActions.propTypes = {
  onCancel: PropTypes.func.isRequired, // (event) => void
  onSubmit: PropTypes.func.isRequired, // (value, event) => void

  /** Whether to render small icon buttons */
  small: PropTypes.bool,

  showCancel: PropTypes.bool,
  showSubmit: PropTypes.bool,

  submitText: PropTypes.string.isRequired,
  cancelText: PropTypes.string.isRequired,

  showErrorText: PropTypes.bool.isRequired,
  errorMessageText: PropTypes.string.isRequired,
};

FormActions.defaultProps = {
  onCancel: noop,
  small: false,
  showCancel: true,
  showSubmit: true,
  submitText: "Ok",
  cancelText: "Cancel",
  showErrorText: true,
  errorMessageText: "Invalid form data. Please review errors above.",
};

export default FormActions;
