import instance from "../xhrService";

/**
 * Control measures
 */
const url = (id = null) => {
  return "/api/legend-items" + (id ? `/${id}` : "");
};

/** Generic Legend Item api  */
export const legendItemApi = {
  index: (projectId = null, type = null, documentGroupId = null,search) => {
    return instance
      .get(url(null), {
        params: { type, projectId, documentGroupId, s: search}
      })
      .then(_ => _.data);
  },
  indexByProjectUsage: projectId => {
    return instance
      .get(`/api/projects/${projectId}/legend-items`)
      .then(_ => _.data);
  },
  create: (projectId, data) => {
    return instance
      .post(url(data.id || undefined), {
        ...data,
        projectId,
      })
      .then(_ => _.data);
  },
  createGlobal: data => {
    return instance.post(url(), data).then(_ => _.data);
  },
  update: (projectId, data) => {
    const reqData = { ...data };

    if (projectId) {
      reqData.projectId = projectId;
    }

    return instance.put(url(data.id), reqData).then(_ => _.data);
  },
  updateGlobal: data => {
    return legendItemApi.update(null, data);
  },
  destroy: (id, projectId) => {
    return instance
      .delete(url(id), {
        params: {
          projectId: projectId,
        },
      })
      .then(() => true);
  },
};

/**
 * Scope the index method of the generic {legendItemApi} with legend item type
 *
 */
export const controlMeasureApi = {
  ...legendItemApi,
  index: projectId => legendItemApi.index(projectId, "control_measure"),
};

export const pollutantApi = {
  ...legendItemApi,
  index: projectId => legendItemApi.index(projectId, "pollutant"),
};

export default legendItemApi;
