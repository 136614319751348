import React, { useContext, useEffect, useMemo } from "react";
import PropTypes from "prop-types";
// import update from "immutability-helper";

// components and helpers
import GroupDelete from "./GroupDelete";
import GroupDocuments from "./GroupDocuments";
import GroupUpdate from "./GroupUpdate";
import ResourceDetailTitle from "../Shared/ResourceDetail/ResourceDetailTitle";
import ResourceDetailTabs from "../Shared/ResourceDetail/ResourceDetailTabs";

import RegulationContext from "../../contexts/RegulationContext";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import RolesContext from "../../contexts/RolesContext";
import { LoadingMessage } from "@sw-sw/lib-ui";
import {
  useDeleteRegulationDoc,
  useGroupDocs,
  useBmpDocs,
  useUpdateRegulationDoc,
  useUploadRegulationDoc,
  useUploadBmpDoc,
  useOnMoveRegulationDoc,
  useOnDropRegulationDoc,
  useDeleteBmpDoc,
  ScopeType,
  useUpdateBmpDoc,
  useOnMoveBmpDoc,
  useOnDropBmpDoc,
} from "../../hooks/groupDocs";

const GroupDetailUI = props => {
  const regulationContext = useContext(RegulationContext);
  const { getPath } = useContext(AppDivisionContext);
  const { id } = props.match.params;

  const groupDocsQuery = useGroupDocs(id);
  const bmpDocsQuery = useBmpDocs(id);
  const deleteRegulationDocMutation = useDeleteRegulationDoc(id);
  const deleteBmpDocMutation = useDeleteBmpDoc(id);
  const uploadRegulationMutation = useUploadRegulationDoc(id);
  const uploadBmpMutation = useUploadBmpDoc(id);
  const updateRegulationMutation = useUpdateRegulationDoc(id);
  const updateBmpMutation = useUpdateBmpDoc(id);
  const moveRegulationMutation = useOnMoveRegulationDoc(id);
  const moveBmpMutation = useOnMoveBmpDoc(id);
  const dropRegulationMutation = useOnDropRegulationDoc();
  const dropBmpMutation = useOnDropBmpDoc();

  const { permCheck } = props;

  const formatScope = scope => {
    return `${scope}_docs`;
  };

  const onDocumentDelete = (docId, scope) => {
    formatScope(scope) === ScopeType.REGULATIONS_DOCS
      ? deleteRegulationDocMutation.mutate({ scope: formatScope(scope), docId })
      : deleteBmpDocMutation.mutate({ scope: formatScope(scope), docId });
  };

  const updateGroup = group => {
    return regulationContext.updateRegulation(group);
  };

  const deleteGroup = () => {
    props.history.push(getPath("/groups"));
  };

  const onDocumentUpload = (file, scope, legendItemId) => {
    formatScope(scope) === ScopeType.REGULATIONS_DOCS
      ? uploadRegulationMutation.mutate({
          scope: formatScope(scope),
          file,
        })
      : uploadBmpMutation.mutate({
          scope: formatScope(scope),
          file,
          legendItemId,
        });
  };

  const onDocumentEdit = (file, scope) => {
    formatScope(scope) === ScopeType.REGULATIONS_DOCS
      ? updateRegulationMutation.mutate({ scope: formatScope(scope), file })
      : updateBmpMutation.mutate({ scope: formatScope(scope), file });
  };

  const onMove = ({ scope, hoverIndex, dragIndex, legendItemId }) => {
    formatScope(scope) === ScopeType.REGULATIONS_DOCS
      ? moveRegulationMutation.mutate({
          scope: formatScope(scope),
          hoverIndex,
          dragIndex,
          legendItemId,
        })
      : moveBmpMutation.mutate({
          scope: formatScope(scope),
          hoverIndex,
          dragIndex,
          legendItemId,
        });
  };

  const onDrop = ({ scope, hoverIndex, dragIndex, document, index }) => {
    formatScope(scope) === ScopeType.REGULATIONS_DOCS
      ? dropRegulationMutation.mutate({
          scope: formatScope(scope),
          hoverIndex,
          dragIndex,
          document,
          index,
        })
      : dropBmpMutation.mutate({
          scope: formatScope(scope),
          hoverIndex,
          dragIndex,
          document,
          index,
        });
  };

  const docs = useMemo(() => {
    const organizeDocs = () => {
      const regulations_docs = [
        { name: "Regulations", id: 0, group_docs: [] },
      ].map(regulation => {
        return { ...regulation, group_docs: groupDocsQuery.data.group_docs };
      });

      return {
        regulations_docs,
        bmp_docs: bmpDocsQuery.data,
        groupData: groupDocsQuery.data,
      };
    };

    if (groupDocsQuery.data || bmpDocsQuery.data) {
      return organizeDocs();
    } else {
      return {};
    }
  }, [groupDocsQuery, bmpDocsQuery]);

  useEffect(() => {
    if (docs && docs.groupData) {
      updateGroup(docs.groupData);
    }
  }, [docs]);

  if (!groupDocsQuery.data || !bmpDocsQuery.data) {
    return <LoadingMessage what="Documents" />;
  }

  return regulationContext.regulation ? (
    <section className="project-detail group-detail">
      <div className="group-header">
        {regulationContext.regulation && regulationContext.regulation.name ? (
          <ResourceDetailTitle title={regulationContext.regulation.name} />
        ) : null}
        <div className="project-buttons">
          {permCheck("update", "Regulations") && (
            <GroupUpdate
              group={regulationContext.regulation}
              onGroupUpdate={updateGroup}
            />
          )}

          <div className="project-delete-button">
            {permCheck("delete", "Regulations") && (
              <GroupDelete
                group={regulationContext.regulation}
                onGroupDelete={deleteGroup}
              />
            )}
          </div>
        </div>
      </div>
      <ResourceDetailTabs
        headers={["Regulations", "BMP"]}
        panels={[
          regulationContext.regulation &&
          regulationContext.regulation.group_docs
            ? () => (
                <GroupDocuments
                  scope="regulations"
                  // @TODO fetch docs from db
                  documentGroups={docs.regulations_docs}
                  document_group_id={regulationContext.regulation.id}
                  onDocumentUpload={onDocumentUpload}
                  onDocumentEdit={onDocumentEdit}
                  onDocumentDelete={onDocumentDelete}
                  onMove={e => onMove(e, "regulations")}
                  onDrop={e => onDrop(e, "regulations")}
                  canDrag={permCheck("update", "Regulations")}
                />
              )
            : () => "No Documents",
          () => (
            <GroupDocuments
              scope="bmp"
              documentGroups={docs.bmp_docs}
              document_group_id={regulationContext.regulation.id}
              onDocumentUpload={onDocumentUpload}
              onDocumentEdit={onDocumentEdit}
              onDocumentDelete={onDocumentDelete}
              onMove={e => onMove(e, "bmp")}
              onDrop={e => onDrop(e, "regulations")}
              canDrag={permCheck("update", "Regulations")}
            />
          ),
        ].filter(_ => _)}
      />
    </section>
  ) : (
    <LoadingMessage what="Group Documents" />
  );
};

GroupDetailUI.propTypes = {
  history: PropTypes.shape({}).isRequired,
  match: PropTypes.shape({}).isRequired,
  permCheck: PropTypes.func.isRequired,
};

const GroupDetail = props => {
  const permCheck = useContext(RolesContext).userHasPermission;

  return <GroupDetailUI {...props} permCheck={permCheck} />;
};

export default GroupDetail;
