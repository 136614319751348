import React, { useState } from "react";

import ResourceDetailUpdate from "../Shared/ResourceDetail/ResourceDetailUpdate";
import GroupDetailsForm from "./Forms/GroupDetailsForm";
import groupApi from "../../utils/api/group";

export interface IGroupUpdateProps {
  group: { id: number; name: string };
  onGroupUpdate: (group: any) => void;
}

const GroupUpdate: React.FC<IGroupUpdateProps> = props => {
  const [show, setShow] = useState(false);

  function handleSubmit(formState: any) {
    
    if(!formState.name.trim()){
      throw new Error("Regulation name cannot be empty")
    }
    
    return groupApi.update(props.group.id, formState).then(group => {
      props.onGroupUpdate(group);
      setShow(false);

      return Promise.resolve();
    });
  }

  return (
    <ResourceDetailUpdate resource="Group" onButtonClick={() => setShow(true)}>
      {show && (
        <GroupDetailsForm
          handleSubmit={handleSubmit}
          handleClose={() => setShow(false)}
          title="Edit Group Details"
          group={props.group}
        />
      )}
    </ResourceDetailUpdate>
  );
};

export default GroupUpdate;
