import React, { useContext, useEffect } from "react";
import { Redirect } from "react-router-dom";

import ClientAdd from "./ClientAdd";
import RolesContext from "../../contexts/RolesContext";
import ResourceIndex from "../Shared/ResourceIndex/ResourceIndex";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import DivisionError from "../Shared/DivisionError/DivisionError";
import { getDivisionResourceName } from "../../hooks/divisionResource";
import { useMatomo } from "@datapunt/matomo-tracker-react";

const Clients: React.FC<{}> = () => {
  const roleStore = useContext(RolesContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const { trackPageView } = useMatomo();

  if (!appDivisionContext.appDivisionId) {
    return <DivisionError resource="clients" />;
  }

  useEffect(() => {
    trackPageView({});
  }, [trackPageView]);

  return (
    <ResourceIndex
      resourceName={getDivisionResourceName("clients")}
      resourceQueryProps={{ divisionId: appDivisionContext.appDivisionId }}
      resourceTableHeaders={[
        ["Name", "name"],
        ["Address", "f_address"],
        ["Date Created", "f_created_at"],
      ]}
      resourceSearchProps={["name", "f_address", "f_created_at"]}
      enableRead={roleStore.userHasPermission("read", "Client")}
      enableEdit={false}
      enableCallToAction={roleStore.userHasPermission("create", "Client")}
      callToActionContent="Add Client"
      searchInputPlaceholder="Search Clients"
      renderReader={client => {
        return (
          <Redirect
            to={appDivisionContext.getPath(`/clients/${client.id}`)}
            push
          />
        );
      }}
      renderCallToAction={handleClose => {
        return <ClientAdd handleClose={handleClose} />;
      }}
      enablePagination
      hideCallToActionInMobile={false}
    />
  );
};

export default Clients;
