import React from 'react'
import styles from './Toggle.module.scss';
export default function Toggle(props: any) {
    
    return (
        <>
            <label className={styles.switch} >
                <input type="checkbox" checked={props.isChecked} onChange={props.handleChange} id={props.id} />
                <span className={`${styles.slider} ${styles.round}`}></span>
            </label>
        </>
    )
}