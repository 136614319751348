import {
  ISignatureEditorData,
  SignatureGroupType,
  SignatureAttestation,
  SignatureLine
} from "@sw-sw/lib-certification";
import { FormContextProvider } from "@sw-sw/lib-form";
import { Button } from "@sw-sw/lib-ui";
import React, { useContext, useEffect, useState } from "react";
import AppContext from "../../../contexts/AppContext";
import {
  SignatureEditorContext,
  SignatureEditorMode,
} from "../../../contexts/SignatureEditorContext";
import { CertificationUI } from "../../Inspections/Certification/CertificationUI";
import Loading from "../../Shared/ResourceIndex/Loading";
import DeleteButton from "./Buttons/DeleteButton";
import EditButton from "./Buttons/EditButton";
import ReorderButtons, { ReorderActionPayload } from "./Buttons/ReorderButtons";
import { SignatureEditorButtonProps } from "./Buttons/SignatureEditorButton";
import EditModal from "./EditModal";
import inspectionTemplateApi from "../../../utils/api/inspectionTemplate";
import findingTypesApi from '../../../utils/api/findingTypes';

export const SignatureEditor: React.FC<{}> = ({ }) => {
  const appContext: any = useContext(AppContext);
  const signatureEditorContext = useContext(SignatureEditorContext);
  const [editorMode, setEditorMode] = useState<SignatureEditorMode>(
    SignatureEditorMode.LOADING,
  );
  const [data, setData] = useState<ISignatureEditorData>(
    JSON.parse(JSON.stringify(signatureEditorContext.data)),
  );
  const [showAck, setShowAck] = useState<any>();
  const [showComplianceCert, setShowComplianceCert] = useState<any>();
  const [templateData, setTemplateData] = useState<any>();
  const [findingTypeData, setFindingTypeData] = useState<any>();


  const [ack, setAck] = useState<boolean>(data.attestations.acknowledgement.length ? true : false)
  const templateId: number = data.attestations.compliance[0] && data.attestations.compliance[0].templateId
  const defaultAttestation: SignatureAttestation = {
    order: 0,
    templateId: templateId,
    signatureType: SignatureGroupType.acknowledgement,
    content:
      " I certify that this report is true, and complete, to the best of my knowledge and belief.",
  }
  const defaultSignature: SignatureLine = {
    type: SignatureGroupType.acknowledgement,
    label: null,
    roleFieldLabel: "Inspector Qualifications",
    userFieldLabel: "Inspector Name",
  }

  useEffect(() => {
    inspectionTemplateApi.get(templateId).then((res) => {      
      setShowAck(res.has_acknowledgement);
      setTemplateData(res);
      setShowComplianceCert(res.has_compliance_cert)
    });
    findingTypesApi.index(templateId).then((data:any)=>{
      setFindingTypeData(data);
    });
  }, [])

  const handleUpdate = async () => {
    templateData.has_acknowledgement = !showAck

    return await inspectionTemplateApi.updateAck(templateId, templateData).then(()=>{
      setShowAck(!showAck)
    });
  }

  useEffect(() => {
    setEditorMode(SignatureEditorMode.READ);
  }, []);

  if (editorMode === SignatureEditorMode.LOADING || !data) {
    return <Loading />;
  }

  const resetData = () => {
    if (signatureEditorContext.data.attestations.acknowledgement.length) {
      setAck(true)
    } else {
      setAck(false)
      setShowAck(false)
      handleUpdate()
    }

    if (signatureEditorContext.data) {
      setData(JSON.parse(JSON.stringify(signatureEditorContext.data)));
    }
  };

  const addAck = () => {
    data.attestations.acknowledgement.push(defaultAttestation)
    data.lines.acknowledgement.push(defaultSignature)
    setShowAck(true)
    setData(data)
    handleUpdate()
  }

  const onSave = async () => {
    data.has_compliance_cert = showComplianceCert;
    await signatureEditorContext.onUpdate(data);

    appContext.triggerSuccessNotification();
    setEditorMode(SignatureEditorMode.READ);
  };

  const onDelete = ({
    resourceIndex,
    resourceType,
    groupType,
  }: SignatureEditorButtonProps) => {
    let dataCopy = JSON.parse(JSON.stringify(data));

    dataCopy[resourceType][groupType].splice(resourceIndex, 1);

    setData(dataCopy);
  };

  const onReorder = ({
    resourceType,
    resourceIndex,
    targetIndex,
    groupType,
  }: ReorderActionPayload) => {
    let dataCopy = JSON.parse(JSON.stringify(data));

    let tempLine = dataCopy[resourceType][groupType][resourceIndex];

    dataCopy[resourceType][groupType][resourceIndex] =
      dataCopy[resourceType][groupType][targetIndex];
    dataCopy[resourceType][groupType][targetIndex] = tempLine;

    setData(dataCopy);
  };

  return (
    <div className="template-signature-editor">
      <div className="button-container">
        {editorMode === SignatureEditorMode.EDIT && (
          <>
            {!ack && <Button
              outline
              onClick={() => {
                addAck()
                setAck(true)
              }}
            >
              Add Acknowledgement
            </Button>}
            <Button primary onClick={onSave}>
              Save
            </Button>
          </>
        )}
        <Button
          outline
          onClick={() => {
            if (editorMode === SignatureEditorMode.EDIT) {
              setEditorMode(SignatureEditorMode.READ);
              resetData();
            } else {
              setEditorMode(SignatureEditorMode.EDIT);
            }
          }}
        >
          {`${editorMode === SignatureEditorMode.EDIT
            ? "Cancel"
            : "Edit Signatures"
            }`}
        </Button>
      </div>
      <FormContextProvider>
        <CertificationUI
          showCertificate={ack}
          signatureGroups={data.lines}
          attestationGroups={data.attestations}
          isCcr={false}
          minCertificationDate={new Date()}
          onSave={async (...args) => {
            console.trace(args);
          }}
          templateId={templateId}
          showAck={showAck}
          templateData={templateData}
          readOnly={true}
          showComplianceCert={showComplianceCert}
          setShowComplianceCert={setShowComplianceCert}
          fromSignature={true}
          findingTypeData={findingTypeData}
          renderEditButton={
            editorMode === SignatureEditorMode.EDIT
              ? props => <EditButton {...props} />
              : undefined
          }
          renderDeleteButton={
            editorMode === SignatureEditorMode.EDIT
              ? props =>
                data[props.resourceType][props.groupType].length > 1 ? (
                  <DeleteButton onDelete={onDelete} {...props} />
                ) : null
              : undefined
          }
          renderReorderButtons={
            editorMode === SignatureEditorMode.EDIT
              ? props => (
                <ReorderButtons
                  onReorder={onReorder}
                  resourceSize={data.lines[props.groupType].length}
                  {...props}
                />
              )
              : undefined
          }
        />
      </FormContextProvider>

      {signatureEditorContext.modal && (
        <FormContextProvider>
          <EditModal value={data} onSubmit={setData} />
        </FormContextProvider>
      )}
    </div>
  );
};
