import {
  FormContextProvider,
  SchemaFieldsWithContextUI,
} from '@sw-sw/lib-form';
import React, { MouseEventHandler } from 'react';
import { FormModal, SubmitHandlerFn } from '../../Shared/form';

/**
 * @deprecated see @sw-sw/lib-inspection-templates
 */
interface InspectionTemplate {
  name: string;
}

export interface IInspectionTemplateFormProps {
  handleSubmit: SubmitHandlerFn;
  handleClose: MouseEventHandler;
  title?: string;
  inspectionTemplate?: Partial<InspectionTemplate>;
  errorMsg?: boolean;
}

const schema = {
  name: {
    label: 'Name',
    validation: {
      required: true,
    },
  },
};

const InspectionTemplateDetailsForm: React.FC<IInspectionTemplateFormProps> = ({
  handleSubmit,
  handleClose,
  title = 'Add/Modify an Inspection Template',
  inspectionTemplate = { name: '' },
  errorMsg,
}) => {  
  return (
    <FormContextProvider>
      <FormModal
        onCancel={handleClose}
        onSubmit={handleSubmit}
        modalProps={{
          title: title,
        }}
      >
        <SchemaFieldsWithContextUI
          schema={schema}
          initialFormData={inspectionTemplate}
        />
        {errorMsg ?
         <h5 className='error-messages finding-error'>
           This finding type name already exist. Please try another.
         </h5>
          : ''}
      </FormModal>
    </FormContextProvider>
  );
};

export default InspectionTemplateDetailsForm;
