import {
  FormContext,
  FormContextProvider,
  SchemaFieldsWithContextUI,
} from "@sw-sw/lib-form";
import { LayoutTypes, Question } from "@sw-sw/lib-inspection-templates";
import { TooltipContextProvider } from "@sw-sw/lib-ui";
import { set } from "lodash";
import React, { MouseEventHandler, useContext } from "react";
import QuestionGroupContext from "../../../../contexts/QuestionGroupContext";
import TemplateEditorContext from "../../../../contexts/TemplateEditorContext";
import { useInspectionTypes } from "../../../../hooks/inspectionTypes";
import { FormModal, SubmitHandlerFn } from "../../../Shared/form";
import Loading from "../../../Shared/ResourceIndex/Loading";

export interface IQuestionDetailFormProps {
  title?: string;
  question?: Question;
  questionValues?: Array<any>;
  onCancel: MouseEventHandler;
  onSubmit: SubmitHandlerFn;
  order: number;
}

const QuestionDetailForm: React.FC<IQuestionDetailFormProps> = ({
  question = null,
  questionValues = null,
  onCancel,
  title = "Edit Question",
  onSubmit = () => Promise.resolve(),
  order,
}) => {
  const templateEditorContext = useContext(TemplateEditorContext);
  const questionGroupContext = useContext(QuestionGroupContext);
  const inspectionTypesQuery = useInspectionTypes();

  const getInitialValues = () => {
    if (question && questionValues) {
      const vals: { [key: string]: string | boolean } = {};

      templateEditorContext.questionConfigData.forEach(config => {
        const valueIndex = questionValues
          .map(_ => _.questionFieldId)
          .findIndex(_ => _ === config.id);

        if (valueIndex >= 0) {
          vals[`${config.fieldName}`] = questionValues[valueIndex].value;
        }
      });

      const valueIndex = questionValues.findIndex(_ =>
        Object.keys(_).includes("questionTypeFieldId"),
      );

      if (valueIndex >= 0) {
        vals["options"] = JSON.parse(questionValues[valueIndex].value);
      }

      return {
        ...question,
        ...vals,
      };
    } else {
      return {
        groupId: questionGroupContext.questionGroup.id,
        sort_order: order,
        features: [],
      };
    }
  };

  const getSchema = (values: Record<string, any>) => {
    const schema = Object.assign({}, templateEditorContext.defaultSchema);

    if (
      !values.question_type_id ||
      !templateEditorContext.questionsWithOptions.includes(
        parseInt(values.question_type_id),
      )
    ) {
      delete schema.options;
    }

    if (
      questionGroupContext.questionGroup.layout_type === LayoutTypes["TABLE"]
    ) {
      delete schema.question_width;
    }

    if (
      values.isInspectionType &&
      (values.question_type_id !== 2 ||
        JSON.stringify(values.options) !==
          JSON.stringify(inspectionTypesQuery.data))
    ) {
      set(values, "options", inspectionTypesQuery.data);
      set(values, "question_type_id", 2); // id of Select One
    }

    if (!values.isInspectionType) {
      set(schema, "question_type_id", {
        ...schema.question_type_id,
        disabled: false,
      });
      if (values.options) {
        set(schema, "options", { ...schema.options, disabled: false });
      }
    }

    return schema;
  };

  if (inspectionTypesQuery.isLoading || !inspectionTypesQuery.data) {
    return <Loading />;
  }

  return (
    <TooltipContextProvider>
      <FormContextProvider>
        <FormContext.Consumer>
          {formContext => (
            <FormModal
              onCancel={onCancel}
              onSubmit={onSubmit}
              modalProps={{ title: title }}
            >
              <SchemaFieldsWithContextUI
                schema={getSchema(formContext.value)}
                initialFormData={getInitialValues()}
              />
            </FormModal>
          )}
        </FormContext.Consumer>
      </FormContextProvider>
    </TooltipContextProvider>
  );
};

export default QuestionDetailForm;
