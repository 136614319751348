import { FormSchema } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import React, { MouseEventHandler, useContext, useEffect } from "react";
import ProjectContext from "../../../contexts/ProjectContext";
import ProjectPrintContext, {
  InspectionDateFilter,
} from "../../../contexts/ProjectPrintContext";
import LoadingModal from "../../Shared/Loading/LoadingModal";
import PrintModal from "../../Shared/Print/PrintModal";
import {
  getInspectionFilterSchema,
  InspectionSchemaOptions,
} from "./PrintInspectionsModal";

export interface IPrintSwmpModal {
  onClose: MouseEventHandler<Element>;
}

const getPrintSchema = ({
  showInspectionSelection,
  ...schemaOptions
}: InspectionSchemaOptions & {
  showInspectionSelection: boolean;
}) => {
  const appendixOptions = [
    {
      label: "Construction Docs/Civil Drawings",
      value: "constructionDocs",
    },
    {
      label: "Soil Reports",
      value: "soilReports",
    },
    {
      label: "Delegation Letters",
      value: "delegationLetters",
    },
    {
      label: "Endangered Species",
      value: "endangeredSpecies",
    },
    {
      label: "Historical Properties",
      value: "historicalProperties",
    },
    {
      label: "Qualifications/Certifications",
      value: "qualifications",
    },
    {
      label: "Local Stormwater Regulations",
      value: "localRegulations",
    },
    {
      label: "MISC",
      value: "misc",
    },
  ];

  const schema: FormSchema = {
    narrative: {
      controlType: UIControlType.checkbox,
      label: "Narrative",
    },
    bmpDetails: {
      controlType: UIControlType.checkbox,
      label: "BMP Details",
    },
    inspections: {
      controlType: UIControlType.checkbox,
      label: "Inspections",
    },

    ...getInspectionFilterSchema(schemaOptions),

    maps: {
      controlType: UIControlType.checkbox,
      label: "Maps",
    },
    mapLog: {
      controlType: UIControlType.checkbox,
      label: "Map Log",
    },
    permits: {
      controlType: UIControlType.checkbox,
      label: "Permits",
    },
    generalPermits: {
      controlType: UIControlType.checkbox,
      label: "General Permits",
    },
    appendix: {
      controlType: UIControlType.checkboxGroup,
      label: "Appendix",
      options: appendixOptions,
      valueKey: "value",
      labelKey: "label",
    },
  };

  if (!showInspectionSelection) {
    delete schema.timeFilter;
    delete schema.start_date;
    delete schema.end_date;
    delete schema.select_all;
    delete schema.inspectionIds;
    delete schema.inspectionText;
  }

  return schema;
};

const PrintSwmpModal: React.FC<IPrintSwmpModal> = ({ onClose }) => {
  const projectPrintContext = useContext(ProjectPrintContext);
  const projectContext = useContext(ProjectContext);

  const initialData = {
    narrative: true,
    bmpDetails: true,
    inspections: false,
    maps: true,
    mapLog: false,
    permits: true,
    generalPermits: false,
    appendix: {
      constructionDocs: false,
      soilReports: true,
      delegationLetters: true,
      endangeredSpecies: true,
      historicalProperties: true,
      qualifications: true,
      localRegulations: false,
      misc: false,
    },
    timeFilter: 3,
  };

  useEffect(() => {
    projectContext.inspectionsQuery.refetch();
  }, []);

  if (!projectContext.project || projectContext.inspectionsQuery.isFetching) {
    return <LoadingModal show />;
  }

  return (
    <PrintModal
      jobName={"swmp"}
      jobData={{
        projectId: projectContext.project.id,
      }}
      statusUpdateKey={projectContext.project.id}
      onClose={onClose}
      formSchema={(formData: Record<string, any>) => {
        const filter: InspectionDateFilter = {
          start: formData.start_date,
          end: formData.end_date,
          prevMonths: formData.timeFilter,
        };
        const inspections = projectPrintContext.getInspectionOptions(
          projectContext.inspections,
          filter,
        );

        return getPrintSchema({
          inspections,
          filter,
          filterOptions: projectPrintContext.timeFilterOptions,
          showInspectionSelection: formData.inspections,
          inspectionTextLabel: projectPrintContext.getInspectionText({
            inspections,
            filter,
          }),
        });
      }}
      initialFormData={initialData}
      modalTitle="Print SWMP"
      validateData={projectPrintContext.validateInspectionData}
    />
  );
};

export default PrintSwmpModal;
