import instance from "../xhrService";
import { env } from "../../config";

const uploadBaseUrl = "/api/uploads";

/**
 * @param {boolean} [omitXfdf] Whether or not to omit the user-generated annotations. Only supported for PDF file types.
 */
const getDownloadUrl = (
  guid,
  size = null,
  omitXfdf = false,
  date = Date.now(),
  projectId = null,
) => {
  const host = env.REACT_APP_API_BASE_URL;
  const params = [`version=${date}`];

  if (size) {
    params.push(`size=${size}`);
  }

  if (omitXfdf) {
    params.push(`omitXfdf=${omitXfdf}`);
  }

  if (projectId) {
    params.push(`projectId=${projectId}`);
  }

  return `${host}/dl/${guid}?${params.join("&")}`;
};
const getGroupUploadUrl = (a, b) =>
  `/api/groups/${a}/uploads` + (b ? `/${b}` : "");
const getControlUploadUrl = (a, b) =>
  `/api/legend-items/${a}/uploads` + (b ? `/${b}` : "");
const getProjectUploadUrl = (a, b) =>
  `/api/projects/${a}/uploads` + (b ? `/${b}` : "");
const getFindingUploadUrl = (a, b) =>
  `/api/findings/${a}/uploads` + (b ? `/${b}` : "");
const getDocumentSortUrl = modelName => `/api/documents/${modelName}/sort`;

/**
 * @todo add other document APIs
 */
const uploadApi = {
  create(file, name = null) {
    const formData = new FormData();

    formData.append("file", file, name || file.name);

    return instance
      .post(uploadBaseUrl, formData)
      .then(res => res.data)
      .catch(function (err) {
        console.log("upload failure!!", err);

        return err;
      });
  },
  replace(uploadGuid, file) {
    const formData = new FormData();

    formData.append("file", file, file.name);

    return instance
      .put(`${uploadBaseUrl}/${uploadGuid}`, formData)
      .then(res => res.data)
      .catch(function (err) {
        console.log("upload (replace) failure!!", err);

        return err;
      });
  },
  rename(uploadGuid, fileName) {
    return instance
      .put(`${uploadBaseUrl}/${uploadGuid}/name`, { name: fileName })
      .then(res => res.data)
      .catch(err => console.error(err));
  },
  group: {
    index(groupId) {
      return instance
        .get(getGroupUploadUrl(groupId))
        .then(response => response.data);
    },

    create(groupId, uploadId) {
      return instance
        .post(getGroupUploadUrl(groupId, uploadId), {
          /** @todo support multiple doc types per group */
          // document_type_id: groupTypeId,
        })
        .then(response => response.data);
    },

    destroy(groupId, uploadId) {
      return instance
        .delete(getGroupUploadUrl(groupId, uploadId))
        .then(response => response.data);
    },

    async sort({ id, order, documentGroupId, groupGUID }) {
      return await instance.put(getDocumentSortUrl("group"), {
        id,
        order,
        documentGroupId,
      });
    },
  },
  /** (global) legend Item regulations */
  groupControl: {
    indexByGroup(groupId, LegendItemIds = null) {
      const params = {};

      if (Array.isArray(LegendItemIds)) {
        params.LegendItemIds = LegendItemIds;
      }

      return instance
        .get(`/api/legend-items/group/${groupId}/uploads`, { params })
        .then(response => response.data);
    },

    create(groupId, controlId, uploadId) {
      return instance
        .post(getControlUploadUrl(controlId, uploadId), {
          document_group_id: groupId,
        })
        .then(() => uploadId);
    },

    destroy(groupId, controlId, uploadId) {
      return instance
        .delete(getControlUploadUrl(controlId, uploadId), {
          params: { document_group_id: groupId },
        })
        .then(() => true);
    },

    async sort({ id, order, legendItemId, documentGroupId }) {
      await instance.put(getDocumentSortUrl("legend"), {
        id,
        order,
        legendItemId,
        documentGroupId,
      });

      // const response = await uploadApi.groupControl.indexByGroup(
      //   documentGroupId,
      // );
      // return response.data;
    },
  },
  finding: {
    /**
     * create/update/delete finding images through single endpoint
     * @param {number} findingId
     * @param {{file: File, name: string, annotations?: any[]}[]} newFiles
     * @param {any[]} updatedFiles
     */
    update(findingId, newFiles, updatedFiles = []) {
      const formData = new FormData();

      formData.set("files[]", "");

      // attach new uploads
      newFiles.forEach(newFile => {
        const { file, name, annotations = [], rotation = 0 } = newFile;

        formData.append(`files[]`, file, name || file.name);
        formData.append(`annotations[]`, JSON.stringify(annotations || {}));
        formData.append(`rotations[]`, rotation);
      });

      updatedFiles.forEach(upload => {
        formData.append(`uploads[]`, JSON.stringify(upload));
      });

      return instance
        .post(getFindingUploadUrl(findingId), formData)
        .then(res => res.data);
    },
  },
  project: {
    create(uploadId, projectId, projectDocTypeId) {
      return instance
        .post(getProjectUploadUrl(projectId, uploadId), {
          project_doc_type_id: projectDocTypeId,
        })
        .then(response => response.data);
    },

    index(projectId, projectDocTypeId = undefined) {
      return instance
        .get(getProjectUploadUrl(projectId), {
          params: {
            project_doc_type_id: projectDocTypeId,
          },
        })
        .then(response => response.data);
    },

    destroy(projectId, uploadId) {
      return instance
        .delete(getProjectUploadUrl(projectId, uploadId))
        .then(response => response);
    },

    async sort({ id, order, projectId, projectDocTypeId }) {
      await instance.put(getDocumentSortUrl("project"), {
        id,
        order,
        projectId,
        projectDocTypeId,
      });
      const response = await uploadApi.project.index(projectId);

      return response.data;
    },
  },
  // read-only, one record per (project_upload, inspection)
  projectInspection: {
    index(projectId, inspectionId, docType) {
      const params = {};

      if (docType) {
        params.project_doc_type_id = docType;
      }

      return instance
        .get(`/api/projects/${projectId}/inspections/${inspectionId}/uploads`, {
          params,
        })
        .then(response => response.data);
    },
  },

  docTypes(name, projectId) {
    const params = {};

    if (name) {
      params.name = name;
    }

    if (projectId) {
      params.projectId = projectId;
    }

    return instance
      .get("/api/projects/docTypes", { params })
      .then(({ data }) => {
        if (name && data.length === 1) {
          return data[0];
        }

        return data;
      });
  },
  getDimensions(guid) {
    /** @todo use the JSON api (uploadApi.get) for this meta */
    return new Promise((resolve, reject) => {
      const img = new Image();

      img.addEventListener("load", function () {
        return resolve([this.naturalWidth, this.naturalHeight]);
      });

      img.addEventListener("error", function () {
        return reject("cannot load image");
      });

      img.src = getDownloadUrl(guid);
    });
  },
  getDownloadUrl,
};

export { getDownloadUrl, uploadApi };
export default uploadApi;
