import { Switch } from "@mui/material";
import {
  AnySignatureLineGroupData,
  CertFormSchema,
  CertSignature,
  SignatureGroupAttestations,
  SignatureGroupOptions,
  SignatureGroupType,
  SignatureLineGroups,
} from "@sw-sw/lib-certification";
import React, {
  FC, PropsWithChildren,
  useEffect, 
  useState
} from "react";
import {
  SignatureEditorFormType,
  SignatureResourceType,
} from "../../../contexts/SignatureEditorContext";
import inspectionTemplateApi from "../../../utils/api/inspectionTemplate"
import { SignatureEditorButton } from "../../InspectionTemplates/SignatureEditor/Buttons/SignatureEditorButton";
import CertificationForm from "./CertificationForm";
import ComplianceCertificationInstructions from "./ComplianceCertificationInstructions";
import InspectionCertificationStatement from "./InspectionCertificationStatement";

type CertUIProps = {
  attestationGroups: SignatureGroupAttestations;
  signatureGroups: SignatureLineGroups;
  optionGroups?: AnySignatureLineGroupData<SignatureGroupOptions>;
  userDataGroups?: AnySignatureLineGroupData<CertSignature[]>;
  onSave(data: {
    group: SignatureGroupType;
    lineType: string;
    formData: CertFormSchema;
  }): Promise<void>;
  isCcr?: boolean;
  isPe?: boolean;
  inspectorMinCertificationDate?: Date;
  minCertificationDate?: Date;
  readOnly?: boolean;
  Acknowledgement?: boolean;
  showAck?: boolean;
  renderEditButton?: SignatureEditorButton;
  renderDeleteButton?: SignatureEditorButton;
  renderReorderButtons?: SignatureEditorButton;
  templateId?: number;
  templateData?: any;
  showCertificate?: boolean;
  showComplianceCert?: any;
  setShowComplianceCert?: any;
  fromSignature?: boolean;
  findingTypeData?: any;
};

const handleUpdate = async (id: number | undefined, templateData: any, showAck?: boolean) => {
  templateData.has_acknowledgement = !showAck

  return await inspectionTemplateApi.updateAck(id, templateData);
}

export const CertificationUI: FC<PropsWithChildren<CertUIProps>> = ({
  attestationGroups,
  signatureGroups,
  optionGroups = {},
  userDataGroups = {},
  children,
  onSave,
  isCcr = false,
  isPe = false,
  inspectorMinCertificationDate,
  minCertificationDate,
  readOnly = false,
  renderEditButton,
  renderDeleteButton,
  renderReorderButtons,
  showAck,
  templateId,
  templateData,
  showCertificate = true,
  showComplianceCert,
  setShowComplianceCert,
  fromSignature=false,
  findingTypeData
}) => {
  const showActions = renderEditButton || renderDeleteButton;
  const [togglevalue, setToggleValue] = useState<any>()
  const [intialLoad, setIntialLoad] = useState(true);
  const [toggleComplianceCert, setToogleComplianceCert] = useState<boolean>();
  const [complianceToggleDisabled, setComplianceToggleDisabled] = useState<boolean>(false);
  const [complianceToggleErrorMessage, setcomplianceToggleErrorMessage] = useState<string>("");

  const handleToggleComplianceCertification = (currentState: boolean) => {
    setToogleComplianceCert(currentState);
    setShowComplianceCert(currentState);
  }

  useEffect(() => {
    if (showAck && intialLoad) {
      setToggleValue(showAck)
      setIntialLoad(false)
    }
  }, [showAck])


  useEffect(() => {
    if (showComplianceCert) {
      setToogleComplianceCert(showComplianceCert)
    }
  }, [showComplianceCert]);

  useEffect(()=>{
    if(findingTypeData){
      const data = findingTypeData.filter((item:any)=>item.details.is_required_for_cert === true);

      if(data.length>0){
        setComplianceToggleDisabled(true);
        let tempMsg = "* Following finding types are marked as required for sign compliance certification, Please unmark them to proceed : \n ";
        
        data.map((item:any,index:number)=>{
          switch(item.name) {
            case "CA":
              tempMsg += ` ${index+1}) Corrective Action`;
              break;
            case "MI":
              tempMsg += ` ${index+1}) Maintenance Item`;
              break;
            case "A":
              tempMsg += ` ${index+1}) Achievement`;
              break;
            default:
              tempMsg += ` ${index+1}) ${item.name}`;
          }
        });
        setcomplianceToggleErrorMessage(tempMsg);
      }
    }
  },[findingTypeData]);


  return (
    <section className="inspection-certification">
      <section>
        <h3>Inspection Certification</h3>
        <InspectionCertificationStatement
          attestations={attestationGroups[SignatureGroupType.inspection]}
        />

        {showActions
          ? (() => {
            const buttonProps = {
              resourceType: SignatureResourceType.ATTESTATION,
              resourceIndex: 0,
              groupType: SignatureGroupType.inspection,
            };

            return (
              <div className="attestation-edit-mode">
                {renderEditButton
                  ? renderEditButton({
                    ...buttonProps,
                    formType: attestationGroups[
                      SignatureGroupType.inspection
                    ].length
                      ? SignatureEditorFormType.EDIT
                      : SignatureEditorFormType.ADD,
                  })
                  : null}
                {renderDeleteButton &&
                  attestationGroups[SignatureGroupType.inspection].length > 1
                  ? renderDeleteButton(buttonProps)
                  : null}
              </div>
            );
          })()
          : null}

        {signatureGroups.inspection.map((line, index) => {
          const { type, label } = line;
          const buttonProps = {
            resourceType: SignatureResourceType.LINE,
            resourceIndex: index,
            groupType: SignatureGroupType.inspection,
          };

          return (
            <React.Fragment key={`${type}_${index}`}>
              <div className="pure-g">
                {label ? <h4 className="pure-u-1">{label}</h4> : null}
                <CertificationForm
                  line={line}
                  type={SignatureGroupType.inspection}
                  options={optionGroups[SignatureGroupType.inspection]}
                  userData={userDataGroups[SignatureGroupType.inspection]}
                  isCcr={isCcr && line.userFieldLabel === "Inspector Name"}
                  isPe={isPe && line.label === "Supervising Engineer"}
                  minCertificationDate={inspectorMinCertificationDate}
                  onSave={data => {
                    return onSave({
                      ...data,
                      group: SignatureGroupType.inspection,
                    });
                  }}
                  readOnly={readOnly}
                />
                {renderEditButton && renderEditButton(buttonProps)}
                {renderDeleteButton && renderDeleteButton(buttonProps)}
                {renderReorderButtons && renderReorderButtons(buttonProps)}
              </div>
            </React.Fragment>
          );
        })}
        {renderEditButton &&
          renderEditButton({
            resourceType: SignatureResourceType.LINE,
            resourceIndex: signatureGroups.inspection.length,
            formType: SignatureEditorFormType.ADD,
            groupType: SignatureGroupType.inspection,
          })}
        <hr />
      </section>

      {showCertificate &&
        <section>
          <h3>Acknowledgement</h3>
          <InspectionCertificationStatement
            attestations={attestationGroups[SignatureGroupType.acknowledgement]}
          />

          {showActions
            ? (() => {
              const buttonProps = {
                resourceType: SignatureResourceType.ATTESTATION,
                resourceIndex: 0,
                groupType: SignatureGroupType.acknowledgement,
              };

              return (
                <div className="attestation-edit-mode">
                  {renderEditButton
                    ? renderEditButton({
                      ...buttonProps,
                      formType: attestationGroups[
                        SignatureGroupType.acknowledgement
                      ].length
                        ? SignatureEditorFormType.EDIT
                        : SignatureEditorFormType.ADD,
                    })
                    : null}
                  {renderDeleteButton &&
                    attestationGroups[SignatureGroupType.acknowledgement].length > 1
                    ? renderDeleteButton(buttonProps)
                    : null}
                </div>
              );
            })()
            : null}

          {signatureGroups.acknowledgement.map((line, index) => {
            const { type, label } = line;
            const buttonProps = {
              resourceType: SignatureResourceType.LINE,
              resourceIndex: index,
              groupType: SignatureGroupType.acknowledgement,
            };

            return (
              <React.Fragment key={`${type}_${index}`}>
                <div className="pure-g">
                  {label ? <h4 className="pure-u-1">{label}</h4> : null}
                  <CertificationForm
                    line={line}
                    type={SignatureGroupType.acknowledgement}
                    options={optionGroups[SignatureGroupType.acknowledgement]}
                    userData={userDataGroups[SignatureGroupType.acknowledgement]}
                    isCcr={isCcr && line.userFieldLabel === "Inspector Name"}
                    isPe={isPe && line.label === "Supervising Engineer"}
                    minCertificationDate={inspectorMinCertificationDate}
                    onSave={data => {
                      return onSave({
                        ...data,
                        group: SignatureGroupType.acknowledgement,
                      });
                    }}
                    readOnly={readOnly}
                  />
                  {renderEditButton && renderEditButton(buttonProps)}
                  {renderDeleteButton && renderDeleteButton(buttonProps)}
                  {renderReorderButtons && renderReorderButtons(buttonProps)}
                </div>
              </React.Fragment>
            );
          })}
          {renderEditButton &&
            renderEditButton({
              resourceType: SignatureResourceType.LINE,
              resourceIndex: signatureGroups.acknowledgement.length,
              formType: SignatureEditorFormType.ADD,
              groupType: SignatureGroupType.acknowledgement,
            })}
          {renderEditButton &&
            <>
              <br />
              <div className={"form-group form-group--text"}>
                <label>
                  <br />
                  <span>
                    Show Acknowledgement
                  </span>
                </label>
                <Switch
                  checked={togglevalue}
                  onChange={() => handleUpdate(templateId, templateData, showAck).then((res: any) => {
                    if (res) {
                      setToggleValue(res.has_acknowledgement)
                    }
                  })}
                />
              </div>
            </>
          }
          <hr />
        </section>
      }

      {
        (showComplianceCert || fromSignature) &&
        <section>
          <h3>Compliance Certification</h3>
          {readOnly ? null : <ComplianceCertificationInstructions />}
          <InspectionCertificationStatement
            attestations={attestationGroups[SignatureGroupType.compliance]}
          />

          {renderEditButton && (
            <div className="attestation-edit-mode">
              {renderEditButton({
                resourceType: SignatureResourceType.ATTESTATION,
                resourceIndex: 0,
                groupType: SignatureGroupType.compliance,
              })}
              {renderDeleteButton &&
                renderDeleteButton({
                  resourceType: SignatureResourceType.ATTESTATION,
                  resourceIndex: 0,
                  groupType: SignatureGroupType.compliance,
                })}
            </div>
          )}

          {signatureGroups.compliance.map((line, index) => {
            const { type, label } = line;
            const buttonProps = {
              resourceType: SignatureResourceType.LINE,
              resourceIndex: index,
              groupType: SignatureGroupType.compliance,
            };

            return (
              <React.Fragment key={`${type}_${index}`}>
                <div className="pure-g">
                  {label && <h4 className="pure-u-1">{label}</h4>}

                  <CertificationForm
                    line={line}
                    type={SignatureGroupType.compliance}
                    options={optionGroups[SignatureGroupType.compliance]}
                    userData={userDataGroups[SignatureGroupType.compliance]}
                    minCertificationDate={minCertificationDate}
                    onSave={data =>
                      onSave({
                        ...data,
                        group: SignatureGroupType.compliance,
                      })
                    }
                    readOnly={readOnly}
                  />

                  {renderEditButton && renderEditButton(buttonProps)}
                  {renderDeleteButton && renderDeleteButton(buttonProps)}
                  {renderReorderButtons && renderReorderButtons(buttonProps)}
                </div>
              </React.Fragment>
            );
          })}

          {renderEditButton &&
            renderEditButton({
              formType: SignatureEditorFormType.ADD,
              resourceType: SignatureResourceType.LINE,
              resourceIndex: signatureGroups.compliance.length,
              groupType: SignatureGroupType.compliance,
            })}

          {
            renderEditButton &&
            <div className={"form-group form-group--text"} style={{ marginTop: '15px' }}>
              <label>
                <span>
                  Show Compliance Certification
                </span>
              </label>
              <Switch
                checked={toggleComplianceCert}
                disabled={complianceToggleDisabled}
                onChange={() => handleToggleComplianceCertification(!toggleComplianceCert)}
              />
            </div>
          }

          {
            (renderEditButton && complianceToggleErrorMessage && complianceToggleDisabled) && 
            <p style={{color:"red"}}>{complianceToggleErrorMessage}</p>
          }
          <hr />
        </section>
      }


      {children}
    </section>
  );
};

/** @todo Add to `certificiationApi.index` or profile. */
export function getcDOTObservationTemplates(clientName: string) {
  return [
    {
      label: '"Green Book" Statement',
      content: `${clientName || "Client"
        } is under contract to use the CDOT "Green Book". However, for ease of use with our electronic inspection platform, CDOT has allowed ${clientName || "client"
        } to use the 1176 form for the inspection documentation from the "Red Book"`,
    },
  ];
}
