import { TooltipContextProvider } from '@sw-sw/lib-ui';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import uploadApi from '../../utils/api/upload';
import ConfirmationModal from '../Shared/ConfirmationModal/ConfirmationModal';
import FileRenameModal from '../Shared/FileRow/FileRenameModal';
import FileRowList from '../Shared/FileRow/List';
import WebViewerModal from '../Shared/PDFEditor/WebViewerModal';
import SuccessModal from '../Shared/SuccessModal/SuccessModal';
import SuccessNotification from '../Shared/SuccessNotification/SuccessNotification';
class GroupDocuments extends Component {
  static propTypes = {
    documentGroups: PropTypes.arrayOf(PropTypes.object),
    onDocumentDelete: PropTypes.func,
    onDocumentUpload: PropTypes.func.isRequired,
    onDocumentEdit: PropTypes.func,
    document_group_id: PropTypes.number,
    canRename: PropTypes.bool,

    /** @todo remove distinction between bmp & regulations... both use concept of group id...bmp has extra request data ? */
    /** @todo API object prop */
    scope: PropTypes.oneOf(['bmp', 'regulations']),
    readOnly: PropTypes.bool,
  };

  static defaultProps = {
    canRename: true,
    readOnly: false,
  };

  constructor(props) {
    super(props);

    this.state = {
      showConfirm: false,
      showEditor: false,
      showSuccessNotification: false,
      showRename: false,
      filename: '',
      docGuid: null,
      docId: null,
      featureableId: -1,
      timeoutId: -1,
      groupId: -1,
      error: '',
      docType: {},
      canDrag: false,
    };

    this.featureableType = 'group_uploads';
  }

  showConfirm = () => {
    this.setState({
      showConfirm: true,
    });
  };

  hideConfirm = () => {
    this.setState({
      showConfirm: false,
    });

    return false;
  };

  handleConfirmSubmit = () => {
    this.setState({
      showConfirm: false,
      filename: '',
    });

    return true;
  };

  showRename = (document, docType) => {
    this.setState({
      showRename: true,
      docGuid: document.GUID,
      docId: document.id,
      filename: document.name,
      docType: docType,
    });
  };

  hideRename = () => {
    this.setState({
      docGuid: null,
      docId: null,
      filename: '',
      showRename: false,
      docType: {},
    });
  };

  handleRename = async (newName, groupId) => {
    const file = await uploadApi.rename(this.state.docGuid, newName);

    await this.props.onDocumentEdit(file.upload, this.props.scope);
  };

  hideEditor = () => {
    this.setState({ showEditor: false, id: '' });
  };

  handleUpload = ({ file, groupId, shouldUpdate }) => {
    // send to control_measures or group bridge tables
    switch (this.props.scope) {
      case 'bmp':
        return uploadApi.groupControl
          .create(this.props.document_group_id, groupId, file.id)
          .then(() => {
            // update documents array?
            if (shouldUpdate) {
              return this.props.onDocumentUpload(
                file,
                this.props.scope,
                groupId,
              );
            } else return file;
          })
          .catch((err) =>
            this.setState({
              error:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'An error has occurred. The document failed to upload',
            }),
          );

      case 'regulations':
      default:
        return uploadApi.group
          .create(this.props.document_group_id, file.id)
          .then((response) => {
            const detailedFile = { ...file, group_uploads: response };
            // update documents array?

            if (shouldUpdate) {
              return this.props.onDocumentUpload(
                detailedFile,
                this.props.scope,
              );
            } else return detailedFile;
          })
          .catch((err) =>
            this.setState({
              error:
                err.response && err.response.data && err.response.data.message
                  ? err.response.data.message
                  : 'An error has occurred. The document failed to upload',
            }),
          );
    }
  };

  handleDocumentDelete = (doc, groupId) => {
    const fileId = doc.id;

    let p = null;

    switch (this.props.scope) {
      case 'bmp':
        p = uploadApi.groupControl.destroy(
          this.props.document_group_id,
          groupId,
          fileId,
        );
        break;
      case 'regulations':
      default:
        p = uploadApi.group.destroy(this.props.document_group_id, fileId);
        break;
    }

    return (p || Promise.reject()).then(() => {
      return this.props.onDocumentDelete(fileId, this.props.scope, groupId);
    });
  };

  handleEdit = (document, docType) => {
    this.setState({
      showEditor: true,
      docId: document.id,
      docGuid: document.GUID,
      featureableId: document.group_uploads
        ? document.group_uploads.id
        : document.upload_id,
      groupId: docType.id,
      filename: document.name,
      oldDoc: document,
    });
  };

  onEdit = (data) => {
    this.props.onDocumentUpload();
  };

  handleDocumentReplace = async ({ oldDoc, newDoc, groupId }) => {
    // POST to uploads table
    return uploadApi
      .replace(oldDoc.GUID, newDoc)
      .then((result) =>
        this.props.onDocumentEdit(result.upload, this.props.scope),
      )
      .catch((err) =>
        this.setState({
          error:
            err.response && err.response.data && err.response.data.message
              ? err.response.data.message
              : 'An error has occurred. The document failed to upload',
        }),
      );
  };

  componentWillUnmount() {
    if (this.state.timeoutId > -1) {
      window.clearTimeout(this.state.timeoutId);
    }
  }
  render() {
    return (
      <TooltipContextProvider>
          <div className="documents">
          {this.props.documentGroups &&
            this.props.documentGroups.map(docType => (
              <FileRowList
                key={docType.id}
                groupName={docType.name}
                onMove={({ hoverIndex, dragIndex }) =>
                  this.props.onMove({
                    scope: this.props.scope,
                    hoverIndex,
                    dragIndex,
                    legendItemId: docType.id,
                  })
                }
                onDrop={({ hoverIndex, dragIndex, document, index }) =>
                  this.props.onDrop({
                    scope: this.props.scope,
                    hoverIndex,
                    dragIndex,
                    document,
                    index,
                  })
                }
                documents={docType.group_docs}
                canEdit={!this.props.readOnly}
                canPrint
                canDelete={!this.props.readOnly}
                canReplace={!this.props.readOnly}
                canUpload={!this.props.readOnly}
                canDrag={this.props.canDrag}
                hideTitle={this.props.scope === "regulations"}
                handleDelete={doc => this.handleDocumentDelete(doc, docType.id)}
                handleEdit={doc => this.handleEdit(doc, docType)}
                handleReplace={(newDoc, oldDoc) =>
                  this.handleDocumentReplace({
                    oldDoc,
                    newDoc,
                    groupId: docType.id,
                  })
                }
                canRename={!this.props.readOnly && this.props.canRename}
                handleRename={doc => this.showRename(doc, docType)}
                onUpload={file =>
                  this.handleUpload({
                    file,
                    groupId: docType.id,
                    shouldUpdate: true,
                  })
                }
                label="Regulations"
                showMoreThreshold={
                  this.props.scope === "regulations" ? 15 : undefined
                }
              />
            ))}
        </div>

        {/* load modals into DOM */}
        <ConfirmationModal
          show={this.state.showConfirm}
          handleClose={this.hideConfirm}
          handleConfirm={this.handleConfirmSubmit}
          title={`A document with the name ${this.state.filename} already exists.`}
          subTitle='Would you like to overwrite this document?'
          buttonText='Overwrite'
        />

        <WebViewerModal
          show={this.state.showEditor}
          onClose={this.hideEditor}
          onSave={(data) => this.onEdit(data.upload)}
          guid={this.state.docGuid}
          id={this.state.docId}
          filename={this.state.filename}
        />

        <SuccessNotification show={this.state.showSuccessNotification} />
        <SuccessModal
          show={this.state.error.length > 0}
          handleClose={() => this.setState({ error: '' })}
          handleSubmit={() => this.setState({ error: '' })}
          submitBtnText='OK'
          message={this.state.error}
          title='Error Uploading Document'
          isAlert
        />
        <FileRenameModal
          show={this.state.showRename}
          file={{ GUID: this.state.docGuid, name: this.state.filename }}
          onCancel={this.hideRename}
          onSubmit={(fileName) =>
            this.handleRename(fileName, this.state.docType.GUID)
          }
        />
      </TooltipContextProvider>
    );
  }
}
export default GroupDocuments;
