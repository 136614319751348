import { FormSchema, SchemaFormGroupProps } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { camelCase } from "lodash";
import React, { PropsWithChildren, useState } from "react";
import { useLocation } from "react-router-dom";
import divisionApi, { CreateApi, DeleteApi } from "../utils/api/division";

export interface IDivisionProviderProps extends PropsWithChildren<{}> {}

export interface IDivisionContextProps {
  disableSwitcher: boolean;
  setDisableSwitcher: Function;
  selectedDivision: Division | null;

  selectDivision: (id: number) => Promise<any>;
  tabs: { [key: string]: DivisionResource };
  /** Key of current tab. See `tabs` option. Resource name.*/
  selectedTab: string;
  setSelectedTab: (tab: string) => void;
  /** List of resource names. */
  resources: Array<string>;
  resetSelectedTab: () => void;
}

export interface Division {
  id: number;
  name: string;
}

const schemaTmpl: Partial<SchemaFormGroupProps> = {
  controlType: UIControlType.customTagSelect,
  labelKey: "name",
  valueKey: "id",
  isMulti: true,
};

type DivisionResource = {
  divisionApi: {
    create: CreateApi;
    delete: DeleteApi;
  };
  header: string;
  tableHeaders: [string, string][];
  callToActionContent: string;
  detailRoute?: (resourceObj: any) => string;
  formSchema: FormSchema;
  disablePagination?: true;
};

const tabs: {
  [key: string]: DivisionResource;
} = {
  regulations: {
    divisionApi: divisionApi.regulations,
    header: "Regulations",
    tableHeaders: [["Name", "name"]],
    callToActionContent: "Regulations",
    detailRoute: reg => `/groups/${reg.id}`,
    formSchema: {
      regulations: {
        ...schemaTmpl,
        label: "Regulations",
        noOptionMessage: "No regulations found",
        placeholder: "Search for regulations",
        validation: {
          required: true,
        },
      },
    },
  },
  inspectionTemplates: {
    divisionApi: divisionApi.inspectionTemplates,
    header: "Inspection Templates",

    tableHeaders: [["Name", "name"]],
    callToActionContent: "Templates",
    formSchema: {
      inspectionTemplates: {
        ...schemaTmpl,
        label: "Inspection Templates",
        noOptionMessage: "No Inspection Templates found",
        placeholder: "Search for Inspection Templates",
        validation: {
          required: true,
        },
      },
    },
    disablePagination: true,
  },
  clients: {
    divisionApi: divisionApi.clients,
    header: "Clients",

    tableHeaders: [["Name", "name"]],
    callToActionContent: "Clients",
    detailRoute: client => `/clients/${client.id}`,
    formSchema: {
      clients: {
        ...schemaTmpl,
        label: "Clients",
        noOptionMessage: "No clients found",
        placeholder: "Search for clients",
        validation: {
          required: true,
        },
      },
    },
  },
  users: {
    divisionApi: divisionApi.users,
    header: "Users",

    tableHeaders: [
      ["First Name", "first_name"],
      ["Last Name", "last_name"],
      ["Email", "email"],
      ["Role", "roleName"],
    ],
    callToActionContent: "Users",
    detailRoute: user => `/admin/?search=${user.email}`,
    formSchema: {
      users: {
        ...schemaTmpl,
        label: "Users",
        noOptionMessage: "No users found",
        placeholder: "Search for users",
        validation: {
          required: true,
        },
      },
    },
  },
};

const Context = React.createContext<IDivisionContextProps>(undefined!);

export const DivisionProvider: React.FC<IDivisionProviderProps> = ({
  children,
}) => {
  const location = useLocation();
  const [selectedDivision, setSelectedDivision] =
    useState<Division | null>(null);
  const resources = Object.keys(tabs);
  const [selectedTab, setSelectedTab] = useState(
    getInitialSelectedTab({ resources, pathname: location.pathname }),
  );
  const [disableSwitcher, setDisableSwitcher] = useState<boolean>(false);

  /** @todo refactor into hook */
  const selectDivision = async (id: number) => {
    const division = await divisionApi.get(id);

    setSelectedDivision(division);
  };

  const resetSelectedTab = () => {
    setSelectedTab(
      getInitialSelectedTab({ resources, pathname: location.pathname }),
    );
  };

  return (
    <Context.Provider
      value={{
        disableSwitcher,
        setDisableSwitcher,
        tabs,
        selectedTab,
        setSelectedTab,
        selectedDivision,
        selectDivision,
        resources,
        resetSelectedTab,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
export const DivisionContext = Context;

function getInitialSelectedTab({
  resources,
  pathname,
}: {
  resources: string[];
  pathname: string;
}) {
  const [path] = pathname.split("/").slice(-1);
  const resourceName = camelCase(path);

  if (resources.includes(resourceName)) {
    return resourceName;
  } else {
    return resources[0];
  }
}
