import React, { useContext, useEffect, useState } from "react";

import { Context as InteractionContext } from "./../InteractionContext";
import { Context as PositionableInstanceDataContext } from "../../Positionable/InstanceDataContext";
import { Context as DataSourceContext } from "../../DataSourceContext";
import { MapEditorRouteContext } from "../../../CustomRoutes/MapEditor";
import { Modal } from "@sw-sw/lib-ui";
import { controlMeasureApi, pollutantApi } from "../../../../utils/api/legendItem"

function DeleteFeature() {
  const interactionContext = useContext(InteractionContext);
  const data = useContext(MapEditorRouteContext)
  const dataContext = useContext(PositionableInstanceDataContext);
  const dataSource = useContext(DataSourceContext);
  const [name, setName] = useState(null);
  const positionalType = interactionContext.positionableType || interactionContext.modeData.feature.values_.positionable_type

  if (!interactionContext.modeData) {
    return null;
  }

  useEffect(async () => {
    const projectId = data.projectId
    const legenItemId = interactionContext.modeData.feature.values_.positionable_source_id

    if (positionalType === "control_measure") {
      controlMeasureApi.indexByProjectUsage(projectId).then((res) => {
        res.forEach((ele) => {
          if (ele.id === legenItemId) {
            setName(ele.name)
          }
        })
      })
    }
    if (positionalType === "pollutant") {
      pollutantApi.indexByProjectUsage(projectId).then((res) => {
        res.forEach((ele) => {
          if (ele.id === legenItemId) {
            setName(ele.name)
          }
        })
      })
    }
  }, [])


  const positionableData = interactionContext.positionableType ?
    dataSource.get(interactionContext.positionableType, interactionContext.positionableSourceModel) :
    dataSource.get(interactionContext.modeData.feature.values_.positionable_type, interactionContext.modeData.feature.values_.positionable_source_id)

  return (
    <Modal
      show={true}
      handleClose={() => interactionContext.setDefaultMode()}
      handleSubmit={() =>
        dataContext
          .delete(interactionContext.modeData.feature)
          .then(() => interactionContext.setDefaultMode())
      }
      classes="delete-feature-modal"
      submitBtnText="Remove from Map"
      hideHeader
      isSmall
      disableClose
      cancelBtn
      dismissOnEsc
      dismissOnBgClick
    >
      {name ?
        <p>{`Are you sure you want to remove ${name} from the map?`}</p> :
        positionableData ? <p>{`Are you sure you want to remove ${positionableData.name} from the map?`}</p> :
          <p>{`Are you sure you want to remove ${positionalType} from the map?`}</p>
      }
    </Modal>
  );
}

DeleteFeature.propTypes = {};

export default DeleteFeature;
