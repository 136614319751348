import React, { useCallback, useContext } from "react";
import { useHistory } from "react-router-dom";

import AppDivisionContext from "../../contexts/AppDivisionContext";
import { ProjectListProvider } from "../../contexts/ProjectListContext";
import DivisionError from "../Shared/DivisionError/DivisionError";
import ResourceIndex from "../Shared/ResourceIndex/ResourceIndex";

type Manual = {
  id: number;
  slug: string;
  title: {
    rendered: string;
  };
  content: {
    rendered: string;
  };
};

const ManualList = () => {
  const appDivisionContext = useContext(AppDivisionContext);
  const navigation = useHistory();

  const handleClick = useCallback(
    (manual: Manual) => () => {
      if (manual && manual.slug) {
        navigation.push(`/user-manuals/${manual.slug}`);
      }

      return null;
    },
    [],
  );

  const renderList = useCallback(
    (_, manuals: Manual[]) => (
      <div className="projects">
        <div className="flex-row project-list">
          {manuals == null || manuals.length === 0 ? (
            <p>No manuals found</p>
          ) : (
            manuals.map(manual => {
              const manualTitle = manual.title.rendered.replace(
                /[^a-zA-Z0-9]+/g,
                " ",
              );

              return (
                <div className="project" key={manual.id}>
                  <div className="project-main" onClick={handleClick(manual)}>
                    <h3>{manualTitle}</h3>
                  </div>
                </div>
              );
            })
          )}
        </div>
      </div>
    ),
    [],
  );

  if (!appDivisionContext.appDivisionId) {
    return <DivisionError resource="manuals" />;
  }

  const resourceName = "manuals";

  return (
    <ProjectListProvider>
      <ResourceIndex
        resourceName={resourceName}
        resourceQueryProps={{ divisionId: appDivisionContext.appDivisionId }}
        resourceTableHeaders={[["ID", "id"]]}
        enableRead={true}
        enableEdit={false}
        enableCallToAction={false}
        enableOverflow={false}
        hideCallToActionInMobile
        enablePagination
        searchInputPlaceholder="Search Manuals"
        renderList={renderList}
      />
    </ProjectListProvider>
  );
};

export default ManualList;
