import { InspectionTemplate } from "@sw-sw/lib-inspection-templates";
import React, { useState } from "react";
import inspectionTemplateApi from "../../utils/api/inspectionTemplate";
import ResourceDetailUpdate from "../Shared/ResourceDetail/ResourceDetailUpdate";
import InspectionTemplateDetailsForm from "./Forms/InspectionTemplateDetailsForm";

export interface IInspectionTemplateUpdateProps {
  inspectionTemplate: InspectionTemplate;
  onUpdate: () => void;
}

const InspectionTemplateUpdate: React.FC<IInspectionTemplateUpdateProps> = ({
  inspectionTemplate,
  onUpdate,
}) => {
  const [show, setShow] = useState<boolean>(false);

  const handleSubmit = async (formState: any) => {
    try {
      await inspectionTemplateApi.update(inspectionTemplate.id, formState);
      onUpdate();
      setShow(false);
    } catch (e) {
      console.error(e);
    }
  };

  return (
    <ResourceDetailUpdate
      resource="Inspection Template"
      onButtonClick={() => setShow(true)}
    >
      {show && (
        <InspectionTemplateDetailsForm
          handleSubmit={handleSubmit}
          handleClose={() => setShow(false)}
          title="Edit Inspection Template"
          inspectionTemplate={inspectionTemplate}
        />
      )}
    </ResourceDetailUpdate>
  );
};

export default InspectionTemplateUpdate;
