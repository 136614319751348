import { TooltipContextProvider } from '@sw-sw/lib-ui';
import { findIndex } from 'lodash';
import React, { useContext, useState } from 'react';
import { ProjectPermissionState } from '../../contexts/ProjectPermissionContext';
import RolesContext from '../../contexts/RolesContext';
import withProjectPermissionContext from '../../hoc/withProjectPermissionContext';
import uploadApi from '../../utils/api/upload';
import ConfirmationModal from '../Shared/ConfirmationModal/ConfirmationModal';
import ConvertDocModal from '../Shared/ConvertDocument/ConvertDocModal';
import FileRenameModal from '../Shared/FileRow/FileRenameModal';
import FileCardList from '../Shared/FileRow/List';
import WebViewerModal from '../Shared/PDFEditor/WebViewerModal';
import SuccessModal from '../Shared/SuccessModal/SuccessModal';
import './_ProjectDocument.scss';

const allowedTypes = [
  'image/jpg',
  'image/jpeg',
  'image/png',
  'application/pdf',
];

export interface IProjectDocumentsProps {
  documentTypes: Array<any>;
  regulations: Array<any>;
  onDocumentDelete: (guid: string) => void;
  onDocumentUpload: () => void;
  projectId: number;
  onMove: (e: MouseEvent, name: string) => void;
  onDrop: (e: MouseEvent, name: string) => void;
  shouldRenderRegulations: () => void;
  projectPermissionContext: ProjectPermissionState;
}

const ProjectDocuments: React.FC<IProjectDocumentsProps> = ({
  documentTypes,
  regulations,
  onDocumentDelete,
  onDocumentUpload,
  projectId,
  onMove,
  onDrop,
  shouldRenderRegulations,
  projectPermissionContext,
}) => {
  const rolesContext = useContext(RolesContext);
  const [confirm, setConfirm] = useState<boolean>(false);
  const [showEditor, setShowEditor] = useState<boolean>(false);
  const [rename, setRename] = useState<boolean>(false);
  const [showErrorModal, setShowErrorModal] = useState<boolean>(false);
  const [filename, setFilename] = useState<string>('');
  const [docGuid, setDocGuid] = useState<string>('');
  const [docId, setDocId] = useState<number>(-1);
  const [errorText, setErrorText] = useState<string>('');
  const [showConvert, setShowConvert] = useState<boolean>(false);
  const [convertQueue, setConvertQueue] = useState<Array<any>>([]);
  const [isWaiting, setIsWaiting] = useState<boolean>(false);
  const [docType, setDocType] = useState<any>({});

  const hideEditor = () => {
    setShowEditor(false);
    setDocGuid('');
    setDocId(-1);
  };

  const showRename = (document: any, documentType: any) => {
    setRename(true);
    setDocGuid(document.GUID);
    setDocId(document.id);
    setFilename(document.name);
    setDocType(documentType);
  };

  const hideRename = () => {
    setDocGuid('');
    setDocId(-1);
    setFilename('');
    setRename(false);
    setDocType({});
  };

  const handleRename = async (newName: any) => {
    await uploadApi.rename(docGuid, newName);
    hideRename();

    return onDocumentUpload();
  };

  const hideConfirm = () => {
    setConfirm(false);

    return false;
  };

  const onConfirmOverwrite = () => {
    hideConfirm();

    return true;
  };

  const handleUpload = (
    newFile: any,
    projectDocId: number,
    isSiteMaps: boolean,
  ) => {
    /** @todo "convert" */

    if (isSiteMaps && newFile.mime_type.includes('pdf')) {
      // push to conversion queue

      setShowConvert(true);
      setConvertQueue([
        ...convertQueue,
        {
          id: newFile.id,
          name: newFile.name,
          jtModelname: 'project_uploads',
          jtAtts: {
            project_id: projectId,
            project_doc_type_id: projectDocId,
          },
        },
      ]);

      return;
    }

    return uploadApi.project
      .create(newFile.id, projectId, projectDocId)
      .then(() => {
        return onDocumentUpload();
      })
      .catch((err) => {
        setErrorText(
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : 'An error has occurred',
        );
        setShowErrorModal(true);
      });
  };

  const handleDelete = (doc: any) => {
    uploadApi.project
      .destroy(projectId, doc.id)
      .then(() => {
        onDocumentDelete(doc.GUID);
      })
      .catch((err) => {
        setErrorText(
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : 'An error has occurred',
        );
        setShowErrorModal(true);
      });
  };

  const handleReplace = (doc: any, newDoc: any) => {
    return uploadApi
      .replace(doc.GUID, newDoc)
      .then(() => {
        onDocumentUpload();
        setIsWaiting(false);
      })
      .catch((err) => {
        setErrorText(
          err.response && err.response.data && err.response.data.message
            ? err.response.data.message
            : 'An error has occurred',
        );
        setShowErrorModal(true);
      });
  };

  // handle a converted file
  const handleConverted = () => {
    return onDocumentUpload();
  };

  const handleEdit = (document: any) => {
    setShowEditor(true);
    setDocGuid(document.GUID);
    setDocId(document.id);
    setFilename(document.name);
  };

  const onEdit = () => {
    onDocumentUpload();
  };

  const dequeue = (upload: any) => {
    // remove from conversion queue
    const inx = findIndex(convertQueue, {
      id: upload.id,
    });

    if (inx !== null) {
      setConvertQueue([
        ...convertQueue.slice(0, inx),
        ...convertQueue.slice(inx + 1),
      ]);
    }
  };

  const docGroups = [
    {
      name: 'Regulations',
      documents: regulations,
    },
    ...documentTypes,
  ];

  return (
    <TooltipContextProvider>
        <section className='documents'>
          {docGroups.map((documentType: any) => {
            if (
              documentType.name === 'Regulations' &&
              shouldRenderRegulations()
            ) {
              return (
                <FileCardList
                  permCheck={rolesContext.userHasPermission}
                  groupName={documentType.name}
                  documents={documentType.documents}
                  canUpload={false}
                  canPrint
                  canRename={false}
                  isWaiting={isWaiting}
                  key={documentType.id || documentType.name}
                  canDrag={false}
                  onMove={(e: any) => onMove(e, documentType.name)}
                  onDrop={(e: any) => onDrop(e, documentType.name)}
                />
              );
            }

            return (
              <FileCardList
              key={documentType.id}
              onMove={(e: any) => onMove(e, documentType.name)}
              onDrop={(e: any) => onDrop(e, documentType.name)}
              groupName={documentType.name}
              documents={documentType.documents}
              isWaiting={isWaiting}
              canPrint
              {...projectPermissionContext.getDocumentProps(docType.name)}
              handleRename={(doc: any) => showRename(doc, docType)}
              handleEdit={(doc: any) => handleEdit(doc)}
              handleReplace={(newDoc: any, doc: any) =>
                handleReplace(doc, newDoc)
              }
              onUpload={(newFile: any) =>
                handleUpload(
                  newFile,
                  documentType.project_doc_type_id,
                  documentType.name === "Active Site Maps",
                )
              }
              minimumImageDimensions={
                documentType.name === "Active Site Maps"
                  ? {
                      width: 1000,
                      height: 1000,
                    }
                  : {
                      width: 20,
                      height: 20,
                    }
              }
              handleDelete={(doc: any) => handleDelete(doc)}
              permCheck={(action: string, page: string) => {
                const contextAction = action === "read" ? "read" : "update";

                return (
                  rolesContext.userHasPermission(contextAction, "Project") &&
                  rolesContext.userHasPermission(action, page)
                );
              }}
              label="Regulations"
              allowedTypes={
                documentType.name === "Active Site Maps" ? allowedTypes : []
              }
            />
            );
          })}
        </section>

      {/* load modals into DOM */}
      <ConfirmationModal
        show={confirm}
        handleClose={hideConfirm}
        handleConfirm={onConfirmOverwrite}
        title='Are you sure you want to delete this document?'
        subTitle='This can not be undone.'
        buttonText='Delete Document'
      />

      <WebViewerModal
        show={showEditor}
        onClose={hideEditor}
        onSave={() => onEdit()}
        guid={docGuid}
        id={docId}
        uploadId={docId}
        filename={filename}
      />

      {/* invalid file type || DB error */}
      <SuccessModal
        show={showErrorModal}
        handleClose={() => setShowErrorModal(false)}
        handleSubmit={() => setShowErrorModal(false)}
        submitBtnText='OK'
        message={errorText}
        title='Error'
        isAlert
      />

      {showConvert && (
        <ConvertDocModal
          docs={convertQueue}
          handleClose={() => {
            setShowConvert(false);
            setConvertQueue([]);
          }}
          handleConverted={handleConverted}
          dequeue={dequeue}
        />
      )}
      <FileRenameModal
        show={rename}
        file={{ guid: docGuid, name: filename }}
        onCancel={hideRename}
        onSubmit={(fileName) => handleRename(fileName)}
      />
    </TooltipContextProvider>
  );
};

export default withProjectPermissionContext(ProjectDocuments);
