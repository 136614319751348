import React, { useEffect, useContext, useCallback } from "react";
import useMap from "../../useMap";
import { extractMapEventFeature } from "./ContextMenu/MapContextMenu";
import { getUtil } from "../../../../utils/positionable";
import { Context as InteractionContext } from "../InteractionContext";

function FeatureDetails({ readOnly = false }) {
  const map = useMap();
  const interactionContext = useContext(InteractionContext);

  const handleClick = useCallback(
    event => {
      const feature = extractMapEventFeature(event, map);

      let util;

      if (feature) {
        util = getUtil(feature.get("positionable_type"));
      }

      /**
       * Show overlay only if clicked on a feature
       * and overlay is supported by feature.
       *
       * If no feature clicked, close overlay, if active
       */
      if (feature && util && util.hasOverlay()) {
        interactionContext.setMode("detail", {
          feature,
          readOnly,
        });
      } else {
        interactionContext.setDefaultMode();
      }
    },
    [interactionContext],
  );

  useEffect(() => {
    map.on("singleclick", handleClick);

    return () => {
      map.un("singleclick", handleClick);
    };
  }, []);

  return <div />;
}

export default FeatureDetails;
