import { AxiosResponse } from "axios";
import { useMutation, useQuery, useQueryClient } from "react-query";
import legendItemApi from "../utils/api/legendItem";
import uploadApi from "../utils/api/upload";

type FileType = {
  id: number;
  name: string;
  GUID: string;
};

type BmpType = {
  id: number;
  name: string;
};

export type DocType = {
  name: string;
  id: number;
  group_docs: Array<FileType>;
  legend_item_id?: number;
  GroupUploadModel?: {
    id: number;
    document_group_id: number;
  };
  group_uploads?: {
    id: number;
    document_group_id: number;
  };
  GUID?: string;
  legend_item_upload_id?: number;
  document_group_id?: number;
};

export enum ScopeType {
  REGULATIONS_DOCS = "regulations_docs",
  BMP_DOCS = "bmp_docs",
}

export const useGroupDocs = (id: number) => {
  return useQuery([ScopeType.REGULATIONS_DOCS, id], () => {
    return uploadApi.group.index(id);
  });
};

export const useBmpDocs = (regulationId: number) => {
  return useQuery([ScopeType.BMP_DOCS, regulationId], () => {
    return legendItemApi.index(null, null, regulationId).then(bmps => {
      return uploadApi.groupControl
        .indexByGroup(regulationId)
        .then((data: Array<DocType>) => {
          if (bmps.length) {
            return bmps.map((bmp: BmpType) => {
              return {
                ...bmp,
                group_docs: data.filter(doc => doc.legend_item_id === bmp.id),
              };
            });
          } else {
            return [];
          }
        });
    });
  });
};

/**
 * Mutations
 */
export const useDeleteRegulationDoc = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => Promise.resolve(), {
    onMutate: (data: { scope: ScopeType; docId: number }) => {
      return queryClient.setQueryData<DocType>([data.scope, id], oldData => {
        const updatedDocs = oldData!.group_docs.filter(
          (doc: { id: number }) => doc.id !== data.docId,
        );

        return {
          ...oldData!,
          group_docs: updatedDocs,
        };
      });
    },
  });
};

export const useDeleteBmpDoc = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => Promise.resolve(), {
    onMutate: (data: { scope: ScopeType; docId: number }) => {
      return queryClient.setQueryData<Array<DocType>>(
        [data.scope, id],
        oldData => {
          const legendItemIndex = oldData!.findIndex((group: DocType) =>
            group.group_docs.find((doc: { id: number }) => {
              return doc.id === data.docId;
            }),
          );

          const updatedDocs = oldData![legendItemIndex].group_docs.filter(
            (doc: { id: number }) => doc.id !== data.docId,
          );

          const oldDataCopy = JSON.parse(JSON.stringify(oldData));

          oldDataCopy[legendItemIndex] = {
            ...oldDataCopy[legendItemIndex],
            group_docs: updatedDocs,
          };

          return oldDataCopy;
        },
      );
    },
  });
};

export const useUploadRegulationDoc = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => Promise.resolve(), {
    onMutate: (data: { scope: ScopeType; file: FileType }) => {
      return queryClient.setQueryData<DocType>([data.scope, id], oldData => {
        return {
          ...oldData!,
          group_docs: [...oldData!.group_docs, data.file],
        };
      });
    },
  });
};

export const useUploadBmpDoc = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => Promise.resolve(), {
    onMutate: (data: {
      scope: ScopeType;
      file: FileType;
      legendItemId: number;
    }) => {
      return queryClient.setQueryData<Array<DocType>>(
        [data.scope, id],
        oldData => {
          const oldDataCopy = JSON.parse(JSON.stringify(oldData));

          const legendItemIndex = oldDataCopy.findIndex(
            (legendItem: { id: number }) => {
              return legendItem.id === data.legendItemId;
            },
          );
          oldDataCopy[legendItemIndex].group_docs = [
            ...oldDataCopy[legendItemIndex].group_docs,
            data.file,
          ];

          return oldDataCopy;
        },
      );
    },
  });
};

export const useUpdateRegulationDoc = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => Promise.resolve(), {
    onMutate: (data: { scope: ScopeType; file: FileType }) => {
      return queryClient.setQueryData<Array<DocType>>(
        [data.scope, id],
        oldData => {
          const oldDataCopy = JSON.parse(JSON.stringify(oldData));

          const fileIndex = oldDataCopy.group_docs.findIndex(
            (doc: { id: number }) => doc.id === data.file.id,
          );

          oldDataCopy.group_docs[fileIndex] = data.file;

          return oldDataCopy;
        },
      );
    },
  });
};

export const useUpdateBmpDoc = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => Promise.resolve(), {
    onMutate: (data: { scope: ScopeType; file: FileType }) => {
      return queryClient.setQueryData<DocType>([data.scope, id], oldData => {
        const oldDataCopy = JSON.parse(JSON.stringify(oldData));

        const legendItemIndex = oldDataCopy.findIndex((group: DocType) =>
          group.group_docs.find((doc: { id: number }) => {
            return doc.id === data.file.id;
          }),
        );

        const fileIndex = oldDataCopy[legendItemIndex].group_docs.findIndex(
          (doc: { id: number }) => doc.id === data.file.id,
        );

        oldDataCopy[legendItemIndex].group_docs[fileIndex] = data.file;

        return oldDataCopy;
      });
    },
  });
};

export const useOnMoveRegulationDoc = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => Promise.resolve(), {
    onMutate: (data: {
      scope: ScopeType;
      hoverIndex: number;
      dragIndex: number;
      legendItemId?: number;
    }) => {
      return queryClient.setQueryData<Array<DocType>>(
        [data.scope, id],
        oldData => {
          const oldDataCopy = JSON.parse(JSON.stringify(oldData));
          const draggingDocument = oldDataCopy.group_docs[data.dragIndex];

          oldDataCopy.group_docs.splice(data.dragIndex, 1);
          oldDataCopy.group_docs.splice(data.hoverIndex, 0, draggingDocument);

          return oldDataCopy;
        },
      );
    },
  });
};

export const useOnMoveBmpDoc = (id: number) => {
  const queryClient = useQueryClient();

  return useMutation(() => Promise.resolve(), {
    onMutate: (data: {
      scope: ScopeType;
      hoverIndex: number;
      dragIndex: number;
      legendItemId?: number;
    }) => {
      return queryClient.setQueryData<DocType>([data.scope, id], oldData => {
        const oldDataCopy = JSON.parse(JSON.stringify(oldData));

        const legendItemIndex = oldDataCopy.findIndex(
          (legendItem: { id: number }) => {
            return legendItem.id === data.legendItemId;
          },
        );
        const draggingDocument =
          oldDataCopy[legendItemIndex].group_docs[data.dragIndex];

        oldDataCopy[legendItemIndex].group_docs.splice(data.dragIndex, 1);
        oldDataCopy[legendItemIndex].group_docs.splice(
          data.hoverIndex,
          0,
          draggingDocument,
        );

        return oldDataCopy;
      });
    },
  });
};

export const useOnDropRegulationDoc = () => {
  return useMutation(
    (data: {
      scope: ScopeType;
      hoverIndex: number;
      dragIndex: number;
      document: DocType;
      index: number;
    }): Promise<AxiosResponse<void>> => {
      const model =
        data.document.GroupUploadModel || data.document.group_uploads;

      return uploadApi.group.sort({
        id: model!.id,
        documentGroupId: model!.document_group_id,
        order: data.index,
        groupGUID: data.document.GUID,
      });
    },
  );
};

export const useOnDropBmpDoc = () => {
  return useMutation(
    (data: {
      scope: ScopeType;
      hoverIndex: number;
      dragIndex: number;
      document: DocType;
      index: number;
    }): Promise<void> => {
      return uploadApi.groupControl.sort({
        id: data.document.legend_item_upload_id,
        legendItemId: data.document.legend_item_id,
        documentGroupId: data.document.document_group_id,
        order: data.index,
      });
    },
  );
};
