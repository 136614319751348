import xhrService from "../xhrService";
const baseUrl = "/api/roles";

export const rolesApi = {
  index: () => {
    return xhrService.get(baseUrl).then(response => response.data);
  },
};

export default rolesApi;
