import axios from "axios";
import { has } from "lodash";
import { env } from "../config";

if (!env.REACT_APP_API_BASE_URL) {
  throw new Error("REACT_APP_API_BASE_URL is a required environment variable");
}

/**
 * Provide a ready-to-use instance of the XHR api
 */
const xhrService = axios.create({
  baseURL: env.REACT_APP_API_BASE_URL,
});

/** Auth middleware */
xhrService.interceptors.request.use(function setBearerToken(config) {
  const token = JSON.parse(localStorage.getItem("token"));
  const publictoken = JSON.parse(localStorage.getItem("publictoken"));
  const divisionId = JSON.parse(localStorage.getItem("divisionId"));
  const regex = /^\/public/
  const isPublic = regex.test(window.location.pathname)

  if (publictoken && isPublic) {
    config.headers.Authorization = `Bearer ${publictoken}`;
  } else if (token) {
    config.headers.Authorization = `Bearer ${token}`;
  } else {
    config.headers.Authorization = "";
  }

  /** @deprecated */
  if (has(config, "withoutDivision")) {
    console.warn(
      "xhrService configuration option `withoutDivision` is deprecated and has no effect.",
    );
  }

  config.headers.divisionId = divisionId ? `${divisionId}` : "";

  if (config.withoutDivision) {
    delete config.withoutDivision;
  }

  return config;
});

export { xhrService };
export default xhrService;
