import React, { MouseEventHandler, useContext } from "react";
import { Redirect } from "react-router-dom";
import ResourceIndex from "../Shared/ResourceIndex/ResourceIndex";
import RolesContext from "../../contexts/RolesContext";
import InspectionTemplateAdd from "./InspectionTemplateAdd";
import { Modal } from "@sw-sw/lib-ui";

const InspectionTemplates: React.FC = () => {
  const rolesContext = useContext(RolesContext);

  return (
    <React.Fragment>
      <ResourceIndex
        resourceName="inspectionTemplates"
        resourceTableHeaders={[["Name", "name"]]}
        resourceSearchProps={["name"]}
        enableRead={rolesContext.userHasPermission(
          "read",
          "Inspection Templates",
        )}
        enableClick={rolesContext.userHasPermission(
          "update",
          "Inspection Templates",
        )}
        enablePagination={false}
        enableCallToAction={rolesContext.userHasPermission(
          "create",
          "Inspection Templates",
        )}
        callToActionContent="Add Inspection Template"
        renderReader={(inspectionTemplate, handleClose) => {
          if (!inspectionTemplate.isUserTemplate) {
            return (
              <Modal
                show={true}
                handleSubmit={handleClose}
                handleClose={handleClose}
                title="Error"
                subTitle="System Templates can not be edited at this time."
              />
            );
          } else {
            return (
              <Redirect
                push
                to={`/inspection-templates/${inspectionTemplate.id}`}
              />
            );
          }
        }}
        renderCallToAction={(handleClose: MouseEventHandler) => (
          <InspectionTemplateAdd handleClose={handleClose} />
        )}
        hideCallToActionInMobile={false}
      />
    </React.Fragment>
  );
};

export default InspectionTemplates;
