import xhrService from "../xhrService";

/**
 * Finish types
 *
 */
export type ComplianceReport = {
    paginationState: Object;
    result: [{
        projectName: string;
        inspectionCount: number;
        findingsCount: number;
        avgFindings: number;
        maxDay: number;
        openFindings: number;
        avgFindingDays: number;
        notCompleteInExpectedDays: number;
        complianceCount: number;
        ackCount: number;
    }]
};

export type FindingsDetails = {
    findings: string[]
}

export type IndexApi = {
    divisionId: number,
    pageNumber?: number,
    searchQuery?: string,
    rowsPerPage?: number,
    clientIds?: number[],
    maxDate?: Date,
    minDate?: Date,
    findings?: string
}

export type FindingApi<D = string> = (
    divisionId: number,
) => Promise<D[]>;

/**
 * Go through types
 */
type ReportApi = {
    index: (param: IndexApi) => Promise<ComplianceReport>;
    getFindings: (divisionId: number) => Promise<FindingsDetails>;
};

export const reportApi: ReportApi = {

    index: (param) => {
        const params: { s?: string; p?: number, r?: number, client?: number[], maxDate?: Date, minDate?: Date, findings?: string } = {};

        if (param.searchQuery) {
            params.s = param.searchQuery;
        }

        if (param.pageNumber) {
            params.p = param.pageNumber;
        }

        if (param.rowsPerPage) {
            params.r = param.rowsPerPage;
        }

        if (param.clientIds) {
            params.client = param.clientIds;
        }
        if (param.maxDate) {
            params.maxDate = param.maxDate;
        }
        if (param.minDate) {
            params.minDate = param.minDate;
        }
        if (param.findings) {
            params.findings = param.findings;
        }

        return xhrService
            .get(`/api/divisions/${param.divisionId}/compliance`, { params })
            .then(response => response.data);
    },

    getFindings: (divisionId) => {
        return xhrService
            .get(`/api/divisions/${divisionId}/findingTypes`)
            .then(response => response.data)
    }
};

export default reportApi;
