import xhrService from "./xhrService";

import { controlMeasureApi, pollutantApi } from "./api/legendItem";
import findingsApi from "./api/finding";
import commentablesApi from "./api/commentable";

/** @deprecated use directly from `./xhrService` module */
export default xhrService;

/**
 * the available APIs for each positionableType
 *
 * @important This object enables getting an api, dynamically
 */
export const positionableDataApis = {
  findingsApi,
  commentablesApi,
  controlMeasureApi,
  pollutantApi,
};
