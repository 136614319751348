import React, {
  PropsWithChildren,
  useContext,
  useEffect,
  useState,
} from "react";
import { UseQueryResult } from "react-query";
import { useLocation } from "react-router-dom";
import { useDivisionQuery } from "../hooks/divisionQuery";
import AppContext from "./AppContext";
import { Division } from "./DivisionContext";

export interface IAppDivisionContextProps {
  appDivisionId: number | null;
  saveAppDivisionId: (id: number | null) => void;
  validDivision: (divisionId: number) => Boolean;
  getPath: (route: string, div?: number) => string;
  getPublicPath: (route: string, div?: number) => string;
  divisions: Division[];
  divisionsQuery: UseQueryResult<Division[]>;

  refetchDivisions: () => Promise<UseQueryResult>;
  loading: boolean;
}

const Context = React.createContext<IAppDivisionContextProps>(undefined!);

export const AppDivisionProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const location = useLocation();
  const divisionsQuery = useDivisionQuery();
  const appStore = useContext(AppContext);
  const divisionRegex = /\/divisions\/(\d+)\/([a-z]*)/;
  const divisionPath = location.pathname.match(divisionRegex);
  const publicRegex = /\/public\/(\d+)\/([a-z]*)/;
  const publicPath = location.pathname.match(publicRegex);
  const loading = divisionsQuery.isLoading || !divisionsQuery.data;

  const [appDivisionId, setAppDivisionId] = useState<number | null>(
    Number(localStorage.getItem("divisionId")),
  );

  const saveAppDivisionId = (id: any) => {
    if (id === parseInt(localStorage.getItem("divisionId") || "0")) {
      return;
    }

    if (id) {
      localStorage.setItem("divisionId", id);
    } else {
      localStorage.removeItem("divisionId");
    }

    setAppDivisionId(id);
  };

  const validDivision = (divisionId: number) => {
    const divisions = divisionsQuery.data
      ? divisionsQuery.data.map((_: { id: number }) => _.id)
      : [];

    if (divisions.includes(divisionId)) {
      saveAppDivisionId(divisionId);

      return true;
    } else {
      return false;
    }
  };

  const getPath = (route: string, divisionId = appDivisionId) => {
    const { nonDivisionRoutes } = appStore.get("constants");
    const regex = /\/[a-z]*/;
    const rootPath = route.match(regex);

    const path =
      rootPath && nonDivisionRoutes && nonDivisionRoutes.includes(rootPath[0])
        ? ""
        : `/divisions/${divisionId ? divisionId : 1}`;

    return path + route;
  };

  const getPublicPath = (route: string, divisionId = appDivisionId) => {
    const { nonDivisionRoutes } = appStore.get("constants");
    const regex = /\/[a-z]*/;
    const rootPath = route.match(regex);

    const path =
      rootPath && nonDivisionRoutes && nonDivisionRoutes.includes(rootPath[0])
        ? "/public"
        : `/public/divisions/${divisionId ? divisionId : 1}`;

    return path + route;
  };

  useEffect(() => {
    if (divisionsQuery.data && divisionsQuery.data.length) {
      // ensure divisionId is set
      if (!appDivisionId) {
        saveAppDivisionId(divisionsQuery.data[0].id);
        return;
      }

      // guard selected division id
      if (
        appDivisionId &&
        !divisionsQuery.data.find(
          (div: { id: number }) => div.id === appDivisionId,
        )
      ) {
        saveAppDivisionId(divisionsQuery.data[0].id);
      }
    } else {
      saveAppDivisionId(null);
    }
  }, [divisionsQuery.data]);

  useEffect(() => {
    if (window) {
      const pathnameArr = window.location.pathname.split('/')
      // this condition is to check if this context is only called on the pathname with division slug
      if (pathnameArr[1] === "divisions") {
        // this is to set the division id on every reload   
        setAppDivisionId(Number(pathnameArr[2]))
      }
      if (pathnameArr[1] === "public") {
        // this is to set the division id on every reload   
        setAppDivisionId(Number(pathnameArr[3]))
      }
    }
  }, [])

  return (
    <Context.Provider
      value={{
        appDivisionId,
        saveAppDivisionId,
        validDivision,
        getPath,
        getPublicPath,
        loading,
        divisionsQuery,
        divisions: divisionsQuery.data ? divisionsQuery.data : [],
        refetchDivisions: divisionsQuery.refetch,
      }}
    >
      {(!divisionPath || !loading || !publicPath) && children}
    </Context.Provider>
  );
};

export default Context;
export const AppDivisionContext = Context;
