import React, { useContext } from "react";
import { Link } from "react-router-dom";
import GroupDocuments from "../../Groups/GroupDocuments";
import Loading from "../../Shared/ResourceIndex/Loading";
import { TooltipContextProvider } from "@sw-sw/lib-ui";
import ProjectPermissionContext from "../../../contexts/ProjectPermissionContext";
import { useProjectBmpDocuments } from "../../../hooks/projectDetails";
import AppContext from "../../../contexts/AppContext";

export interface IBmpDetailsProps {
  groupId: number;
  projectId: number;
}
const BmpDetails: React.FC<IBmpDetailsProps> = ({ groupId, projectId }) => {
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const bmpDetailsQuery = useProjectBmpDocuments(projectId, groupId);
  const auth = useContext(AppContext).get("user")

  if (!bmpDetailsQuery.data) {
    return <Loading what="BMP Details" />;
  }

  if (bmpDetailsQuery.data.length) {
    return (
      <TooltipContextProvider>
        <GroupDocuments
          scope="bmp"
          documentGroups={bmpDetailsQuery.data}
          document_group_id={groupId}
          onDocumentUpload={bmpDetailsQuery.refetch}
          onDocumentDelete={bmpDetailsQuery.refetch}
          canRename={false}
          readOnly={projectPermissionContext.readOnly}
        />
      </TooltipContextProvider>
    );
  } else {
    if (auth.role.name === "Public") {
      return <p>No control measures have been added to the active site map.</p>
    }

    return (
      <p>
        No control measures have been added to the active site map.{" "}
        <Link to={`/projects/${projectId}/active-site-map`}>Click here</Link> to
        manage site map control measures.
      </p>
    );
  }
};

export default BmpDetails;
