import printJs from "print-js";
import { getDownloadUrl } from "./api/upload";
import { isFirefoxClient } from "./detectClient";

/**
 * Print a document
 *
 * @todo better documentation for param `doc.printable`
 *
 * @param {Object} doc
 * @param {string} doc.mime_type
 * @param {string} doc.GUID
 * @param {string} doc.printable
 * @param {boolean} doc.base64
 */
const print = doc => {
  const options = Object.assign(
    {
      printable: doc.printable ? doc.printable : getDownloadUrl(doc.GUID),
      type: doc.mime_type === "application/pdf" ? "pdf" : "image",
      showModal: false,
      onError: err => console.log(err),
    },
    doc.base64 ? { base64: doc.base64 } : {},
  );

  printJs(options);
};

/**
 * Print a PDF, encoded as a string
 */
const printPDF = async (url, opts = {}) => {
  const isFirefox = isFirefoxClient();

  // xhrService.get(url).then(console.log);

  let printable = url;

  if (isFirefox) {
    // open in new tab...

    window.open(url, "_newtab");
  } else {
    printJs({
      type: "pdf",
      printable,
      ...opts,
    });
  }
};

const printUtil = {
  print,
  printPDF,
};

export default printUtil;
export { print, printPDF, printUtil };
