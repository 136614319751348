import c from "classnames";
import { find } from "lodash";
import React, { useContext, useState } from "react";
import { Redirect } from "react-router-dom";
import AppContext from "../../../contexts/AppContext";
import { DivisionContext } from "../../../contexts/DivisionContext";
import AppDivisionContext from "../../../contexts/AppDivisionContext";
import useDivisionResource from "../../../hooks/divisionResource";
import List from "../../Shared/List/List";
import Pagination from "../../Shared/Pagination/Pagination";
import ResourceSearch from "../../Shared/Resource/Search";
import Loading from "../../Shared/ResourceIndex/Loading";
import DivisionResourceAdd from "./DivisionResourceAdd";
import DivisionResourceDelete from "./DivisionResourceDelete";

const DivisionResourceIndex: React.FC = () => {
  const divisionContext = useContext(DivisionContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const appContext = useContext(AppContext);
  // const [loading, setLoading] = useState(true);
  const { tabs, selectedTab, selectedDivision } = divisionContext;
  const { getPath } = appDivisionContext;

  if (!selectedDivision) {
    throw new Error("selectedDivision is required");
  }

  const { detailRoute, disablePagination, callToActionContent, tableHeaders } =
    divisionContext.tabs[selectedTab];
  const [showCallToAction, setShowCallToAction] = useState(false);
  const [redirect, setRedirect] = useState<string | null>(null);
  const { pagination, search, selection, client, data, setData, loading } =
    useDivisionResource({
      resource: selectedTab,
      disablePagination: disablePagination,
      divisionId: selectedDivision.id,
    });
  const { page, setPage, paginationState } = pagination;
  const { selected, setSelected, all: selectAll } = selection;

  const handleDelete = async () => {
    if (divisionContext.selectedDivision) {
      return tabs[selectedTab].divisionApi
        .delete(divisionContext.selectedDivision.id, selected)
        .then(() => {
          client.invalidateQueries();

          if (
            selectedTab === "users" &&
            selected.includes(appContext.get("user.id"))
          ) {
            const divisions = appContext.get("user.divisions");
            const filteredDivisions = divisions.filter(
              (div: { id: number }) => {
                if (divisionContext.selectedDivision) {
                  return div.id !== divisionContext.selectedDivision.id;
                }

                return true;
              },
            );
            appDivisionContext.refetchDivisions();

            return filteredDivisions;
          }

          return null;
        })
        .then((divs: Array<any>) => {
          if (divs) {
            appContext.set("user.divisions.length", 0);
            appContext.set("user.divisions", divs);
          }
        })
        .then(() => {
          setSelected([]);
          setShowCallToAction(false);
        });
    } else {
      console.error("No Division loaded in context");
    }
  };

  const onSelect = (e: { target: { value: string } }) => {
    if (selected.includes(parseInt(e.target.value))) {
      const arr = [...selected];
      const index = arr.indexOf(parseInt(e.target.value));

      if (index >= 0) {
        arr.splice(index, 1);
        setSelected(arr);
      }
    } else {
      setSelected([...selected, parseInt(e.target.value)]);
    }
  };

  const onSelectAll = () => {
    if (!selectAll) {
      setSelected(data.map((row: any) => row.id));
    } else {
      setSelected([]);
    }
  };

  const allowDetail = () => {
    // add logic to allow detail if user is a member of the division being edited.
    const divisions = appContext.get("user.divisions");

    if (!divisionContext.tabs[selectedTab].detailRoute) {
      return false;
    }

    return divisionContext.selectedDivision
      ? divisions
          .map((_: { id: number }) => _.id)
          .includes(divisionContext.selectedDivision.id)
        ? true
        : false
      : false;
  };

  const handleDetail = (resourceObj: any) => {
    if (detailRoute) {
      setRedirect(getPath(detailRoute(resourceObj)));
    }
  };

  if (redirect && divisionContext.selectedDivision) {
    appDivisionContext.saveAppDivisionId(divisionContext.selectedDivision.id);

    return <Redirect to={redirect} />;
  }

  const searchProps = {
    query: search.query,
    setQuery(input: string) {
      if (page) {
        setPage(1);
      }

      search.setQuery(input);
    },
  };

  return (
    <section>
      <article className="dri">
        <header className="dri-header">
          <div className={c("dri-filters", "dri-select")}>
            <ResourceSearch
              local={disablePagination}
              localSearch={
                disablePagination
                  ? {
                      data,
                      setData,
                      attributes: ["id", "name"],
                    }
                  : undefined
              }
              {...searchProps}
            />

            {selected.length ? (
              !paginationState && selectAll ? (
                <p className="dri-select-text">{`Selected all ${data.length} items`}</p>
              ) : (
                <p className="dri-select-text">
                  {`Selected ${selected.length} of ${
                    paginationState ? paginationState.numModels : data.length
                  } items`}
                </p>
              )
            ) : null}
          </div>
          <div className="dri-actions">
            {selected.length > 0 ? (
              <button
                className="warn delete-resource"
                onClick={() => setShowCallToAction(true)}
              >
                {`Delete ${callToActionContent}`}
              </button>
            ) : (
              <button
                className="primary"
                onClick={() => setShowCallToAction(true)}
              >
                {`Add ${callToActionContent}`}
              </button>
            )}
          </div>
        </header>
        <section className="dri-list">
          {loading ? (
            <Loading />
          ) : (
            <List
              headers={tableHeaders.map(header => ({
                label: header[0],
              }))}
              rows={data.map(row => ({
                id: row.id,
                cells: tableHeaders.map(
                  ([_, propName]) => (row as any)[propName],
                ),
                handleClick: undefined,
                handleDetail: () => handleDetail(row),
              }))}
              pageItems={selectedTab}
              enableSelection={true}
              selected={selected}
              selectAll={selectAll}
              onSelect={onSelect}
              onSelectAll={() => onSelectAll()}
              loading={loading}
              showDetail={allowDetail()}
            />
          )}
          <Pagination
            page={page}
            setPage={setPage}
            paginationState={paginationState}
          />
        </section>

        {showCallToAction && selected.length <= 0 && (
          <DivisionResourceAdd handleClose={() => setShowCallToAction(false)} />
        )}
        {showCallToAction && selected.length > 0 && (
          <DivisionResourceDelete
            handleClose={() => setShowCallToAction(false)}
            handleSubmit={handleDelete}
            selected={selected
              .map(id => find(data, { id }))
              .filter(model => model !== undefined)
              .map(object => object!.name)}
          />
        )}
      </article>
    </section>
  );
};

export default DivisionResourceIndex;
