import instance from "../xhrService";

export type CAFindingModel = {
  id: number;
  name: string;
  date_initiated: string;
  initial_inspection_id: number;
  inspections: any[];
  location: string;
};

export const correctiveActionsFindingsApi = {
  index(clientId: number): Promise<CAFindingModel[]> {
    return instance
      .get(`/api/projects/${clientId}/findings`)
      .then(res => res.data);
  },
};
