import React, {
  useEffect,
  useState,
} from 'react';
import { InspectionStore } from '../../contexts/InspectionContext';
import ProjectContext from '../../contexts/ProjectContext';
import projectApi from '../../utils/api/project';
import { useRouteMatch } from "react-router-dom";

/**
 *
 */

const FullScreenLayout = ({ children }) => {
  const match = useRouteMatch()
  const [projectDocs, setProjectDocs] = useState();

  useEffect(async () => {
    const data = await projectApi.getDocs(match.params.projectId);

    setProjectDocs(data);
  }, []);

  return (
    <ProjectContext.Provider
      value={{
        projectDocs,
        setProjectDocs,
      }}
    >
      <InspectionStore>
        <div className='layout layout--fullscreen'>{children}</div>
      </InspectionStore>
    </ProjectContext.Provider>
  );
};

export default FullScreenLayout;