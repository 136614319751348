import React, { useState, useEffect, useContext, useCallback } from "react"
import { get, pick, isEqual } from "lodash";
import userApi from "../../../utils/api/user"
import divisionApi from "../../../utils/api/division";
import clientApi from "../../../utils/api/client";
import { TooltipContextProvider } from "@sw-sw/lib-ui";
import Form1 from "../Forms/UserStepOne";
import { useUserFormData } from "../../../hooks/userFormData";
import AppContext from "../../../contexts/AppContext";
import { AppDivisionContext } from "../../../contexts/AppDivisionContext";
import { FormContext, FormContextProvider } from "@sw-sw/lib-form";
import xhrService from "../../../utils/api";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleUp, faAngleDown, faTrash, } from "@fortawesome/free-solid-svg-icons";
import ConfirmationModal from "../../Shared/ConfirmationModal/ConfirmationModal";
import { Redirect } from "react-router";
import Select from 'react-select'
import makeAnimated from 'react-select/animated';
import { flatten, cloneDeep } from "lodash";
import Switch from '@mui/material/Switch';
import Pagination from "react-js-pagination";
import Permissions from "./Permissions";
import { LoadingIcon } from "@sw-sw/lib-ui";

export const EditUserPage = ({ formOpts, showEditRole, isEdit }) => {
  const itemCount = 7
  const [startItem, setStartItem] = useState(0)
  const [lastItem, setLastItem] = useState(itemCount)
  const [activePage, setActivePage] = useState(1)
  const [userData, setUserData] = useState(null)
  const [redirect, setRedirect] = useState(false)
  const [userId, setUserId] = useState(null)
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [showAlert, setShowAlert] = useState(false)
  const [showDeleteClientModal, setShowDeleteClientModal] = useState(false)
  const [initialValue, setInitialValue] = useState({
    tags: [],
    roleId: "",
    projects: [],
  });

  const [tableData, setTableData] = useState({})
  const [searchTableData, setSearchTableData] = useState([])
  const appStore = useContext(AppContext);
  const appDivisionContext = useContext(AppDivisionContext);
  const { getPath } = useContext(AppDivisionContext);
  const formDataQuery = useUserFormData(appDivisionContext.appDivisionId);
  const [divisionId, setDivisionId] = useState([])

  const [selectedOptions, setSelectedOptions] = useState([]);
  const [options, setOptions] = useState("")
  const [renderSearchData, setRenderSearchData] = useState(false)
  const [searchInput, setSearchInput] = useState("");
  const [clientId, setClientId] = useState(null)
  const [loader, setLoader] = useState(false)
  const animatedComponents = makeAnimated();
  const [showPermissions, setShowPermissions] = useState(false)
  const [roleName, setRoleName] = useState()
  const [refetch, setRefetch] = useState(false)


  useEffect(async () => {
    if (divisionId) {
      const clientOption = await Promise.all(
        divisionId.map((ele) => {
          return divisionApi.clients.index(ele.id).then(async (res) => {
            const allOption = getOptions(res)
            const existingOption = getOptions(initialValue.clients)
            const newOption = allOption.filter(val => {
              return (!existingOption.includes(val))
            });

            return newOption
          })
        }))

      setOptions(flatten(clientOption))
    }
  }, [divisionId])

  useEffect(() => {
    if (!formDataQuery.loading) {
      setFormData({
        roles: formDataQuery.roles.data,
        divisions: formDataQuery.divisions.data,
      })
    }
  }, [formDataQuery.loading])

  function ExpandedView(ele, clientName) {
    const label = { inputProps: { 'aria-label': 'Switch demo' } };

    const handleToggle = () => {
      const userUpdate = ele
      const newClientObj = Object.assign(initialValue)

      if (ele.toggle) {
        userUpdate.toggle = !ele.toggle
        let index = null

        newClientObj.projects.forEach((val, i) => {
          if (val.id === ele.id) {
            index = i
          }
        })
        newClientObj.projects.splice(index, 1)
      } else {
        newClientObj.projects.push(ele)
      }
      setInitialValue(newClientObj)
    }

    return (
      <tr style={{ fontSize: "12px" }} className="client-detail-info-values">
        <th colSpan={3}>{ele.name}</th>
        <th colSpan={3}>{ele.division}</th>
        <th colSpan={3} className="caret-cell">
          <div>
            <Switch onChange={handleToggle} {...label} defaultChecked={ele.toggle} />
          </div>
        </th>
        <th colSpan={3} className="caret-cell" >
          <Switch {...label} disabled defaultChecked={ele.toggle} />
        </th>
      </tr>
    )
  }

  function UserTable({
    user,
    index,
    handleRowClick,
    expanded,
    ...dashTableProps
  }) {

    const deleteClient = () => {
      setClientId(user.id)
      setShowDeleteClientModal(true)
    }

    const getDeleteAndExpand = () => {
      return (
        <>
          <FontAwesomeIcon
            style={{ marginRight: "10px" }}
            icon={faTrash}
            className="caret"
            onClick={() => deleteClient(index)}
          />
          <FontAwesomeIcon
            icon={expanded.includes(index) ? faAngleUp : faAngleDown}
            className="caret"
            onClick={() => handleRowClick(index)}
          />
        </>
      )
    }

    const tdStyle = {
      height: "50px",
      display: "flex",
      justifyContent: "center",
      textAlign: "center"
    }

    return (
      <>
        <tr className="pointer client-basic-info" >
          <td colSpan={6} onClick={() => handleRowClick(index)}>{user.name}</td>
          <td className="caret-cell" >
            <td style={tdStyle}>
              {getDeleteAndExpand()}
            </td>
          </td>
        </tr>

        {expanded.includes(index) && (
          user.projects.length ?
            <tr key={`expansion-${index}`}>
              <table colSpan={2} className="list-view pure-table pure-table-horizontal area-manager-table client-detail-info">
                <thead className="list-header">
                  <th colSpan={3}>Project Name</th>
                  <th colSpan={3}>Divisions</th>
                  <th colSpan={3}>Access to project</th>
                  <th colSpan={3}>Access to statistics</th>
                </thead>
                {user.projects.map((ele) => ele && ExpandedView(ele, user.name))}
                {user.archivedProjects.length ?
                  <>
                    <th>Archived Projects</th>
                    {user.archivedProjects.map((ele) => ExpandedView(ele, user.name))}
                  </> : null
                }
              </table>
            </tr>
            :
            <tr key={`expansion-${index}`}>
              <table colSpan={2} className="list-view pure-table pure-table-horizontal area-manager-table">
                <thead className="list-header">
                  <th>No projects to be displayed</th>
                </thead>
              </table>
            </tr>
        )}
      </>
    );
  }

  const Table = ({ data, tableKeys, getMenuOptions, getLink, props }) => {
    if (!data.length) {
      return (
        <section className="table-holder add-clients-table">
          <table className="list-view pure-table pure-table-horizontal area-manager-table">
            <thead className="list-header">
              <tr>
                <th colSpan={6}>Client Name</th>
                <th colSpan={2}>Action</th>
              </tr>
            </thead>
            <tbody>
              <th>No Clients to be displayed</th>
            </tbody>
          </table>
        </section>
      )
    }
    const newData = []
    const [expanded, setExpanded] = useState([]);

    data.length && data.forEach((ele) => {
      const archived = []
      const projects = ele.projects.map((project) => {
        if (project.archived) {
          archived.push(project)

          return null
        } else {
          return project
        }
      })

      newData.push({
        id: ele.id,
        name: ele.name,
        projects: projects.filter(project => project),
        archivedProjects: archived
      })
    })

    const handleRowClick = useCallback(
      i => {
        if (expanded.includes(i)) {
          const filterState = expanded.filter(item => item !== i);

          setExpanded(filterState);
        } else {
          setExpanded([...expanded, i]);
        }
      },
      [expanded],
    );

    const onPageChange = (event) => {
      setLastItem(event * itemCount)
      setStartItem((event * itemCount) - itemCount)
      setActivePage(event)
    }

    return (
      <section className="table-holder add-clients-table">
        <table className="list-view pure-table pure-table-horizontal area-manager-table">
          <thead className="list-header add-client-table-head">
            <tr>
              <th colSpan={6}>Client Name</th>
              <th colSpan={2}>Action</th>
            </tr>
          </thead>
          <tbody>
            {newData.length && newData.map((user, i) => {
              if (i >= startItem && i < lastItem) {
                return (
                  <UserTable
                    tableKeys={tableKeys}
                    expanded={expanded}
                    handleRowClick={handleRowClick}
                    getMenuOptions={args => getMenuOptions({ ...args, getLink })}
                    user={user}
                    index={i}
                    key={i}
                    {...props}
                  />)
              }

              return null
            })}
          </tbody>
        </table>
        <Pagination
          activePage={activePage}
          itemsCountPerPage={itemCount}
          totalItemsCount={data.length}
          pageRangeDisplayed={3}
          onChange={onPageChange}
        />
      </section>
    )
  }

  const extractInitialValue = user => {
    const keys = [
      "id",
      "first_name",
      "last_name",
      "email",
      "address.phone",
      "address.phone_ext",
      "position",
      "roleId",
      "roleName",
      "ccrNumber",
      "peNumber",
    ];

    const mapAsOpts = list => list.map(x => pick(x, ["id", "name", "client_id", "divisionProject.division_id"]));

    return {
      ...pick(user, keys),

      /**
       * @important this is needed to initialize current selections
       */
      clients: mapAsOpts(get(user, "clients", [])),
      projects: mapAsOpts(get(user, "projects", [])),
      regulations: mapAsOpts(get(user, "document_groups", [])),
      templates: mapAsOpts(get(user, "templates", [])),
      divisionIds: mapAsOpts(get(user, "divisions", [])),
      managerUserId:
        user.managers && Array.isArray(user.managers) && user.managers.length
          ? user.managers[0].id
          : null,
    };
  };

  const divisionName = (id) => {
    let division = null

    appDivisionContext.divisions.forEach((ele) => {
      if (ele.id === id) {
        division = ele.name
      }
    })

    return division
  }

  const getProjectData = (res) => {
    const newObj = []
    const divisionIds = initialValue.divisionIds.map((ele) => ele.id)

    res.forEach((projects) => {
      if (projects.divisionProject && divisionIds.includes(projects.divisionProject.division_id)) {
        newObj.push({
          id: projects.id,
          name: projects.name,
          division: divisionName(projects.divisionProject ? projects.divisionProject.division_id : null),
          toggle: false
        })
      }
    })

    return newObj
  }

  const handleClientAdd = async () => {
    const clientIds = selectedOptions.map((ele) => (ele.value))
    const getName = (id) => {
      let label = ""

      selectedOptions.forEach((ele) => {
        if (ele.value === id) {
          label = ele.label
        }
      })

      return label
    }

    const addedClient = await Promise.all(
      clientIds.map(async (id) => {
        const data = await clientApi.projects.index(id).then(async (res) => {
          return {
            id: id,
            name: getName(id),
            projects: getProjectData(res)
          }
        })

        return data
      })
    )
    const newOption = options.filter(val => !selectedOptions.includes(val));
    const newClient = Object.assign(initialValue)

    addedClient.forEach((val) => {
      newClient.clients.push(val)
    })

    const newArr = [...tableData, ...addedClient]

    newArr.sort((a, b) => a.name.localeCompare(b.name))

    setTableData(newArr)
    setOptions(newOption)
    setInitialValue(newClient)
    setSelectedOptions(null)
  }

  const handleChangeSearch = (e) => {
    e.preventDefault();
    setSearchInput(e.target.value);
    setStartItem(0)
    setLastItem(itemCount)
    setActivePage(1)

    const newTableData = []

    const searchData = cloneDeep(tableData)

    if (e.target.value.length > 1) {
      const sortClinet = searchData.map(table => {
        if (table.name.toLowerCase().match(e.target.value.toLowerCase())) {
          newTableData.push(table)

          return null
        }

        return table
      })

      if (e.target.value.length > 2) {
        const projectSort = sortClinet.map((project) => {
          if (project) {
            const sortedProject = project.projects.filter((tableProject) => {
              return tableProject.name.toLowerCase().match(e.target.value.toLowerCase())
            })

            project.projects = sortedProject

            return project
          }

          return null
        })

        projectSort.forEach((project) => {
          if (project && project.projects.length) {
            newTableData.push(project)
          }
        })
      }
      setSearchTableData(newTableData)
    } else {
      setSearchTableData(tableData)
    }
  }

  useEffect(() => {
    if (searchTableData.length > 0 && !renderSearchData) {
      setRenderSearchData(true)
    }
  }, [searchTableData])

  const getClientData = () => {
    return initialValue.clients.map((ele) => ({
      id: ele.id,
      name: ele.name
    }))
  }

  const CTA = () => {
    const formContext = useContext(FormContext);

    if (formContext.value.divisionIds && formContext.value.divisionIds.length !== divisionId.length) {
      setDivisionId(formContext.value.divisionIds)
    }

    const handleBack = () => {
      const obj = cloneDeep(formContext.value)

      delete obj.tags
      if (isEqual(formContext.initialValue, obj)) {
        setRedirect(true)
      } else {
        setShowAlert(true)
      }
    }

    const handleCreateSubmit = () => {
      const roleIdArr = []

      formData.roles.forEach((ele) => {
        if (["Area / Assistant Manager", "Inspector"].includes(ele.name)) {
          roleIdArr.push(ele.id)
        }
      })

      setLoader(true)
      appStore.triggerSuccessNotification();
      formContext.set("clients", getClientData());
      formContext.set("projects", initialValue.projects)

      if (!roleIdArr.includes(formContext.value.roleId)) {
        formContext.value.managerUserId = null
      }

      userApi.update(userId, {
        ...formContext.value,
        clients: formContext.initialValue.clients,
        divisionIds: formContext.value.divisionIds
          ? formContext.value.divisionIds.map(_ => _.id)
          : [],
      }).then((res) => {
        setLoader(false)
        setTimeout(() => {
          window.location.reload()
        }, 2000);
      }).catch((err) => {
        console.log("err", err);
        setLoader(false)
      })
    }

    return (
      <div className="cta-outer-wrapper">
        <div className="cta-inner-wrapper">
          <button
            className="primary-outline"
            onClick={handleBack}
          >
            Back
          </button>
          <button
            className="primary"
            onClick={() => handleCreateSubmit()}
          >
            Save
          </button>
          {loader && <LoadingIcon />}
          <button
            className="secondary"
            onClick={() => setShowDeleteModal(true)}
          >
            Delete User
          </button>
        </div>
      </div>
    )
  }

  const [formData, setFormData] = useState({
    roles: formDataQuery.roles.data,
    divisions: formDataQuery.divisions.data,
  });

  const getOptions = (data) => {
    const optionData = []

    data.forEach((ele) => {
      optionData.push({
        label: ele.name,
        value: ele.id
      })
    })

    return optionData
  }

  const createTableData = (data) => {
    const clientArr = data.clients
    const projectArr = data.projects.map((ele) => (ele.id))
    const divisionIds = data.divisionIds.map((ele) => ele.id)

    const newArr = []

    clientArr.map(async (ele) => {
      const newObj = {
        id: ele.id,
        name: ele.name,
        projects: []
      }


      xhrService.get(`/api/clients/${ele.id}/projects`).then((res) => {
        res.data.forEach((projects) => {
          if (projects.divisionProject && divisionIds.includes(projects.divisionProject.division_id)) {
            newObj.projects.push({
              id: projects.id,
              name: projects.name,
              division: divisionName(projects.divisionProject ? projects.divisionProject.division_id : null),
              toggle: projectArr.includes(projects.id),
              archived: projects.archivedAt ? true : false
            })
          }
        })
        newObj.projects.sort((a, b) => a.name.localeCompare(b.name))
      })

      newArr.push(newObj)
    })
    newArr.sort((a, b) => a.name.localeCompare(b.name))

    return newArr
  }

  useEffect(async () => {
    const getUser = await userApi.profile()

    setRoleName(getUser.roleName)
  }, [])

  useEffect(() => {
    const userIdArr = window.location.pathname.split('/')

    setUserId(userIdArr[4])
    userApi.get(userIdArr[4]).then(async (res) => {
      setUserData(res)
      setInitialValue(extractInitialValue(res));
      setTableData(createTableData(extractInitialValue(res)))
      setDivisionId(extractInitialValue(res).divisionIds)
      setRefetch(false)
    })
  }, [refetch])

  return (
    <TooltipContextProvider>
      <FormContextProvider initialValue={initialValue}>
        {
          !formDataQuery.loading && formData.divisions.length &&
          <section>
            <div style={{ height: '60px', width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
              <h3>Edit User</h3>
              {
                ["Admin", "Client Manager"].includes(roleName) &&
                <button
                  className="primary"
                  onClick={() => setShowPermissions(true)}
                  style={{ height: '40px', position: 'relative' }}
                >
                  Permissions
                </button>
              }
              {showPermissions &&
                <Permissions
                  userId={userId}
                  hideModal={() => setShowPermissions(false)}
                  notifications={userData.notifications}
                  refetch={() => setRefetch(true)}
                />
              }
            </div>
            <Form1
              className={"edit-user-form-section"}
              formOpts={formData}
              showEditRole={true}
              isEdit={true}
              isProfile={false}
              isMyProfile={false}
              adminTab
            />
            <ConfirmationModal
              title={`There are some unsaved changes would you like to proceed?`}
              show={showAlert}
              handleClose={() => setShowAlert(false)}
              handleConfirm={() => {
                setRedirect(true)
              }}
              buttonText="Continue"
            />
            {redirect && <Redirect to={getPath('/admin')} push />}
            <div className="hr">
              <hr />
            </div>
            <div className="searchContainer">
              <input
                type="text"
                placeholder="Search here"
                onChange={handleChangeSearch}
                value={searchInput}
              />
              <section className="addClientMainContainer">
                <div className="addClientContainer">
                  <p className="addClientText">Add Clients</p>
                  <div className="selectClient">
                    <Select
                      closeMenuOnSelect={false}
                      isMulti
                      components={animatedComponents}
                      options={options}
                      onChange={(data) => { setSelectedOptions(data) }}
                      isClearable={true}
                      value={selectedOptions}
                      id={'selectInput'}
                    />
                    <button
                      className="primary"
                      onClick={() => handleClientAdd()}
                    >
                      Save Client
                    </button>
                  </div>
                </div>
              </section>
            </div>
            {renderSearchData && tableData ? <Table data={searchTableData} /> : <Table data={tableData} />}
            <CTA />
            <ConfirmationModal
              title={`Are you sure you want to delete ?`}
              show={showDeleteModal}
              handleClose={() => setShowDeleteModal(false)}
              handleConfirm={() => {
                return xhrService.delete(`/api/users/delete/${userId}`).then(() => {
                  appStore.triggerSuccessNotification();
                  setShowDeleteModal(false)
                  setRedirect(true)
                });
              }}
              buttonText="Delete user"
            />
            <ConfirmationModal
              title={`Are you sure you want to delete this client?`}
              show={showDeleteClientModal}
              handleClose={() => setShowDeleteClientModal(false)}
              handleConfirm={(event) => {
                let arrIndex = null
                const arrClientIndex = []
                const projectId = []

                tableData.forEach((ele, i) => {
                  if (ele.id === clientId) {
                    arrIndex = i
                    if (ele.projects.length) {
                      ele.projects.map((val) => (
                        projectId.push(val.id)
                      ))
                    }
                  }
                })
                const newInitalValue = Object.assign(initialValue)
                const newTableData = Object.assign(tableData)

                newInitalValue.clients.forEach((ele, i) => {
                  if (ele.id === clientId) {
                    arrClientIndex.push(i)
                  }
                })

                const projectIndex = newInitalValue.projects.map((val, i) => {
                  if (projectId.includes(val.id)) {
                    return i
                  }

                  return projectIndex
                })

                projectIndex.filter((item) => item)

                projectId.length && projectIndex.forEach((ele) => {
                  newInitalValue.projects.splice(ele, 1)
                })
                arrClientIndex.length && arrClientIndex.forEach((val) => {
                  newInitalValue.clients.splice(val, 1)
                })
                newTableData.splice(arrIndex, 1);
                setTableData(newTableData)
                setShowDeleteClientModal(false)
                setInitialValue(newInitalValue)
              }}
              buttonText="Delete client"
            />
          </section>
        }
      </FormContextProvider>
    </TooltipContextProvider>
  )
}