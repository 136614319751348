import React, { useContext, useState } from "react";

import ClientDetailsForm from "./Forms/ClientDetailsForm";
import clientApi, { Client } from "../../utils/api/client";
import { Redirect } from "react-router-dom";
import AppDivisionContext from "../../contexts/AppDivisionContext";

export interface IClientAddProps {
  onAdd?: (client: Client) => void;
  show?: boolean;
  handleClose: () => void;
  enableRedirect?: boolean;
}

const ClientAdd: React.FC<IClientAddProps> = ({
  onAdd,
  show = true,
  handleClose,
  enableRedirect = true,
}) => {
  const [redirectClientId, setRedirectClientId] = useState<number | null>(null);
  const { getPath } = useContext(AppDivisionContext);

  function handleSubmit(formState: any) {
    return clientApi.create(formState).then(client => {
      if (typeof onAdd === "function") {
        onAdd(client);
      }

      if (enableRedirect === true) {
        setRedirectClientId(client.id);
      }

      return Promise.resolve(client);
    });
  }

  if (redirectClientId) {
    return <Redirect to={getPath(`/clients/${redirectClientId}`)} push />;
  }

  return (
    <ClientDetailsForm
      handleSubmit={handleSubmit}
      show={show}
      handleClose={handleClose}
      title="Create New Client"
    />
  );
};

export default ClientAdd;
