import * as React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import * as moment from "moment";

import { MapEditorRouteContext } from "../CustomRoutes/MapEditor";
import { Context as InteractionContext } from "./Interaction/InteractionContext";
import { Context as InstanceDataContext } from "./Positionable/InstanceDataContext";
import { dateFormat } from "./../../utils";
import {  useEffect , useState , useContext} from "react";
import { Prompt } from "react-router-dom";
import { Modal } from "@sw-sw/lib-ui";
import RolesContext from "../../contexts/RolesContext";

export const Header = () => {

    const mapRouteContext = useContext(InstanceDataContext);
    const interactionContext = useContext(InteractionContext);
    const mapEditorRouteContext = useContext(MapEditorRouteContext);
    const [closeModal , setCloseModal] = useState<boolean>(false)
    const permCheck = useContext(RolesContext).userHasPermission;

    useEffect(()=>{
      window.onbeforeunload =(e:any)=>{
        if(mapRouteContext.hasPendingUpdates){
          e.preventDefault();
          e.returnValue = true;
        }
      }
    })

    const onContinue = () => {
      mapRouteContext.discard();
      interactionContext.setPositionableType(null);
      setCloseModal(false) 
      mapEditorRouteContext.goBack()
    }

    const openModal = () => {
      setCloseModal(true)
    }
    
    return (
        <MapEditorRouteContext.Consumer>
          {({
            mapName,
            projectName,
            updatedAt,
            inspections,
            selectedInspection,
            setSelectedInspection,
            goBack,
          }) => (
            <div className="map-editor-header">
              <div className="map-editor-header-left">
                <h1>{projectName}</h1>
                <h2>{mapName}</h2>
              </div>

              <div className="map-editor-header-right">
                <div className="map-editor-header-info">
                  {selectedInspection !== null && (
                    <InteractionContext.Consumer>
                      {({ mode }) => (
                        <InstanceDataContext.Consumer>
                          {({ hasPendingUpdates }) => (
                            <select
                              className="map-select-inspection"
                              value={selectedInspection.id}
                              onChange={e => setSelectedInspection(e.target.value)}
                              disabled={hasPendingUpdates || mode === "create"}
                              title="Active Inspection"
                            >
                              {[...inspections]
                                .reverse()
                                .map(({ id, created_date }, index) => (
                                  <option value={id} key={id}>
                                    {`#${index + 1} on ${moment
                                      .utc(created_date)
                                      .format(dateFormat)}`}
                                  </option>
                                ))
                                .reverse()}
                            </select>
                          )}
                        </InstanceDataContext.Consumer>
                      )}
                    </InteractionContext.Consumer>
                  )}
                  <span className="map-updated-at">{(permCheck("all", "Map Drawing") || permCheck("update", "Map Drawing"))&& "Last Saved:" + updatedAt}</span>
                </div>
                
                <span
                  className="map-close pure-button button-transparent"
                  onClick={mapRouteContext.hasPendingUpdates ? openModal : goBack}
                >
                  <FontAwesomeIcon icon="times-circle" />
                </span>
              </div>

              
              {closeModal && 
              <Modal
                handleClose={()=>setCloseModal(false)}
                handleSubmit={onContinue}
                submitBtnText = {"Continue"}
                show={true}
                title={"You have unsaved changes"}
                hideButtons={false}
                cancelBtn={true}
                style={{textAlign:'center'}}
              >
                <p>
                  You have some unsaved changes. Are you sure you want to exit?
                </p>
                <p>
                <small>*Disclaimer : By selecting continue all unsaved changes will be lost.</small>
                </p>
              </Modal>
              }
              <Prompt 
              when={mapRouteContext.hasPendingUpdates}

              message="You have unchanged map edit.Are you sure you want to exit?" />
            </div>
          )}
        </MapEditorRouteContext.Consumer>
    )
}

Header.displayName = "MapEditorHeader";
