import React, { useState } from "react";
import groupApi from "../../utils/api/group";
import ResourceDetailDelete from "../Shared/ResourceDetail/ResourceDetailDelete";

export interface IGroupDeleteProps {
  onGroupDelete: () => void;
  group: { id: number; name: string };
}

const GroupDelete: React.FC<IGroupDeleteProps> = ({ onGroupDelete, group }) => {
  const [show, setShow] = useState<boolean>(false);
  const [errorMsg, setErrorMsg] = useState<string>("");
  const [disableDelete, setDisableDelete] = useState<boolean>(true);

  const handleSubmit = () => {
    groupApi
      .destroy(group.id)
      .then(() => {
        setShow(false);
        setDisableDelete(true);
        onGroupDelete();
      })
      .catch(e => {
        if (e.response && e.response.data && e.response.data.message) {
          setErrorMsg(e.response.data.message);
        } else {
          setErrorMsg("An error has occurred");
        }
      });
  };

  const showModal = () => {
    setShow(true);
    setErrorMsg("");
  };

  const hideModal = () => {
    setShow(false);
    setDisableDelete(true);
  };

  return (
    <ResourceDetailDelete
      resource="Group"
      handleSubmit={handleSubmit}
      confirmationMsg={
        <>
          All documents and contacts associated with{" "}
          <strong>{group.name}</strong> will be deleted. Do you wish to proceed?
        </>
      }
      show={show}
      errorMsg={errorMsg}
      hideModal={hideModal}
      showModal={showModal}
      disableDelete={disableDelete}
      toggleDisabled={() => setDisableDelete(!disableDelete)}
    />
  );
};

export default GroupDelete;
