import { Project } from "../../../utils/api/project";
import { parseYMD } from "../../../utils/date";
import { env } from "../../../config"

export function extractInitialData(project: Project, documentTypes: any[]) {
  const {
    address,
    inspection_template_id: inspectionTemplateId,
    next_inspection_date,
    inspection_compliance_required = true,
    findingComplianceRequired = true,
    ...rest
  } = project;

  // const inspectionDate = next_inspection_date
  //   ? new Date(next_inspection_date)
  //   : null;

  return Object.assign(
    {
      clientId: project.client_id,
      startDate: project.start_date ? new Date(project.start_date) : "",
      endDate: project.end_date ? new Date(project.end_date) : "",
      inspectionTemplateId,
      inspection_compliance_required,
      findingComplianceRequired,
      //address
      addressStreet: address ? address.street_1 : undefined,
      addressCity: address ? address.city : undefined,
      addressState: env.REACT_APP_REGION === "UK" ? address && address.countryObj && address.countryObj.id : address && address.state && address.state.id,
      addressZip: address ? address.zip : undefined,
      latitude: address ? address.latitude : undefined,
      longitude: address ? address.longitude : undefined,
      next_inspection_date: next_inspection_date
        ? parseYMD(next_inspection_date)
        : null,
      // routineStartDate:
      //   inspectionDate && !project.inspections.length
      //     ? new Date(
      //         inspectionDate.getFullYear(),
      //         inspectionDate.getMonth(),
      //         inspectionDate.getDate(),
      //         inspectionDate.getHours() +
      //           inspectionDate.getTimezoneOffset() / 60,
      //       )
      //     : null,
      ...rest,
      inspections: undefined,
    },
    // transform documentTypes to obj
    documentTypes
      ? {
          docTypes: documentTypes.reduce(
            (obj, val) => ({
              ...obj,
              [val.id]: true,
            }),
            {},
          ),
        }
      : undefined,
  );
}
