import React, { useContext } from "react";
import { useHistory } from "react-router-dom";
import AppDivisionContext from "../../../contexts/AppDivisionContext";
import { Project } from "../../../utils/api/project";

const ProjectCard = ({ project }: { project?: Project }) => {
  const { getPath } = useContext(AppDivisionContext);
  const history = useHistory();

  if (!project) {
    throw new Error();
  }

  return (
    <div className="project" key={project.id}>
      <div
        className="project-main"
        onClick={() =>
          history.push(getPath(`/projects/${project.id}/inspections`))
        }
      >
        <h3>{project.name}</h3>
        <h5>Next Inspection: {project.next_inspection_date}</h5>

        <div className="flex-row"></div>
      </div>
    </div>
  );
};

export default ProjectCard;
