import React, { useContext } from "react";
import NotificationControl from "./NotificationControl";
import NotificationSettingsContext from "../../contexts/NotificationsContext";
import ReactToolTip from "react-tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";

const NotificationsSettingsList = props => {
  const settingsContext = useContext(NotificationSettingsContext);
  const { notificationSettings, updateTypeSettings, loading } = settingsContext;

  return (
    <div className="notification-settings">
      <h3>Notification Settings</h3>
      <span className="sub-header-wrapper" >
        <span className="sub-header">Toggle the delivery methods for each notification type</span>
        <span
          className="help-icon"
          data-tip={'By turning on SMS/Text notifications, you are consenting to receive text message notifications from SW2. Data rates may apply. To opt-out, simply turn off the SMS notification for each notification type.'}
          data-delay-show="125"
          onMouseOver={() => ReactToolTip.show()}
        >
          <FontAwesomeIcon icon={faQuestionCircle} />
        </span>
      </span>
      {notificationSettings.map(setting => {
        if (props.isRegulator2) {
          if (setting.name === "Inspection Complete") {
            return (
              <NotificationControl
                updateSettings={updateTypeSettings}
                id={setting.id}
                name={setting.name}
                isEnabled={setting.is_enabled}
                defaultEmailMessage={setting.default_email_message}
                hasCustomMessage={setting.has_custom_message}
                key={setting.id}
                loading={loading}
                description={setting.description}
              />
            )
          }
          
          return null
        }

        return (
          <NotificationControl
            updateSettings={updateTypeSettings}
            id={setting.id}
            name={setting.name}
            isEnabled={setting.is_enabled}
            defaultEmailMessage={setting.default_email_message}
            hasCustomMessage={setting.has_custom_message}
            key={setting.id}
            loading={loading}
            description={setting.description}
          />
        )
      })}
    </div>
  );
};

export default NotificationsSettingsList;
