import {
  IExistingUpload,
  isExistingUpload,
  isUploadWithFile,
  IUpload,
  IUploadWithFile,
} from "@sw-sw/lib-form";
import instance, { xhrService } from "../xhrService";
import uploadApi from "./upload";

export type Finding = {
  id: number;
  type: string;
  date_completed: Date | null;
  date_initiated: Date | null;
};

/**
 * Findings by inspection
 */
const url = (a: number) => `/api/inspections/${a}/findings`;

async function uploadImages<T extends { id: number }>(
  finding: T,
  images: IUpload[] = [],
): Promise<T & { finding_uploads: any[] }> {
  if (images.length === 0) {
    return { ...finding, finding_uploads: [] };
  }

  const [newUploads, existingUploads] = extractUploads(images);

  return uploadApi.finding
    .update(finding.id, newUploads, existingUploads)
    .then(fUploads => {
      return {
        ...finding,
        finding_uploads: fUploads,
      };
    });
}

function extractUploads(
  images: IUpload[],
): [IUploadWithFile[], IExistingUpload[]] {
  const newUploads: IUploadWithFile[] = [];
  const existingUploads: IExistingUpload[] = [];

  images.forEach(image => {
    if (isUploadWithFile(image)) {
      newUploads.push(image);
    } else if (isExistingUpload(image)) {
      existingUploads.push(image);
    }
  });

  return [newUploads, existingUploads];
}

export const findingApi = {
  index: (id: number, excludeCompleted: boolean = false) =>
    instance
      .get(url(id), { params: { excludeCompleted } })
      .then(response => response.data),

  show: (id: number): Promise<Finding> =>
    instance.get(`/api/findings/${id}`).then(response => response.data),

  create: (
    inspectionId: number,
    formData: { [k: string]: any; images: IUpload[] },
  ) => {
    const { images, ...data } = formData;

    return instance
      .post(url(inspectionId), data)
      .then(response => response.data)
      .then(finding => uploadImages(finding, images));
  },

  destroy: (id: number, note: string) =>
    instance
      .delete(`/api/findings/${id}`, { data: { note: note } })
      .then(response => response.data),

  update: (id: number, formData: { [k: string]: any; images: IUpload[] }) => {
    const { images, ...data } = formData;

    return instance
      .post(`/api/findings/${id}`, data)
      .then(response => response.data)
      .then(finding => uploadImages(finding, images));
  },

  bulk: {
    complete: async (
      inspectionId: number,
      data: {
        [imageKeyName: string]: IUpload[];
      } & { finding_ids: number[]; date_completed: Date; comments: string },
    ) => {
      const {
        finding_ids: findingIds,
        date_completed: dateCompleted,
        comments,
      } = data;
      const findingUploads = new Map<number, IUpload[]>();

      findingIds.forEach(id => {
        const imagesKey = `images_${id}`;

        if (Object.prototype.hasOwnProperty.call(data, imagesKey)) {
          findingUploads.set(id, data[imagesKey]);
        }
      });

      const { data: findings }: { data: { id: number }[] } =
        await xhrService.put(`/api/inspections/${inspectionId}/findings`, {
          finding_ids: findingIds,
          date_completed: dateCompleted,
          comments,
        });

      return await Promise.all(
        findings.map(finding =>
          uploadImages(finding, findingUploads.get(finding.id)),
        ),
      );
    },
    addNotes: (inspectionId: number, findingIds: number[], comments: any) => {
      return xhrService.post(
        `/api/inspections/${inspectionId}/findings/notes`,
        {
          findingIds,
          comments,
        },
      );
    },
  },
};

export default findingApi;
