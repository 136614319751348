import React, {
  useContext,
  useState,
  Dispatch,
  useMemo,
  useEffect,
} from 'react';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { ProjectContext } from '../../../contexts/ProjectContext';
import { useProjectCorrectiveActions } from '../../../hooks/projectDetails';
import FindingCard from '../../Inspections/Findings/FindingCard';
import Loading from '../../Shared/ResourceIndex/Loading';
import {
  FormContext,
  FormSchemaFields,
  FormContextProvider,
} from '@sw-sw/lib-form';
const controlField = 'findingFilter';

const FindingFilter = (props: {
  handleChange: Dispatch<string>;
  options: { label: string; value: string }[];
}) => {
  const formContext = useContext(FormContext);

  const schema = {
    [controlField]: {
      controlType: UIControlType.select,
      placeholder: 'Finding Types',
      className: 'filter small',
      options: [{ label: 'All Findings', value: 'all' }, ...props.options],
    },
  };

  return (
    <FormSchemaFields
      className='filter-form'
      formData={formContext.value}
      // initialFormData={{ [controlField]: 'all' }}
      onChange={(field, val) => {
        formContext.set(field, val);
        props.handleChange(val);
      }}
      schema={schema}
    />
  );
};

export default function FindingListing(_props: {
  showFindings: string;
  inspectionId: number;
  Finding: string;
}) {
  const projectContext = useContext(ProjectContext);
  const correctiveActionsQuery = useProjectCorrectiveActions(
    projectContext.project ? projectContext.project.id : 0,
  );
  const [filter, setFilter] = useState<string>('all');

  const [filteredFindings, setFilteredFindings] = useState<any[]>(
    correctiveActionsQuery.data || [],
  );

  useEffect(() => {
    if (correctiveActionsQuery.data) {
      setFilteredFindings(filterFindings(correctiveActionsQuery.data, filter));
    }
  }, [correctiveActionsQuery.data, filter]);

  const filterFindings = (input: any[], filterParam: string) => {
    return input.filter((finding: any) => {
      if (filter === 'all') {
        return true;
      }

      return finding.type === filterParam;
    });
  };

  const getOptions = useMemo(() => {
    if (!correctiveActionsQuery.data) return [];

    //get all unique types
    const uniqueTypes = correctiveActionsQuery.data.reduce(
      (acc: any, finding: any) => {
        if (!acc.includes(finding.type)) {
          acc.push(finding.type);
        }

        return acc;
      },
      [],
    );

    const options = uniqueTypes.sort().map((type: any) => {
      return {
        label:
          type === 'CA'
            ? 'Corrective Action'
            : type === 'A'
            ? 'Achievement'
            : type === 'MI'
            ? 'Maintenance Item'
            : type,
        value: type,
      };
    });

    return options;
  }, [correctiveActionsQuery.data]);

  if (!correctiveActionsQuery.data) {
    return <Loading what='corrective actions' />;
  }

  return (
    <div className='inspection-findings'>
      <div className='right-aligned'>
        <FormContextProvider>
          {correctiveActionsQuery.data.length > 0 && (
            <FindingFilter handleChange={setFilter} options={getOptions} />
          )}
        </FormContextProvider>
      </div>
      <section className='pure-g findings-listing'>
        {!filteredFindings.length ? (
          <p className='pure-u'>No findings</p>
        ) : (
          filteredFindings.map((correctiveActionFinding: any) => (
            <FindingCard
              key={correctiveActionFinding.id}
              finding={correctiveActionFinding}
            />
          ))
        )}
      </section>
    </div>
  );
}
