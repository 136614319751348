import { FormSchema, OptionShape } from '@sw-sw/lib-form';
import { UIControlType } from '@sw-sw/lib-form-control-types';
import { Placement } from 'popper.js';
import React, { MouseEventHandler, useContext } from 'react';
import ProjectContext from '../../../contexts/ProjectContext';
import ProjectPrintContext, {
  InspectionDateFilter,
  PrintProjectFormData,
} from '../../../contexts/ProjectPrintContext';
import { InspectionIdsControl } from '../../Inspections/DateFilter/InspectionIdsControl';
import { getMapOptionsSchema } from '../../Inspections/Details/SiteMapPrint';
import LoadingModal from '../../Shared/Loading/LoadingModal';
import PrintModal from '../../Shared/Print/PrintModal';
import { useProjectSiteMapInspections } from '../../../hooks/inspection';

export type SiteMapSchemaOptions = {
  inspections: OptionShape[];
  filter: InspectionDateFilter;
  filterOptions: OptionShape[];
  siteMapTextLabel: string;
};

export const getSiteMapFilterSchema = ({
  inspections,
  filter,
  filterOptions,
  siteMapTextLabel,
}: SiteMapSchemaOptions): FormSchema => ({
  inspection_label: {
    label: 'Site Maps',
    controlType: UIControlType.plainText,
    style: { marginBottom: 0 },
    className: inspections.length === 0 ? 'hidden' : '',
  },
  timeFilter: {
    controlType: UIControlType.select,
    parse: Number,
    label: 'Filter',
    options: filterOptions,
    style: { flex: '0 1 33%', paddingLeft: '2rem' },
  },
  start_date: {
    controlType: UIControlType.date,
    disabled: (filter.prevMonths || 0) > 0,
    label: 'Start Date',
    style: { flex: '0 1 33%' },
    popperPlacement: 'top' as Placement,
  },
  end_date: {
    controlType: UIControlType.date,
    disabled: (filter.prevMonths || 0) > 0,
    label: 'End Date',
    style: { flex: '0 1 33%' },
    popperPlacement: 'top' as Placement,
    validation: {
      maxDate: new Date(),
    },
  },
  siteMapText: {
    controlType: UIControlType.plainText,
    label: siteMapTextLabel,
    style: { paddingLeft: '2rem', marginBottom: '1rem' },
  },
  select_all: {
    controlType: UIControlType.checkbox,
    label: 'Select All',
    style: { marginBottom: 0, paddingLeft: '2rem' },
    className:
      'inspection-select-all ' + (inspections.length === 0 ? 'hidden' : ''),
  },
  inspectionIds: {
    controlType: UIControlType.custom,
    renderControl: InspectionIdsControl,
    options: inspections,
    validation: {
      required: inspections.length > 0,
    },
    style: { paddingLeft: '2rem' },
    className: 'form-group--checkboxGroup two-columns',
  },
});

const getPrintInspectionsSchema = (options: SiteMapSchemaOptions) => {
  const schema: FormSchema = {
    ...getMapOptionsSchema(),
    ...getSiteMapFilterSchema(options),
  };

  return schema;
};

export interface IPrintInspectionsModal {
  onClose: MouseEventHandler<Element>;
  targetDoc?: any;
}

const PrintSiteMapsModal: React.FC<IPrintInspectionsModal> = ({
  onClose,
  targetDoc,
}) => {
  const projectContext = useContext(ProjectContext);
  const projectPrintContext = useContext(ProjectPrintContext);

  const targetDocId = targetDoc ? targetDoc.id : 0;

  if (!projectContext.project || projectContext.inspectionsQuery.isFetching) {
    return <LoadingModal show />;
  }

  const inspectionsSiteMapQuery = useProjectSiteMapInspections(
    projectContext.project.id,
    targetDocId,
  );
  if (inspectionsSiteMapQuery.isFetching) {
    return <LoadingModal show />;
  }

  return (
    <PrintModal
      jobName={'sitemaps'}
      className='print-inspections-modal'
      jobData={{
        projectId: projectContext.project.id,
        targetDocId: targetDoc ? targetDoc.id : null,
      }}
      statusUpdateKey={projectContext.project.id}
      onClose={onClose}
      formSchema={(formContextValue: PrintProjectFormData) => {
        const filter: InspectionDateFilter = {
          start: formContextValue.start_date,
          end: formContextValue.end_date,
          prevMonths: formContextValue.timeFilter,
        };

        const inspections = projectPrintContext.getInspectionOptions(
          targetDocId
            ? inspectionsSiteMapQuery.data || []
            : projectContext.inspectionsQuery.data || [],
          filter,
        );

        return getPrintInspectionsSchema({
          inspections,
          filter,
          filterOptions: projectPrintContext.timeFilterOptions,
          siteMapTextLabel: projectPrintContext.getSiteMapText({
            inspections,
            filter,
          }),
        });
      }}
      initialFormData={{
        inspectionIds: {},
        include_findings: true,
        projectId: projectContext.project.id,
        timeFilter: 3,
      }}
      modalTitle='Print Site Maps'
      validateData={projectPrintContext.validateInspectionData}
    />
  );
};

export default PrintSiteMapsModal;
