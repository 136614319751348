import React, {
  useState,
  useContext,
  createRef,
  useEffect,
  Fragment,
} from "react";
import Logo from "../../images/SW-Logo-White.svg";
import { Link, useHistory } from "react-router-dom";
import {
  faUserCircle,
  faBars,
  faFolder,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { shape, string } from "prop-types";

import NotificationContext from "../../contexts/NotificationsContext";
import AppDivisionContext from "../../contexts/AppDivisionContext";
import ModalNav from "./ModalNav";
import NotificationBell from "../Shared/Notifications/NotificationBell";
import NotificationPopper from "../Shared/Notifications/NotificationPopper";
import UserPopper from "./poppers/UserPopper";
import DivisionPopper from "./poppers/DivisionPopper";
import { getRootProperties } from "./SecondaryNavigation";

/**
 * Default application layout, provides a sidebar and content area
 *
 * User should be logged in
 */

const PopperMenu = ({ type, ...props }) => {
  switch (type) {
    case "user":
      return <UserPopper {...props} />;
    case "notification":
      return <NotificationPopper {...props} />;
    case "division":
      return <DivisionPopper {...props} />;
    default:
      return "";
  }
};

const HamburgerBar = props => {
  const [menuType, setMenuType] = useState("user"); // oneOf: ['user', 'notification', 'division']
  const [location, setLocation] = useState(null);
  const [open, setOpen] = useState(false);
  const [coordinates, setCoordinates] = useState({ top: null, right: null });
  const [showPopper, setPopperVis] = useState(false);

  const history = useHistory();
  const notificationRef = createRef();
  const userRef = createRef();
  const divisionRef = createRef();
  const notificationContext = useContext(NotificationContext);
  const appDivisionContext = useContext(AppDivisionContext);

  const notificationCount = notificationContext.notifications.filter(
    ({ is_opened }) => !is_opened,
  ).length;

  useEffect(() => {
    if (location !== props.match.path) {
      setOpen(false);
    }
    setLocation(props.match.path);
  }, [props.match.path]);

  /**
   *
   * @param {string} type `user` `notif`
   */

  const getCoordinates = type => {
    switch (type) {
      case "user":
        return userRef.current.getBoundingClientRect();
      case "notification":
        return notificationRef.current.getBoundingClientRect();
      case "division":
        return divisionRef.current.getBoundingClientRect();
      default:
        return userRef.current.getBoundingClientRect();
    }
  };

  const getDivision = () => {
    const division = appDivisionContext.divisions.find(
      div => div.id === parseInt(appDivisionContext.appDivisionId),
    );

    return division ? division.name : null;
  };

  function handleClick(type) {
    const { right, bottom } = getCoordinates(type);

    setCoordinates({ right: window.innerWidth - right, top: bottom });
    setMenuType(type);
    setPopperVis(true);
  }

  const onDivisionChange = (division = appDivisionContext.appDivisionId) => {
    const { rootTo } = getRootProperties(props.match);

    history.push(appDivisionContext.getPath(rootTo, division));
  };

  return (
    <Fragment>
      <header className="hamburger-nav-bar">
        <FontAwesomeIcon
          icon={faBars}
          className="nav-icon pointer"
          onClick={() => setOpen(true)}
          title="Open menu"
        />

        <Link to={`/divisions/${getDivision}/dashboard`} className="home-link" title="Home">
          <img src={Logo} alt="logo" className="nav-icon" />
        </Link>

        <div>
          {getDivision() && !appDivisionContext.disableSwitcher && (
            <span
              onClick={() => handleClick("division")}
              className="inline-nav pad-right pointer"
              ref={divisionRef}
            >
              <FontAwesomeIcon icon={faFolder} className="nav-icon" />
            </span>
          )}

          <NotificationBell
            handleClick={() => handleClick("notification")}
            notificationCount={notificationCount}
            className="nav-icon"
            ref={notificationRef}
            title="Notifications"
          />

          <span
            onClick={() => handleClick("user")}
            className="inline-nav pointer"
            ref={userRef}
          >
            <FontAwesomeIcon icon={faUserCircle} className="nav-icon" />
          </span>
        </div>
      </header>

      <ModalNav
        open={open}
        handleClose={() => setOpen(false)}
        match={props.match}
      />

      {showPopper && (
        <PopperMenu
          type={menuType}
          coordinates={coordinates}
          handleClose={() => setPopperVis(false)}
          onChange={menuType === "division" ? onDivisionChange : null}
        />
      )}
    </Fragment>
  );
};

HamburgerBar.propTypes = {
  match: shape({
    path: string.isRequired,
  }).isRequired,
};

export default HamburgerBar;
