import React, { useContext, useState, useEffect } from "react";
import { useQueryClient } from "react-query";

import userApi from "../utils/api/user";
import AppContext from "./AppContext";

export const AuthenticationContext = React.createContext();

/**
 * UserStore
 *
 * Manage user authentication only. (not authorization)
 *
 * @important don't manage roles & permissions here
 */
export function AuthenticationStore(props) {
  const client = useQueryClient();
  const appStore = useContext(AppContext);
  const [loggedIn, setLoggedIn] = useState(appStore.has("user"));

  useEffect(() => {
    setLoggedIn(appStore.has("user"));
  }, [appStore]);

  return (
    <AuthenticationContext.Provider
      value={{
        loggedIn,
        login: async (email, password) => {
          const success = await userApi.login(email, password);

          if (success) {
            await appStore.reloadInitialData();

            return true;
          }

          throw new Error("Invalid response data");
        },
        publiclogin: async (email, password) => {
          const success = await userApi.publiclogin(email, password);

          if (success) {
            await appStore.reloadInitialData();

            return true;
          }

          throw new Error("Invalid response data");
        },
        logout: () => {
          userApi.logout();
          client.removeQueries();
          appStore.set("user", null);
        },
        resetPassword: (token, form) => userApi.resetPassword(token, form),
        register: (token, form) => userApi.register(token, form),
      }}
    >
      {props.children}
    </AuthenticationContext.Provider>
  );
}

export default AuthenticationContext;
