import React, { useContext, useState } from 'react';
import { string, bool, func, number } from 'prop-types';

import InspectionContext from '../../../contexts/InspectionContext';
import { RolesContext } from '../../../contexts/RolesContext';
import AppDivisionContext from '../../../contexts/AppDivisionContext';
import DashboardContext from '../../../contexts/DashboardContext';
import ConfirmationModal from '../../Shared/ConfirmationModal/ConfirmationModal';
import { Redirect } from 'react-router-dom';
import inspectionApi from '../../../utils/api/inspection';
import ActionButtons from '../../Shared/ActionButtons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

function InspectionHeader({
  title,
  projectName,
  clientName,
  enablePrint,
  handlePrint,
  handleSiteMapPrint,
  inspectionNumber,
  inspectionId,
}) {
  const inspectionStore = useContext(InspectionContext);
  const roleStore = useContext(RolesContext);
  const { fetchStatsFuncForCal } = useContext(DashboardContext);
  const { getPath, getPublicPath } = useContext(AppDivisionContext);

  const [showDelete, setShowDelete] = useState(false);
  const [toProject, setToProject] = useState(false);

  const isPublic = window.location.pathname.startsWith("/public")

  // init actions

  const secondaryActions = [
    {
      label: 'Back to Project',
      icon: 'folder',
      onClick: () => setToProject(true),
    },
  ];

  if (roleStore.userHasPermission('delete', 'Inspection')) {
    secondaryActions.push({
      label: 'Delete Inspection',
      icon: 'trash',
      onClick: () => setShowDelete(true),
    });
  }

  /**
   * Print should be last
   *
   * @todo enable this button via a "Print Inspection" permission
   * if (roleStore.userHasPermission('all', 'Print Inspection')) { }
   * */
  if (enablePrint) {
    secondaryActions.push(
      {
        label: 'Print Inspection',
        icon: 'print',
        onClick: handlePrint,
      },
      {
        icon: 'print',
        label: 'Print Site Map',
        onClick: handleSiteMapPrint,
      },
    );
  }

  // render
  if (toProject) {
    if (isPublic) {
      return (
        <Redirect
          to={getPublicPath(`/projects/${inspectionStore.getProjectId()}/inspections`)}
          push
        />
      )
    }

    return (
      <Redirect
        to={getPath(`/projects/${inspectionStore.getProjectId()}/inspections`)}
        push
      />
    );
  }

  return (
    <header className='inspection-header'>
      <div className='inspection-header-info text-truncate'>
        <h2>{title}</h2>
        <h5>
          <FontAwesomeIcon icon='hashtag' className='header-icon' />
          {inspectionNumber} | ID {inspectionId}
        </h5>
        <h5>
          <FontAwesomeIcon icon='folder' className='header-icon' />
          {projectName}
        </h5>
        <h5>
          <FontAwesomeIcon icon='user' className='header-icon' />
          {clientName}
        </h5>
      </div>

      <ActionButtons secondary={secondaryActions} />

      {showDelete && (
        <ConfirmationModal
          show={showDelete}
          title='Are you sure you want to delete this inspection?'
          subTitle=' The inspection will be hidden from all views. This action cannot be reverted.'
          buttonText='Delete'
          handleClose={() => setShowDelete(false)}
          handleConfirm={() => 
            inspectionApi
              .destroy(inspectionStore.inspectionId)
              .then(() => {
                setToProject(true)
                fetchStatsFuncForCal()
              })
          }
        />
      )}
    </header>
  );
}

InspectionHeader.propTypes = {
  title: string.isRequired,
  enablePrint: bool,
  handlePrint: func,
  inspectionNumber: number,
  inspectionId: number,
};

InspectionHeader.defaultProps = {
  enablePrint: false,
  handlePrint: () => { },
  handleSiteMapPrint: () => { },
  inspNumber: 0,
  inspId: 0,
};

export default InspectionHeader;
