import {
    InspectionTemplate,
    QuestionType,
} from "@sw-sw/lib-inspection-templates";
import { LoadingMessage } from "@sw-sw/lib-ui";
import React, {
    PropsWithChildren,
    useContext,
    useEffect,
    useState,
} from "react";
import { useQuery } from "react-query";
import { useRouteMatch } from "react-router";
import { InspectionContext } from "../../contexts/InspectionContext";
import { ProjectContext } from "../../contexts/ProjectContext";
import ProjectPermissionContext from "../../contexts/ProjectPermissionContext";
import { QuestionCommentProvider } from "../../contexts/QuestionCommentContext";
import {
    QuestionTypeContext,
    withQuestionTypeProvider,
} from "../../contexts/QuestionTypeContext";
import { withProjectPermissionProvider } from "../../hoc/withProjectPermissionContext";
import { useQuestionTypes } from "../../hooks/questionTypes";
import inspectionApi from "../../utils/api/inspection";
import projectApi from "../../utils/api/project";
import { withoutSidebarLayout } from "./WithoutSidebar";
import PublicURLNavbar from "../PublicUrl/Component/PublicURLNavbar";

/**
 * Check the inspection project
 */
function InspectionDetailsLayout({ children }: PropsWithChildren<{}>) {
    const inspectionContext = useContext(InspectionContext);
    const projectContext = useContext(ProjectContext) as any;
    const projectPermissionContext = useContext(ProjectPermissionContext);
    const questionTypeContext = useContext(QuestionTypeContext);
    const [loading, setLoading] = useState(true);
    const match = useRouteMatch<{ inspectionId: string }>();

    const id = Number(match.params.inspectionId);
    const questionTypesQuery = useQuestionTypes();

    const inspectionQuery = useQuery<{
        /** @todo add types here */
        inspection: any & {
            findings: any[];
            project: any;
        };
        template: InspectionTemplate;
        questionTypes: QuestionType[];
    }>(
        ["inspection", id],
        async () => {
            const { inspection, ...data } = await inspectionApi.get(id);
            const project = await (projectContext.project
                ? projectContext.project
                : projectApi.get(inspection.project_id));

            return {
                ...data,
                inspection: {
                    ...inspection,
                    project,
                },
            };
        },
        {
            refetchOnWindowFocus: false,
            keepPreviousData: false,
        },
    );

    useEffect(() => {
        const { data, isFetched } = inspectionQuery;

        if (isFetched && data) {
            inspectionContext.updateInspection(data.inspection);
            inspectionContext.setFindings(data.inspection.findings);
            inspectionContext.setTemplate(data.template);
            inspectionContext.setInspectionLoaded(true);

            projectContext.setProject(data.inspection.project);

            projectPermissionContext.setReadOnly(
                data.inspection.project.archivedAt ? true : false,
            );
        }
    }, [inspectionQuery.isFetched]);

    useEffect(() => {
        if (questionTypesQuery.isFetched && questionTypesQuery.data) {
            questionTypeContext.setTypes(questionTypesQuery.data);
        }
    }, [questionTypesQuery.isFetched]);

    useEffect(() => {
        const { inspection } = inspectionContext;
        const { project } = projectContext;

        setLoading(!(inspection.id && project && questionTypesQuery.isFetched));
    }, [projectContext, inspectionContext, questionTypesQuery.isFetched]);

    useEffect(() => {
        return () => {
            projectContext.clear();
            inspectionContext.resetInspection();
            inspectionContext.setTemplate(null);
            inspectionQuery.remove();
        };
    }, []);

    return (
        <div className="PublicInspectionLayout__wrapper">
            {loading ? (
                <div className="PublicInspectionLayout__loader">
                    <LoadingMessage what="Inspection Details" showLoadingText />
                </div>
            ) : (
                <>
                    <PublicURLNavbar />
                    <div className="PublicURl">
                        <QuestionCommentProvider>{children}</QuestionCommentProvider>
                    </div>
                </>
            )}
        </div>
    );
}

export default withoutSidebarLayout(
    withProjectPermissionProvider(
        withQuestionTypeProvider(InspectionDetailsLayout),
    ),
);
