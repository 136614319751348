import WebViewerClass, { Core, WebViewerInstance } from "@pdftron/webviewer";
import { MutableRefObject, useContext, useEffect, useState } from "react";
import { useTimeoutFn } from "react-use";
import ProjectContext from "../contexts/ProjectContext";
import { getDownloadUrl } from "../utils/api/upload";
import xfdfApi from "../utils/api/xfdf";
import { AnnotationApi, useAnnotationApi } from "./annotationApi";
import { useEnvConfig } from "./envConfig";

type PdfEditorProps = {
  upload: { id: number; guid: string };
  ref: MutableRefObject<HTMLDivElement | null>;
};

export type EditorStatus = "loading" | "saving" | "loaded" | "saved";

/**
 * @param param0
 * @returns
 */
export function usePdfEditor({ upload, ref }: PdfEditorProps): {
  status: EditorStatus;
} {
  const projectId = useProjectId();
  const annotationApi = useAnnotationApi(upload.id, projectId);
  const licenseKey = useEnvConfig("WEBVIEWER_KEY");
  const publicUrl = useEnvConfig("PUBLIC_URL");
  const [viewer, setViewer] = useState<null | WebViewerInstance>(null);
  const [annotationsLoaded, setAnnotationsLoaded] = useState(false);
  const [editorStatus, setEditorStatus] = useState<EditorStatus>("loading");
  const settleEditorStatus = useTimeoutFn(() => {
    if (viewer) {
      setEditorStatus("loaded");
    }
  }, 100);

  useEffect(() => {
    if (ref.current && !viewer) {
      const element = ref.current;

      WebViewerClass(
        {
          path: `${publicUrl}/libs/Webviewer`,
          licenseKey,
          extension: "pdf",
          isAdminUser: true,
          annotationUser: annotationApi.authorName,
          initialDoc: getDownloadUrl(upload.guid, null, true),
        },
        element,
      ).then(viewerInstance => {
        // console.log("almost loaded", { viewerInstance });

        setViewer(viewerInstance);

        // element.addEventListener("documentLoaded", () => { });

        viewerInstance.Core.annotationManager.on(
          "annotationChanged",
          (_annotations, _action, info) => {
            onAnnotationChange(
              viewerInstance,
              async model => {
                setEditorStatus("saving");

                await annotationApi.save(model);

                setEditorStatus("saved");
                settleEditorStatus[2]();
              },
              info,
              projectId,
            );
          },
        );

        viewerInstance.Core.documentViewer.on("annotationsLoaded", () => {
          setAnnotationsLoaded(true);
        });
      });
    }
  }, []);

  useEffect(() => {
    if (annotationsLoaded && viewer) {
      console.log("before loadRemoteAnnotations");
      loadRemoteAnnotations(annotationApi, viewer).then(() => {
        setEditorStatus("loaded");
      });
    }
  }, [annotationsLoaded, viewer]);

  return { status: editorStatus };
}

export async function loadRemoteAnnotations(
  { data }: AnnotationApi,
  webViewerInstance: WebViewerInstance,
) {
  const annotationCommands = data.filter(d => d.isCommand);

  await Promise.all(
    annotationCommands.map(async row => {
      const annotations =
        await webViewerInstance.Core.annotationManager.importAnnotCommand(
          row.xfdfValue,
        );
      await webViewerInstance.Core.annotationManager.drawAnnotationsFromList(
        annotations,
      );
    }),
  );
}

async function onAnnotationChange(
  webViewerInstance: WebViewerInstance,
  apiSave: AnnotationApi["save"],
  info: Core.AnnotationManager.AnnotationChangedInfoObject,
  projectId?: number,
) {
  if (!info.imported) {
    console.log("changed", info);

    await saveAnnotationCommands(
      webViewerInstance.Core.annotationManager,
      apiSave,
      projectId,
    );
  }
}

async function saveAnnotationCommands(
  manager: Core.AnnotationManager,
  apiSave: AnnotationApi["save"],
  projectId?: number,
) {
  const xfdfString = await manager.exportAnnotCommand();
  const parser = new DOMParser();
  const commandData = parser.parseFromString(xfdfString, "application/xml");
  const addedAnnots = commandData.getElementsByTagName("add")[0];
  const modifiedAnnots = commandData.getElementsByTagName("modify")[0];
  const deletedAnnots = commandData.getElementsByTagName("delete")[0];
  const saveRequests: Promise<void>[] = [];

  console.log({ addedAnnots, modifiedAnnots, deletedAnnots });

  // List of added annotations
  addedAnnots.childNodes.forEach(child => {
    if (isElement(child) && hasTitleAttribute(child)) {
      const model = xfdfApi.getCommandModel(child, "add", projectId);

      if (model.key) {
        saveRequests.push(apiSave(model));
      }
    }
  });

  // List of modified annotations
  modifiedAnnots.childNodes.forEach(child => {
    if (isElement(child) && hasTitleAttribute(child)) {
      saveRequests.push(apiSave(xfdfApi.getCommandModel(child, "modify", projectId)));
    }
  });

  // List of deleted annotations
  deletedAnnots.childNodes.forEach(child => {
    if (isElement(child)) {
      saveRequests.push(apiSave(xfdfApi.getCommandModel(child, "delete", projectId)));
    }
  });

  await Promise.all(saveRequests);
}

function isElement(child: ChildNode): child is Element {
  return child.nodeType == Node.ELEMENT_NODE;
}

function hasTitleAttribute(element: Element) {
  return (element.getAttribute("title") || "").length > 0;
}

function useProjectId() {
  const { project } = useContext(ProjectContext);

  if (project && project.id) {
    return project.id;
  }

  return undefined;
}
