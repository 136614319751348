import React, { useContext } from "react";
import { func, shape, number } from "prop-types";
import classnames from "classnames";
import { Link as ReactRouterLink } from "react-router-dom";
import { withRouter } from "react-router-dom";

import NotificationContext from "../../../contexts/NotificationsContext";
import AppDivisionContext from "../../../contexts/AppDivisionContext";
import Popper from "../Popper/Popper";

function NotificationPopper(props) {
  const notificationContext = useContext(NotificationContext);
  const { getPath } = useContext(AppDivisionContext);

  function handleNotificationClick(id, location) {
    notificationContext.markNotificationAsRead(id);
    props.handleClose(id);
    if (location.match(/^https:\/\/\S*/)) {
      window.location = location;
    } else {
      props.history.push(getPath(location));
    }
  }

  const Link = ({ children, ...rest }) => {
    return (
      <ReactRouterLink {...rest} onClick={props.handleClose}>
        {children}
      </ReactRouterLink>
    );
  };

  return (
    <Popper
      classes="notification-menu"
      coordinates={props.coordinates}
      handleClose={props.handleClose}
    >
      <div className="header">
        <h3>Notifications</h3>
        <Link className="no-underline" to="/my-profile">
          View Settings
        </Link>
      </div>

      <ul className="nav-list">
        {notificationContext.notifications.map(
          ({ id, title, content, date, link, is_opened }) => (
            <li
              key={id}
              className={classnames("nav-list-item pointer", {
                isOpened: is_opened,
              })}
              onClick={() => handleNotificationClick(id, link)}
            >
              <span className="title">{title}</span>
              <div className="notification-detail">
                <span>{content}</span>
                <span>{date}</span>
              </div>
            </li>
          ),
        )}
      </ul>
    </Popper>
  );
}

NotificationPopper.propTypes = {
  handleClose: func.isRequired,
  coordinates: shape({
    top: number,
    bottom: number,
    left: number,
    right: number,
  }).isRequired,
};

export default withRouter(NotificationPopper);
