import { InspectionTemplate } from "@sw-sw/lib-inspection-templates";
import { find, get } from "lodash";
import moment from "moment";
import "moment-timezone";
import React, {
  Dispatch,
  PropsWithChildren,
  SetStateAction,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { QueryObserverResult, useQuery } from "react-query";
import { useRouteMatch } from "react-router";
import AppDivisionContext from "../contexts/AppDivisionContext";
import {
  InspectionWithFindingCount,
  useProjectInspections
} from "../hooks/inspection";
import divisionApi from "../utils/api/division";
import inspectionApi from "../utils/api/inspection";
import projectApi, { Project } from "../utils/api/project";
import { ProjectInspection } from "../utils/api/projectInspection";

type ProjectDocs = any;

export type ProjectContextValue = {
  project: Project | null;
  projectDocs: ProjectDocs | null;
  projectName: string | null;
  clientName: string | null;
  setProject: (input: Project | null) => void;
  setProjectDocs: (input: ProjectDocs | null) => void;
  updateProjectDocs: (patch: any) => void;
  clear: Dispatch<void>;
  template?: InspectionTemplate | null;
  setTemplate: Dispatch<SetStateAction<InspectionTemplate | null | undefined>>;
  loadDocs: (id: number) => void;
  inspectionsQuery: QueryObserverResult<InspectionWithFindingCount[]>;
  inspections: InspectionWithFindingCount[];
  nextInspectionDate: string | null;
  nextInspectionDueToday: boolean;
  inspectionDueToday: () => ProjectInspection | null;
  startRoutineInspection: () => Promise<any>;
  setNextInspectionDueToday: Dispatch<SetStateAction<boolean>>;
  handleStormSelect: (id: number, total: number) => void;
  selectedProjects: Array<number>;
  handleSelectAll: (ids: Array<number>) => void;
  allSelected: boolean;
  handleCancelBulkStorm: Dispatch<void>;
};

const Context = React.createContext<ProjectContextValue>(null as any);

export const ProjectProvider: React.FC<PropsWithChildren<{}>> = ({
  children,
}) => {
  const routeMatch = useRouteMatch();
  const appDivisionContext = useContext(AppDivisionContext);
  const divisionId = get(
    routeMatch.params,
    "division_id",
    appDivisionContext.appDivisionId,
  );
  const templateQuery = useQuery({
    queryFn: () =>
      divisionId
        ? divisionApi.inspectionTemplates.index(divisionId)
        : Promise.resolve([]),
    queryKey: ["divisionInspectionTemplates", divisionId],
    refetchOnWindowFocus: false,
  });
  const [project, setProject] = useState<Project | null>(null);
  const [projectDocs, setProjectDocs] = useState<ProjectDocs | null>(null);
  const [template, setTemplate] = useState<
    InspectionTemplate | null | undefined
  >(null);
  const [selectedProjects, setSelectedProjects] = useState<Array<number>>([]);
  const [allSelected, setAllSelected] = useState(false);

  const inspectionsQuery = useProjectInspections(project ? project.id : 0);
  const inspections = inspectionsQuery.data || [];

  const [dueTodayInspectionId, setDueTodayInspectionId] = useState<
    number | null
  >(null);
  const nextInspectionDate =
    project && project.next_inspection_date
      ? project.next_inspection_date
      : null;

  const [nextInspectionDueToday, setNextInspectionDueToday] =
    useState<boolean>(false);

  const handleStormSelect = (id: number, total: number) => {
    const newState: Array<number> = [...selectedProjects];

    if (newState.includes(id)) {
      const filterState: Array<number> = newState.filter(item => item !== id);

      setSelectedProjects(filterState);

      if (allSelected) {
        setAllSelected(false);
      }
    } else {
      newState.push(id);
      setAllSelected(newState.length === total);
      setSelectedProjects(newState);
    }
  };

  const handleSelectAll = (ids: Array<number>) => {
    if (allSelected) {
      setAllSelected(false);
      setSelectedProjects([]);
    } else {
      setAllSelected(true);
      setSelectedProjects(ids);
    }
  };

  const handleCancelBulkStorm = () => {
    setAllSelected(false);
    setSelectedProjects([]);
  };

  const inspectionDueToday = () => {
    if (dueTodayInspectionId && inspections.length) {
      const i = inspections.filter(({ id }) => id === dueTodayInspectionId);

      if (i.length) {
        return i[0];
      }
    }

    // create "virtual" inspection
    if (nextInspectionDueToday && nextInspectionDate) {
      return {
        id: -1,
        status: "new",
      } as ProjectInspection;
    }

    return null;
  };

  const startRoutineInspection = useCallback(() => {
    if (project) {
      if (dueTodayInspectionId) {
        return Promise.resolve(dueTodayInspectionId);
      } else {
        // new inspection and return id
        return inspectionApi.create(project.id, "Routine").then(i => i.id);
      }
    }
    return Promise.resolve();
  }, [project]);

  const clear = () => {
    setProject(null);
    setProjectDocs(null);
    setTemplate(null);
    setDueTodayInspectionId(null);
    setNextInspectionDueToday(false);
    setSelectedProjects([]);
    setAllSelected(false);
  };

  const loadDocs = async (id: number) => {
    const data = await projectApi.getDocs(id);

    setProjectDocs(data);
  };

  const updateProjectDocs = (patch: any) => {
    setProjectDocs(Object.assign({}, projectDocs, patch));
  };

  useEffect(() => {
    if (
      project &&
      project.id &&
      project.inspection_template_id &&
      templateQuery.isFetched &&
      templateQuery.data
    ) {
      setTemplate(
        templateQuery.data.find(
          tmpl => tmpl.id === project.inspection_template_id,
        ),
      );
    }
  }, [project, templateQuery.isFetched]);

  useEffect(() => {
    if (
      inspectionsQuery.isFetched &&
      project &&
      project.timezone &&
      project.next_inspection_date
    ) {
      const today = moment().millisecond(0).second(0).minute(0).hour(0);
      const isInspectionDueToday = find(
        inspectionsQuery.data || [],
        (inspection: any) => {
          return (
            inspection.type === "Routine" &&
            today.isSame(moment.tz(inspection.created_date, project.timezone))
          );
        },
      );

      setNextInspectionDueToday(
        today.isSame(moment(project.next_inspection_date)),
      );

      if (isInspectionDueToday && isInspectionDueToday.id) {
        setDueTodayInspectionId(isInspectionDueToday.id);
      }
    }
  }, [inspectionsQuery.isFetched, project]);

  return (
    <Context.Provider
      value={{
        project,
        projectName: project ? project.name : null,
        clientName: project && project.client ? project.client.name : null,
        setProject,
        projectDocs,
        setProjectDocs,
        updateProjectDocs,
        template,
        setTemplate,
        selectedProjects,
        allSelected,
        inspectionsQuery,
        inspections: inspectionsQuery.data || [],
        nextInspectionDate,
        nextInspectionDueToday,
        setNextInspectionDueToday,
        handleStormSelect,
        handleSelectAll,
        handleCancelBulkStorm,
        inspectionDueToday,
        startRoutineInspection,
        clear,
        loadDocs
      }}
    >
      {children}
    </Context.Provider>
  );
};

export default Context;
export const ProjectContext = Context;
