import React, { Component, createRef, useContext } from "react";
import { string, bool, func, arrayOf, object, number } from "prop-types";
import { noop } from "lodash";
import ReactTooltip from "react-tooltip";
import c from "classnames";

import { Button } from "@sw-sw/lib-ui";
import UploadInput from "../../FormInput/Upload";
import { print } from "../../../utils/print";
import FileRow from "./FileRow";
import uploadApi from "../../../utils/api/upload";
import RolesContext from "../../../contexts/RolesContext";

const SHOW_LESS_LIMIT = 3;

/**
 * List of file cards, visually grouped beneath a group name heading
 */
export class FileCardListUI extends Component {
  static propTypes = {
    groupName: string.isRequired,
    documents: arrayOf(object).isRequired,
    allowedTypes: arrayOf(string), // array of mime_types

    // feature flags
    // (default off)
    canEdit: bool,
    canPrint: bool,
    canDelete: bool,
    canReplace: bool,
    canRename: bool,
    hideTitle: bool,
    // (default on)
    canUpload: bool,
    canDownload: bool,

    // callbacks for functionality handled in this component and its children
    onUpload: func,

    // functionality handled by the implementing/parent component
    handleDelete: func,
    handleEdit: func,
    handleRename: func,
    handleReplace: func,
    label: string,
    permCheck: func.isRequired,
    showMoreThreshold: number,
    minimumImageDimensions: object,
    maxFileSize: number,
  };

  static defaultProps = {
    onUpload: noop,
    handleDelete: noop,
    canUpload: true,
    canDownload: true,
    canRename: true,
    showMoreThreshold: SHOW_LESS_LIMIT,
    maxFileSize: 75000000,
    minimumImageDimensions: {
      width: 50,
      height: 50,
    },
  };

  constructor(props) {
    super(props);

    this.tableRef = createRef();
  }

  state = {
    isShowingMore: false,
  };

  toggleShowMore() {
    this.setState({ isShowingMore: !this.state.isShowingMore });
  }

  componentDidUpdate() {
    ReactTooltip.rebuild();
  }

  render() {
    const {
      groupName,
      documents,
      onUpload,
      handleDelete,
      handleEdit,
      handleRename,
      handleReplace,
      permCheck,
      label,
      canEdit,
      canPrint,
      canDelete,
      canReplace,
      canRename,
      canUpload,
      canDownload,
      hideTitle,
      allowedTypes,
      minimumImageDimensions,
      maxFileSize,
      onMove,
      onDrop,
      canDrag,
    } = this.props;

    var { showMoreThreshold } = this.props;

    if (!showMoreThreshold) {
      showMoreThreshold = SHOW_LESS_LIMIT;
    }

    return (
      <section className="document-group">
        {hideTitle ? null : (
          <div className="document-group__header">
            <h3 className="document-group__title">{groupName}</h3>
            {canUpload &&
              onUpload &&
              permCheck("update", label) &&
              permCheck("create", "Uploads") && (
                <div className="document-group__item-upload">
                  <UploadInput
                    onUpload={newFile => (onUpload ? onUpload(newFile) : null)}
                    imageDimensions={minimumImageDimensions}
                    allowedTypes={allowedTypes}
                    maxFileSize={maxFileSize}
                  />
                </div>
              )}
          </div>
        )}
        {/* empty state */}
        {!documents.length ? (
          <div className="document-group__empty-state">
            <p className="document-group__title--empty-state">
              No documents have been uploaded
            </p>
          </div>
        ) : null}
        {/* show the upload button here, when title is hidden */}
        {hideTitle &&
        permCheck("create", "Uploads") &&
        permCheck("update", label) ? (
          <UploadInput
            onUpload={newFile => (onUpload ? onUpload(newFile) : null)}
            allowedTypes={allowedTypes}
            maxFileSize={maxFileSize}
            imageDimensions={minimumImageDimensions}
            className={c({
              "upload-control--cta": !documents.length,
              "upload-control--row": documents.length > 0,
            })}
            showHelpText
          />
        ) : null}
        {/* non-empty */}
        {documents.length ? (
          <table className="document-group__items" ref={this.tableRef}>
            <thead>
              <tr className="document-group__list-header">
                {canDrag ? <th className="document-group__list-th"></th> : null}
                <th className="document-group__list-th">Name</th>
                <th className="document-group__list-th">Type</th>
                <th className="document-group__list-th document-group__list-th--hide-on-mobile">
                  Created
                </th>
                <th className="document-group__list-th document-group__list-th--hide-on-mobile">
                  Updated
                </th>
                <th className="document-group__list-th">
                  {/* Leave empty for CSS grid so table actions have room */}
                </th>
              </tr>
            </thead>

            <tbody>
              {documents
                .slice(
                  0,
                  this.state.isShowingMore
                    ? documents.length
                    : this.props.showMoreThreshold,
                )
                .map((doc, index) => {
                  let projectId = null;

                  if (
                    doc.ProjectUploadModel &&
                    doc.ProjectUploadModel.project_id
                  ) {
                    projectId = doc.ProjectUploadModel.project_id;
                  } else if (doc.projectId) {
                    projectId = doc.projectId;
                  }

                  return (
                    <FileRow
                      key={doc.id}
                      index={index}
                      fileName={doc.name}
                      document={doc}
                      canDownload={canDownload}
                      canDrag={canDrag}
                      downloadUrl={
                        canDownload
                          ? uploadApi.getDownloadUrl(
                              doc.GUID,
                              null,
                              false,
                              Date.now(),
                              projectId,
                            )
                          : ""
                      }
                      canEdit={canEdit && doc.mime_type === "application/pdf"}
                      canPrint={canPrint && doc.mime_type === "application/pdf"}
                      canDelete={canDelete}
                      canReplace={canReplace}
                      canRename={canRename}
                      handleDelete={() => handleDelete(doc)}
                      handleEdit={() => handleEdit(doc)}
                      handleRename={() => handleRename(doc)}
                      handleReplace={newDoc => handleReplace(newDoc, doc)}
                      handlePrint={() => (canPrint ? print(doc) : null)}
                      permCheck={permCheck}
                      label={label}
                      maxFileSize={maxFileSize}
                      minimumImageDimensions={minimumImageDimensions}
                      parentRef={this.tableRef}
                      onMove={onMove}
                      onDrop={onDrop}
                      documentGroupName={groupName}
                    />
                  );
                })}
            </tbody>
          </table>
        ) : null}
        {documents.length > this.props.showMoreThreshold ? (
          <Button
            small={true}
            className="document-group__expand-action"
            onClick={() => {
              this.toggleShowMore();
            }}
          >
            Show {this.state.isShowingMore ? "Less" : "More"}
          </Button>
        ) : null}
      </section>
    );
  }
}

const FileCardList = ({ permCheck, ...props }) => {
  const roleStore = useContext(RolesContext);

  if (!permCheck) {
    permCheck = roleStore.userHasPermission;
  }

  return <FileCardListUI {...props} permCheck={permCheck} />;
};

export default FileCardList;
