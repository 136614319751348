import { kebabCase } from "lodash";
import React, { FC } from "react";
import NavTabs, { Tab as NavTab } from "../NavTabs";

interface IResourceDetailTabsProps {
  Component?: FC;
  headers: string[];
  onSelect?: (index: number) => void;
  panels?: FC[];
  urlPrefix?: string;
}

const ResourceDetailTabs: React.FC<IResourceDetailTabsProps> = ({
  Component: TabComponent,
  headers,
  onSelect,
  panels,
  urlPrefix = "/",
}) => {
  if (!TabComponent && !panels) {
    throw new Error('Either "Component" or "panels" is required.');
  }

  const tabs = headers.map<NavTab>((label, index) => {
    var Tab = TabComponent;

    if (!Tab && panels) {
      Tab = panels[index];
    }

    if (!Tab) {
      Tab = () => <p>no tab</p>;
    }

    return {
      Component: Tab,
      label,
      to: `${urlPrefix}${kebabCase(label)}`,
    };
  });

  return <NavTabs tabs={tabs} onSelectTab={onSelect} />;
};

export default ResourceDetailTabs;
