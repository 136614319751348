import { QuestionType, QuestionFeature } from "@sw-sw/lib-inspection-templates";
import { isNull, sortBy } from "lodash";
import moment from "moment";
import React, { useContext, useEffect } from "react";
import { useQuery } from "react-query";
import { toast } from "react-toastify";
import AppContext from "../../../contexts/AppContext";
import { InspectionContext } from "../../../contexts/InspectionContext";
import { ProjectContext } from "../../../contexts/ProjectContext";
import ProjectPermissionContext from "../../../contexts/ProjectPermissionContext";
import { QuestionCommentContext } from "../../../contexts/QuestionCommentContext";
import RolesContext from "../../../contexts/RolesContext";
import { inspectionQuestionApi } from "../../../utils/api/inspectionQuestion";
import { getFindingLabel } from "../../../utils/findings";
import Loading from "../../Shared/ResourceIndex/Loading";
import { DynamicTemplateQuestions } from "../Questions/DynamicTemplateQuestions";
import { LegacyTemplateQuestions } from "../Questions/LegacyTemplateQuestions";
import ToastError from "../../Shared/ToastError/ToastError";

export type InspectionApiErrorType = {
  response: { data: { message: string } };
};

export const InspectionQuestions = () => {
  const permCheck = useContext(RolesContext).userHasPermission;
  const appContext = useContext(AppContext);
  const projectStore = useContext(ProjectContext) as any;
  const projectPermissionContext = useContext(ProjectPermissionContext);
  const inspectionStore = useContext(InspectionContext);
  const questionCommentContext = useContext(QuestionCommentContext);
  const inspection = inspectionStore.inspection;
  const inspectionDate = moment.utc(inspection.created_date);

  const inspectionQuestionQuery = useQuery({
    queryFn: () => inspectionQuestionApi.show(inspectionStore.inspection.id),
    queryKey: ["inspectionQuestions", inspectionStore.inspection.id],
    refetchOnWindowFocus: false,
    refetchOnReconnect: false,
  });
  
  useEffect(() => {
    if (inspectionQuestionQuery.isFetched) {
      // set inspection type feature question
      inspectionQuestionQuery.data.template.question_groups.forEach(
        (questionGroup: {
          questions: Array<{
            answer: string;
            features: Array<QuestionFeature>;
          }>;
        }) => {

          questionGroup.questions.forEach(question => {
            if (question.features.includes(QuestionFeature.isInspectionType) && inspectionQuestionQuery.data.template.name !== "Colorado" ) {
              question.answer =  inspection.type;
            }
          });
        },
      );
 
      inspectionStore.setTemplate(inspectionQuestionQuery.data.template);
    }
  }, [inspectionQuestionQuery.data]);

  if (
    !projectStore.project ||
    !inspectionQuestionQuery.data ||
    !inspectionStore.template
  ) {
    return <Loading what="inspection" />;
  }

  if (!inspectionStore.template) {
    throw new Error("inspectionStore.template must be present");
  }

  /**
   * Dynamic (user-generated) Template or "system" template
   */
  return inspectionStore.template.isUserTemplate ? (
    <DynamicTemplateQuestions />
  ) : (
    <LegacyTemplateQuestions
      onCommentSave={questionCommentContext.onSave}
      onCommentDelete={questionCommentContext.onDelete}
      handleSubmit={async (formData: Record<string, any>) => {
        const toastId = toast("Saving inspection");
        const response = await inspectionStore
          .onSave(formData)
          .catch(async (err: InspectionApiErrorType) => {
            toast.update(toastId, {
              render: (
                <ToastError
                  message="Cannot save inspection because of error: "
                  error={inspectionStore.setSaveError(err)}
                />
              ),
              autoClose: false,
              type: "error",
            });

            await inspectionStore.reloadInspection();
            inspectionStore.resetTemplate();
          });

        if (response) {
          inspectionStore.setTemplate({
            ...inspectionStore.template,
            question_groups: response.template.question_groups,
          });

          inspectionQuestionQuery.refetch();
          toast.update(toastId, {
            render: "Inspection saved!",
            type: "success",
          });
        }
      }}
      inspection={inspection}
      questionTypes={Object.keys(
        inspectionQuestionQuery.data.questionTypes,
      ).reduce<QuestionType[]>((questionTypes, qid) => {
        questionTypes.push(
          inspectionQuestionQuery.data.questionTypes[Number(qid)],
        );

        return questionTypes;
      }, [])}
      template={inspectionStore.template}
      isReadOnly={
        projectPermissionContext.readOnly ||
        (!isNull(inspection.certification_date) &&
          (projectStore.project.inspection_compliance_required
            ? !isNull(inspection.compliance_date)
            : true))
      }
      findings={sortBy(
        inspectionStore.findings
          .filter(
            (finding: any) =>
              finding.type === "CA" &&
              (!finding.date_completed ||
                (finding.date_completed &&
                  moment
                    .utc(finding.date_completed)
                    .isSameOrAfter(inspectionDate, "day"))),
          )
          .map((finding: any) => ({
            value: finding.number,
            label: getFindingLabel(finding),
            badgeLabel: `#${finding.number}`,
          })),
        "label",
      )}
      permCheck={permCheck}
      currentUserName={
        !!appContext.get("user.first_name") ? appContext.get("user.name") : ""
      }
    />
  );
};

export default InspectionQuestions;
