import React from "react";
import { InspectionReportButton } from "./Button";
import "./_InspectionReportDownloadPage.scss";

export default function InspectionReportDownloadPage() {
  return (
    <div className="report-page">
      <img
        className="report-page__logo"
        src="/static/media/SW-Logo-White.svg"
      />

      <div className="report-page__synopsis">
        <h2 className="report-page__title">Report Not Found</h2>
      </div>

      <p className="report-page__manual-download">
        The report you're trying to access has either been moved or deleted.
      </p>

      <InspectionReportButton text="My Dashboard" to="/dashboard" />
    </div>
  );
}

InspectionReportDownloadPage.propTypes = {};
