import { InspectionTemplate } from "@sw-sw/lib-inspection-templates";
import React, { useState } from "react";
import { Redirect } from "react-router-dom";
import inspectionTemplateApi from "../../utils/api/inspectionTemplate";
import ResourceDetailDelete from "../Shared/ResourceDetail/ResourceDetailDelete";

export interface IInspectionTemplateDeleteProps {
  inspectionTemplate: InspectionTemplate;
}

const InspectionTemplateDelete: React.FC<IInspectionTemplateDeleteProps> = ({
  inspectionTemplate,
}) => {
  const [show, setShow] = useState(false);
  const [errorMsg, setErrorMsg] = useState("");
  const [disableDelete, setDisableDelete] = useState(true);
  const [redirect, setRedirect] = useState(false);

  


  const handleSubmit = () => {
    setErrorMsg("");
    inspectionTemplateApi
      .destroy(inspectionTemplate.id)
      .then(() => {
        hideModal();
        setRedirect(true);
      })
      .catch(e => {
        if (e.message) {
          setErrorMsg(e.message);
        } else if (e.response && e.response.data && e.response.data.message) {
          setErrorMsg(e.response.data.message);
        } else {
          setErrorMsg("An error has occurred");
        }
      });
  };

  const showModal = () => {
    setShow(true);
    setErrorMsg("");
  };

  const hideModal = () => {
    setShow(false);
    setDisableDelete(true);
  };

  const toggleDisabled = () => {
    setDisableDelete(!disableDelete);
  };

  if (redirect) {
    return <Redirect to="/inspection-templates" />;
  }

  return (
    <ResourceDetailDelete
      resource="Inspection Template"
      handleSubmit={handleSubmit}
      confirmationMsg={
        <>
          Are you sure that you want to delete the{" "}
          <strong>{inspectionTemplate.name}</strong> inspection template?
        </>
      }
      show={show}
      errorMsg={errorMsg}
      hideModal={hideModal}
      showModal={showModal}
      disableDelete={disableDelete}
      toggleDisabled={toggleDisabled}
    />
  );
};

export default InspectionTemplateDelete;
