import React, { useContext, useState, useEffect, useRef } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsisV, faExternalLinkAlt, faSort, faCalendarAlt, faList } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { Link } from "react-router-dom";
import Loading from "../../../../components/Shared/ResourceIndex/Loading";
// import AppContext from '../../../../contexts/AppContext'
import CalendarView from "./CalendarView"
import classNames from "classnames";
import DashboardContext from "../../../../contexts/DashboardContext";
import EllipsisPopover from '../../shared/EllipsisPopover/EllipsisPopover'



function getCtaRoute(inspectionStatus, dueDate, projectId) {
  if (inspectionStatus === "scheduled" && moment().format("MM-DD-YYYY") === dueDate) {
    return `/projects/${projectId}/start-inspection`;
  }

  return `/projects/${projectId}/inspections`
}


function getCtaText(dueDate) {
  const formattedTodayDate = new Date()
  const formattedDueDate =  new Date(dueDate.replace(/-/g,'/'))

  if (formattedTodayDate.setHours(0,0,0,0) === formattedDueDate.getTime()) {
    return "Start Inspection";
  }

  if (formattedTodayDate > formattedDueDate) {
    return "Past Due";
  }

  return "View Project";
}


const InspectionsDue = ({ providedProps, changeShowMore, mdSize }) => {
  const [search, setSearch] = useState('')
  // const appContext = useContext(AppContext);
  const [clientIsAscending, setClientIsAscending] = useState(true)
  const [siteIsAscending, setSiteIsAscending] = useState(true)
  const { displayStatsForID, isLoadingForID, allProjectsForID, setDisplayStatsForID } = useContext(DashboardContext)
  const widgetRef = useRef(0);
  const [widgetWidth, setWidgetWidth] = useState(widgetRef.current.clientWidth)
  const [isCalendar, setIsCalendar] = useState(false)

  let viewName = 'Calendar View'

  let viewIcon = faCalendarAlt
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;


  if (isCalendar) {
    viewIcon = faList
    viewName = 'List View'
  }

  useEffect(() => {
    function handleResize() {
      setWidgetWidth(widgetRef.current.clientWidth)
    }

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  })

  const handleClose = () => {
    setAnchorEl(null);
  };


  const handleChangeView = () => {
    setIsCalendar(!isCalendar)
    setAnchorEl(null);
  }

  const handleButtonColors = (dueDate) => {
    if(getCtaText(dueDate) === "View Project")
      return "list-view-show-project-color"
     
    if(getCtaText(dueDate) === "Past Due")
      return "list-view-past-due-color"
    
    return "list-view-start-inspection-color"
  }

  //SEARCH
  useEffect(() => {
    const updatedList = [...allProjectsForID].filter((el) => {
      return el.siteName.toLowerCase().includes(search.toLowerCase())
    })

    setDisplayStatsForID(updatedList)
  }, [search])

  //SORT
  const sortBySiteName = () => {
    const updatedList = displayStatsForID.sort((a, b) => {
      let A = a.siteName.toString().toLowerCase()

      let B = b.siteName.toString().toLowerCase()

      if (siteIsAscending === true) {
        setSiteIsAscending(!siteIsAscending)

        return A > B ? -1 : 1 // descending
      }

      else {
        setSiteIsAscending(!siteIsAscending)

        return A > B ? 1 : -1 //ascending
      }
    })

    setDisplayStatsForID(updatedList)
  }

  const sortByClientName = () => {
    const updatedList = displayStatsForID.sort((a, b) => {
      let A = a.clientName.toString().toLowerCase()

      let B = b.clientName.toString().toLowerCase()

      if (clientIsAscending === true) {
        setClientIsAscending(!clientIsAscending)

        return A > B ? -1 : 1 // descending
      }

      else {
        setClientIsAscending(!clientIsAscending)

        return A > B ? 1 : -1 //ascending
      }
    })

    setDisplayStatsForID(updatedList)
  }

  const sortByDueDate = () => {
    const updatedList = displayStatsForID.sort((a, b) => {
      let A = new Date(a.dueDate)

      let B = new Date(b.dueDate)

      if (clientIsAscending === true) {
        setClientIsAscending(!clientIsAscending)

        return A > B ? -1 : 1 // descending
      }

      else {
        setClientIsAscending(!clientIsAscending)

        return A > B ? 1 : -1 //ascending
      }
    })

    setDisplayStatsForID(updatedList)
  }




  let oneColumnHeader = {
    // gridTemplateAreas: '"title title title . bars bars bars bars bars bars bars ellipsis"'
  }


  return (
    <>
      <div className="inspections-due" ref={widgetRef}>
        <header className="inspections-due__header" style={widgetWidth > 700 ? oneColumnHeader : null}>
          <h2 className="inspections-due__title">Inspections Due</h2>
          <div className="inspections-due__bars">
            {/* <section style={{ display: isCalendar ? "none" : "flex" }}>{appContext.state.user.name}</section> */}
            <input
            //  style={{ flexBasis: isCalendar ? "100%" : "60%" }}
             onChange={(event) => setSearch(event.target.value)} placeholder="Search" />
          </div>
          <div className="inspections-due__ellipsis-button">
            <span
              onClick={(event) => setAnchorEl(event.currentTarget)}
            >
              <FontAwesomeIcon icon={faEllipsisV} />
            </span>
          </div>
          <EllipsisPopover handleCloseFunc={handleClose} id={id} open={open} anchorEl={anchorEl} thirdOption={{ sx: { p: 1 }, icon: viewIcon, optionName: viewName, handleOnClickFunc: handleChangeView }} />
        </header>

        <div
          data-rbd-drag-handle-context-id={providedProps}
          data-rbd-drag-handle-draggable-id="gibberish"
          style={{
            // When you set the data-rbd-drag-handle-context-id, RBD applies cursor: grab, so we need to revert that
            cursor: "auto"
          }}
        >
          <div className="inspections-due__body">
            <div className="inspections-due__content">

              {!isCalendar ?
                // ------------------------ List View ------------------------------------------------------------------- 
                (<section className="inspection-due__list-view">
                  <div className="inspections-due__table-head">
                    <h3 onClick={sortBySiteName}>Site Name <span><FontAwesomeIcon icon={faSort} /></span></h3>
                    <h3 onClick={sortByClientName}>Client Name <span><FontAwesomeIcon icon={faSort} /></span></h3>
                    <h3 onClick={sortByDueDate}>Due Date <span><FontAwesomeIcon icon={faSort} /></span> </h3>
                    <h3>Action</h3>
                  </div>

                  {!isLoadingForID ?
                    (displayStatsForID.map((ele, index) => {
                      const { siteName, clientName, dueDate, projectId, inspectionStatus } = ele

                      return (
                        <div key={index} className="inspections-due__table-body">
                          <li>{siteName}</li>
                          <li>{clientName}</li>
                          <li>{dueDate}</li>

                          <li>
                            <Link to={getCtaRoute(inspectionStatus, dueDate, projectId)}>
                              <span className={classNames(handleButtonColors(dueDate))}>{getCtaText(dueDate)}</span>
                            </Link>
                          </li>
                        </div>
                      )
                    })) :
                    (<Loading what="data" />)
                  }
                </section>)
                :
                // ------------------------ Calendar View -------------------------------------------------------------------
                (<section className="inspection-due__calendar-view">
                  <CalendarView dueProjects={displayStatsForID} getCtaRoute={getCtaRoute} getCtaText={getCtaText} />
                </section>)
              }

            </div>

            <footer className="inspections-due__footer">
              <span onClick={changeShowMore}>Show {(mdSize === 6) ? 'More' : 'Less'} <FontAwesomeIcon icon={faExternalLinkAlt} /></span>
            </footer>
          </div>
        </div>
      </div>
    </>
  )
}


export default InspectionsDue