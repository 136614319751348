import { FormSchema, OptionShape } from "@sw-sw/lib-form";
import { UIControlType } from "@sw-sw/lib-form-control-types";
import { Placement } from "popper.js";
import React, { MouseEventHandler, useContext, useEffect } from "react";
import ProjectContext from "../../../contexts/ProjectContext";
import ProjectPrintContext, {
  InspectionDateFilter,
  PrintProjectFormData,
} from "../../../contexts/ProjectPrintContext";
import { InspectionIdsControl } from "../../Inspections/DateFilter/InspectionIdsControl";
import { getMapOptionsSchema } from "../../Inspections/Details/InspectionPrint";
import LoadingModal from "../../Shared/Loading/LoadingModal";
import PrintModal from "../../Shared/Print/PrintModal";

export type InspectionSchemaOptions = {
  inspections: OptionShape[];
  filter: InspectionDateFilter;
  filterOptions: OptionShape[];
  inspectionTextLabel: string;
};

export const getInspectionFilterSchema = ({
  inspections,
  filter,
  filterOptions,
  inspectionTextLabel,
}: InspectionSchemaOptions): FormSchema => ({
  inspection_label: {
    label: "Inspections",
    controlType: UIControlType.plainText,
    style: { marginBottom: 0 },
    className: inspections.length === 0 ? "hidden" : "",
  },
  timeFilter: {
    controlType: UIControlType.select,
    parse: Number,
    label: "Filter",
    options: filterOptions,
    style: { flex: "0 1 33%", paddingLeft: "2rem" },
  },
  start_date: {
    controlType: UIControlType.date,
    disabled: (filter.prevMonths || 0) > 0,
    label: "Start Date",
    style: { flex: "0 1 33%" },
    popperPlacement: "top" as Placement,
  },
  end_date: {
    controlType: UIControlType.date,
    disabled: (filter.prevMonths || 0) > 0,
    label: "End Date",
    style: { flex: "0 1 33%" },
    popperPlacement: "top" as Placement,
    validation: {
      maxDate: new Date(),
    },
  },
  inspectionText: {
    controlType: UIControlType.plainText,
    label: inspectionTextLabel,
    style: { paddingLeft: "2rem", marginBottom: "1rem" },
  },
  select_all: {
    controlType: UIControlType.checkbox,
    label: "Select All",
    style: { marginBottom: 0, paddingLeft: "2rem" },
    className:
      "inspection-select-all " + (inspections.length === 0 ? "hidden" : ""),
  },
  inspectionIds: {
    controlType: UIControlType.custom,
    renderControl: InspectionIdsControl,
    options: inspections,
    validation: {
      required: inspections.length > 0,
    },
    style: { paddingLeft: "2rem" },
    className: "form-group--checkboxGroup two-columns",
  },
});

const getPrintInspectionsSchema = (options: InspectionSchemaOptions) => {
  const schema: FormSchema = {
    ...getMapOptionsSchema(),
    ...getInspectionFilterSchema(options),
  };

  return schema;
};

export interface IPrintInspectionsModal {
  onClose: MouseEventHandler<Element>;
}

const PrintInspectionsModal: React.FC<IPrintInspectionsModal> = ({
  onClose,
}) => {
  const projectContext = useContext(ProjectContext);
  const projectPrintContext = useContext(ProjectPrintContext);

  useEffect(() => {
    projectContext.inspectionsQuery.refetch();
  }, []);

  if (!projectContext.project || projectContext.inspectionsQuery.isFetching) {
    return <LoadingModal show />;
  }

  return (
    <PrintModal
      jobName={"inspections"}
      className="print-inspections-modal"
      jobData={{
        projectId: projectContext.project.id,
      }}
      statusUpdateKey={projectContext.project.id}
      onClose={onClose}
      formSchema={(formContextValue: PrintProjectFormData) => {
        const filter: InspectionDateFilter = {
          start: formContextValue.start_date,
          end: formContextValue.end_date,
          prevMonths: formContextValue.timeFilter,
        };
        const inspections = projectPrintContext.getInspectionOptions(
          projectContext.inspectionsQuery.data || [],
          filter,
        );

        return getPrintInspectionsSchema({
          inspections,
          filter,
          filterOptions: projectPrintContext.timeFilterOptions,
          inspectionTextLabel: projectPrintContext.getInspectionText({
            inspections,
            filter,
          }),
        });
      }}
      initialFormData={{
        inspectionIds: {},
        include_findings: true,
        projectId: projectContext.project.id,
        timeFilter: 3,
      }}
      modalTitle="Print Inspections"
      validateData={projectPrintContext.validateInspectionData}
    />
  );
};

export default PrintInspectionsModal;
