import React, { useCallback, useContext } from "react";
import iContextProviderProps from "../../../contexts/ClientContext";
import ProjectCard from "./ProjectCard";

export default function ClientProjectsTab(props: { showProjects: string }) {
  const context = useContext(iContextProviderProps);

  const { showProjects } = props;

  const onAllSelect = useCallback(() => {
    context.clientProjects.map(_ => _.id);
  }, [context.clientProjects]);

  return (
    <div className="projects">
      <div className="flex-row project-list">
      {showProjects && (
        <div className="post-storm-controls">
          <div className="select-all" onClick={onAllSelect}>
            <div className="check-holder"></div>
          </div>
        </div>
      )}
      {!context.clientProjects || context.clientProjects.length === 0 ? (
        <p>No projects found</p>
      ) : null}
      {context.clientProjects.map(project => {
        return <ProjectCard project={project} />;
      })}
    </div>
    </div>
  );
}
