import React, { useContext } from "react";

import { Redirect } from "react-router-dom";
import AuthenticationContext from "../../contexts/AuthenticationContext";
import { AppDivisionContext } from "../../contexts/AppDivisionContext";


/**
 * Compute the home page route, depending on user auth status
 */
const HomePage = () => {
  const authStore = useContext(AuthenticationContext);
  const appDivisionContext = useContext(AppDivisionContext)

  if (!authStore.loggedIn) {
    return <Redirect to="/login" push={false} />;
  }

  return <Redirect to={appDivisionContext.getPath("/dashboard")} push={false} />;
};

export default HomePage;
