import React, { useContext } from "react";
import { Redirect, useParams } from "react-router-dom";
import RolesContext from "../../contexts/RolesContext";
import { useInspectionTemplate } from "../../hooks/inspectionTemplates";
import ResourceDetailTabs from "../Shared/ResourceDetail/ResourceDetailTabs";
import ResourceDetailTitle from "../Shared/ResourceDetail/ResourceDetailTitle";
import Error from "../Shared/ResourceIndex/Error";
import Loading from "../Shared/ResourceIndex/Loading";
import { SignatureEditor } from "./SignatureEditor/SignatureEditor";
import TemplateEditor from "./TemplateEditor/TemplateEditor";
import InspectionTemplateDelete from "./InspectionTemplateDelete";
import InspectionTemplateUpdate from "./InspectionTemplateUpdate";
import withSignatureEditorProvider from "../../hoc/withSignatureEditorContext";
import InspectionTemplateFinding from "../InspectionTemplates/InspectionFinding/findings";
const InspectionTemplateDetail: React.FC = () => {
  const { id: inspectionDetailIdParam } = useParams<{ id: string }>();
  const { userHasPermission } = useContext(RolesContext);

  const inspectionTemplateQuery = useInspectionTemplate(
    parseInt(inspectionDetailIdParam),
  );

  if (inspectionTemplateQuery.isLoading) {
    return <Loading />;
  }

  if (inspectionTemplateQuery.isError || !inspectionTemplateQuery.data) {
    return <Error error="Failure to retrieve Inspection Template" />;
  }

  const inspectionTemplate = inspectionTemplateQuery.data;

  if (!inspectionTemplate.isUserTemplate) {
    alert("Editing of System templates is not currently allowed.");

    return <Redirect to="/inspection-templates" />;
  }

  return (
    <section className="inspection-template-detail">
      <div className="inspection-template-header">
        <ResourceDetailTitle title={inspectionTemplate.name} />
        <div className="project-buttons">
          {userHasPermission("update", "Inspection Templates") && (
            <InspectionTemplateUpdate
              inspectionTemplate={inspectionTemplate}
              onUpdate={inspectionTemplateQuery.refetch}
            />
          )}

          <div className="project-delete-button">
            {userHasPermission("delete", "Inspection Templates") && (
              <InspectionTemplateDelete
                inspectionTemplate={inspectionTemplate}
              />
            )}
          </div>
        </div>
      </div>
      <ResourceDetailTabs
        headers={["Questions", "Certification", "Findings"]}
        panels={[
          () => <TemplateEditor templateId={inspectionTemplate.id!} />,
          withSignatureEditorProvider(
            () => <SignatureEditor />,
            inspectionTemplate.id!,
          ),
          withSignatureEditorProvider(
            () => <InspectionTemplateFinding templateId={inspectionTemplate.id} />,
            inspectionTemplate.id!,
          ),
        ]}
      />
    </section>
  );
};

export default InspectionTemplateDetail;
