import React, { useState, useContext } from "react";
import {
  format,
  subMonths,
  addMonths,
  startOfWeek,
  startOfMonth,
  addDays,
  getDaysInMonth,
  isEqual
} from "date-fns";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faAngleRight, faEye, faClipboardList } from "@fortawesome/free-solid-svg-icons";
import DashboardContext from "../../../../contexts/DashboardContext";
import { AppDivisionContext } from "../../../../contexts/AppDivisionContext";
import { Link } from "react-router-dom";
import classNames from "classnames";
import { Popover, popoverClasses } from '@mui/material';
import { styled } from '@mui/material/styles';



const MonthViewLayout = ({ dueProjects, getCtaRoute, getCtaText }) => {
  const [currentMonth, setCurrentMonth] = useState(new Date());

  const changeMonthHandle = (btnType) => {
    if (btnType === "prev") {
      setCurrentMonth(subMonths(currentMonth, 1));
    }
    if (btnType === "next") {
      setCurrentMonth(addMonths(currentMonth, 1));
    }
  };

  const renderHeader = () => {
    const dateFormat = "MMMM yyyy";
    
    return (
      <div className="header row flex-middle">

        <div className="col col-end flex-gap renderHeader__left">
          <div className="icon" onClick={() => changeMonthHandle("prev")}>
            <FontAwesomeIcon icon={ faAngleLeft }/>
          </div>
        </div>

        <div className="col col-center renderHeader__center">
          <span>{format(currentMonth, dateFormat)}</span>
        </div>


        <div className="col col-start flex-gap renderHeader__right">
          <div className="icon" onClick={() => changeMonthHandle("next")}>
            <FontAwesomeIcon icon={ faAngleRight }/>
          </div>
        </div>

      </div>
    );
  };

  const renderDays = () => {
    const dateFormat = "EEE";
    const days = [];

    let startDate = startOfWeek(currentMonth, { weekStartsOn: 1 });

    for (let i = 0; i < 7; i++) {
      days.push(
        <div className="col col-center" key={i}>
          {format(addDays(startDate, i), dateFormat)}
        </div>
      );
    }
    
    return <div className="days row">{days}</div>;
  };


  const renderCells = () => {
    let firstDayOfCurrentMonth = format(startOfMonth(currentMonth), 'i') //7

    let numberOfDaysInCurrentMonth = getDaysInMonth(currentMonth) //31

    let blankCells = []

    let datesOfCurrentMonth = []

    for(let i=1; i<firstDayOfCurrentMonth; i++){
        blankCells.push('')
    }

    for(let i=1; i<=numberOfDaysInCurrentMonth; i++){
        datesOfCurrentMonth.push(i)
    }


    return (
    <>
      <div className="render-cells">
          {blankCells && blankCells.map((blank) => {
            return (
              <>
                <span className="render-cell">
                  <span>{blank}</span>
                </span>
              </>
            )
          })}
          {datesOfCurrentMonth.map((date) => {
            return (
              <>
                <CalendarCell date={date} currentMonth={currentMonth}/>
              </>
            )
          })}
      </div>
    </>
    )
  };


  return (
    <div className="month-view-layout">
      <div className="calendar">
        {renderHeader()}
        <div className="calendar__body">
          {renderDays()}
          {renderCells()}
        </div>
      </div>
    </div>
  );
};






const CalendarCell = (props) => {
  const { displayStatsForCal } = useContext(DashboardContext)
  const { appDivisionId } = useContext(AppDivisionContext);
  const [anchorEl, setAnchorEl] = useState(null)

  // Mui Custom CSS for inpection detail popover
  const StyledPopover = styled(Popover)(({ theme }) => ({
    [`&.${popoverClasses.root} .render-cells-popover`]: {
      width: '260px',
      padding: '8px',
      color: '#6D747E'
    },
    [`&.${popoverClasses.root} li`]: {
      listStyle: 'none',
      padding: '0.25rem 0',
      lineHeight: 1
    },
    [`&.${popoverClasses.root} li:nth-child(1)`]: {
      fontWeight: 900,
      marginBottom: '0.5rem'
    },
    [`&.${popoverClasses.root} li:nth-child(1), li:nth-child(3)`]: {
      whitespace: 'nowrap',
      overflow: 'hidden',
      textOverflow: 'ellipsis',
      maxWidth: '25ch',
      '-webkitBoxOrient': 'vertical',
    },
    [`&.${popoverClasses.root} li:nth-child(2) span`]: {
      color: 'rgb(168, 168, 168)'
    },
    [`&.${popoverClasses.root} li p`]: {
      display: 'inline'
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta`]: {
      border: '1px solid #afafaf',
      width: 'fit-content',
      marginTop: '5px',
      padding: '3px',
      borderRadius: '4px',
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta:hover`]: {
      background: 'rgb(228, 228, 228)'
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta a`]: {
      textDecoration: "none"
    },
    [`&.${popoverClasses.root} .render-cells-popover__cta a span`]: {
      borderRadius: '4px',
      padding: '3px',
      color: '#6D747E',
      fontWeight: 900
    },
  }));

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const { date, currentMonth } = props

  const regex = new RegExp(`${format(currentMonth, 'M')}-.*-${format(currentMonth, 'y')}`)

  const inspectionsDueCurrentMonth = displayStatsForCal.filter((ele) => regex.test(ele.inspectionDOI))

  let cellDate = new Date(`${format(startOfMonth(currentMonth), 'M')}/${date}/${format(startOfMonth(currentMonth), 'yyyy')}`)

  let filteredInspection = inspectionsDueCurrentMonth.filter((ele) => isEqual(new Date(ele.inspectionDOI.replace(/-/g,'/')), cellDate))

  const onInspectionIconClick = (event) => {
    setAnchorEl(event.currentTarget);
  }

  const handleIconColors = (inspectionCertDate, inspectionDOI, projectNextInspectionDate) => {
    if(!inspectionDOI) return null

    const formattedInspectionCertDate = inspectionCertDate ? new Date(inspectionCertDate.replace(/-/g,'/')) : null
    const formattedProjectNextInspectionDate = projectNextInspectionDate ? new Date(projectNextInspectionDate.replace(/-/g,'/')) : null
    const formattedInspectionDOI = new Date(inspectionDOI.replace(/-/g,'/'))


    if(!formattedInspectionCertDate){
      if (formattedProjectNextInspectionDate > formattedInspectionDOI) {
        return "missed-inspections-color";
      }

      return "hide-icon"
    }

    if (formattedInspectionCertDate && (formattedInspectionCertDate < formattedInspectionDOI)) {
      return "hide-icon";
    }

    if (formattedInspectionCertDate && (formattedInspectionCertDate > formattedInspectionDOI)) {
      return "late-inspections-color";
    }

    if (formattedInspectionCertDate && (formattedInspectionCertDate.getTime() === formattedInspectionDOI.getTime())) {
      return "on-time-inspections-color";
    }

    return null
  }

  return (
    <>
      <span className="render-cell" id={date}>
        {filteredInspection.length  ? <FontAwesomeIcon onClick={(event) => onInspectionIconClick(event)} icon={ faClipboardList } className={classNames(handleIconColors(filteredInspection[0].inspectionCertDate, filteredInspection[0].inspectionDOI, filteredInspection[0].projectNextInspectionDate))} style={{ position: "absolute", top: 5, left: 5 }} /> : <></>}
        <span>{date}</span>
        {/* Precipitation Icon */}
        {/* <FontAwesomeIcon icon={ faCloudShowersHeavy } style={{ position: "absolute", top: 5, right: 5 }} /> */}
        
        <div>
          {
            filteredInspection[0] &&
            <StyledPopover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
            >
              <div className="render-cells-popover">
                <li>{filteredInspection[0].projectName}</li>
                <li><span>Date Of Inspection:</span> <p>{filteredInspection[0].inspectionDOI}</p></li>
                <li><span>Inpsector Name:</span> <p>{filteredInspection[0].inspector}</p></li>
                <section className="render-cells-popover__cta">
                  <Link to={`/divisions/${appDivisionId}/projects/${filteredInspection[0].projectId}/inspections`}>
                    <span><FontAwesomeIcon icon={ faEye }/> View Project</span>
                  </Link>
                </section>
              </div>
            </StyledPopover>
          }
        </div>
      </span>
    </>
  )
}




export default MonthViewLayout;

